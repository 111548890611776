import { Breakpoint, Color, PageTitle, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';
import { ChildrenContainerProps } from './baseViewForOrders.types';

export const Container: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 171px);
  background-color: ${Color.ICBlack500};

  @media ${Breakpoint.Mobile} {
    background-color: ${Color.ICBlack400};
    border-top: 1px solid ${Color.ICGray500};
  }
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  padding: 24px 24px 62px;
  width: 100%;

  @media ${Breakpoint.Mobile} {
    padding: 24px 24px 30px;
  }
`;

export const ChildrenContainer: StyledComponent<'div', ChildrenContainerProps> = styled.div<ChildrenContainerProps>`
  width: 100%;
  overflow-y: ${({ $withContentScroll }: ChildrenContainerProps): string => $withContentScroll ? 'auto' : 'visible'};
  overflow-x: hidden;
  flex: 1;
  max-height: ${({ $withContentScroll }: ChildrenContainerProps): string => $withContentScroll 
    ? 'calc(100vh - 278px)' 
    : '100%'};
  display: flex;
  justify-content: center;

  @media ${Breakpoint.Tablet} {
    max-height: ${({ $withContentScroll }: ChildrenContainerProps): string => $withContentScroll 
    ? 'calc(100vh - 342px)'
    : '100%'};
  }

  @media ${Breakpoint.Mobile} {
    max-height: ${({ $withContentScroll }: ChildrenContainerProps): string => $withContentScroll 
    ? 'calc(100vh - 342px)'
    : '100%'};
  }
`;

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  background: linear-gradient(189deg, ${Color.ICBlack100} -36.36%, ${Color.ICBlack500} 126.99%);

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    gap: 20px;
    padding: 24px;
  }
`;
