import { UseState, MapPointDelivery } from '@chic-loyalty/ui';
import { QueryKey, DeliveryType } from '@chic/enums';
import { PickupPointDetails, PickupPointsParams, UseOrder } from '@chic/models';
import { RefObject, useState, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { UseSubscriptionDeliveryPickupPointChoose } from './subscriptionDeliveryPickupPointChoose.types';
import { DebouncedState, useDebounce } from 'use-debounce';
import { getPickupPoints } from '@chic/api';
import { useOrder } from '@chic/hooks';
import { isDeliveryPickupPointType } from '@chic/utils';

export const useSubscriptionDelliveryPickupPointChoose: (
  listRef: RefObject<HTMLDivElement>,
  pointId?: string,
) => UseSubscriptionDeliveryPickupPointChoose = (
  listRef: RefObject<HTMLDivElement>,
  pointId: string = '',
): UseSubscriptionDeliveryPickupPointChoose => {
  const { orderData }: UseOrder = useOrder();
  const [mapPoints, setMapPoints]: UseState<MapPointDelivery[]> = useState<MapPointDelivery[]>([]);
  const [pickupPoints, setPickupPoints]: UseState<PickupPointDetails[]> = useState<PickupPointDetails[]>([]);
  const [shouldFlyToPoint, setShouldFlyToPoint]: UseState<boolean> = useState<boolean>(true);
  const [inputValue, setInputValue]: UseState<string> = useState<string>('');
  const [activePointId, setActivePointId]: UseState<number | null> = useState<number | null>(null);
  const [pointDetails, setPointDetails]: UseState<PickupPointDetails | null> = useState<PickupPointDetails | null>(null);
  const [currentPosition, setCurrentPosition]: UseState<[number, number] | null> = useState<[number, number] | null>(null);
  const [pointPosition, setPointPosition]: UseState<[number, number] | null> = useState<[number, number] | null>([52, 20]);
  const [debouncedInputValue]: [string, DebouncedState<(value: string) => void>] = useDebounce(inputValue, 500);
  const [isDeliveryListVisibleOnMobile, setIsDeliveryListVisibleOnMobile]: UseState<boolean> = useState<boolean>(true);
  const activePoint: MapPointDelivery | undefined = useMemo(
    (): MapPointDelivery | undefined => (
      mapPoints.find((point: MapPointDelivery): boolean => point.id === activePointId)
    ),
    [activePointId, mapPoints],
  );
  const selectedDelivery: DeliveryType | undefined = useMemo((): DeliveryType | undefined => orderData.selectedDelivery, [orderData]);
  const requestParams: Partial<PickupPointsParams> = useMemo(
    (): Partial<PickupPointsParams> => (
      {
        location: pointPosition ? `${pointPosition[0]} ${pointPosition[1]}` : undefined,
        text: !pointPosition ? debouncedInputValue : undefined,
        type: isDeliveryPickupPointType(selectedDelivery) ? selectedDelivery : undefined,
      }
    ),
    [pointPosition, debouncedInputValue, selectedDelivery],
  );

  useQuery(
    [QueryKey.PickupPoints, requestParams, selectedDelivery, pointId],
    (): Promise<PickupPointDetails[]> => getPickupPoints(requestParams),
    {
      enabled: !!selectedDelivery,
      onSuccess: (data: PickupPointDetails[]): void => {
        setMapPoints(data
          .map((pickupPoint: PickupPointDetails): MapPointDelivery => ({
            name: pickupPoint.name,
            id: pickupPoint.id,
            address: [pickupPoint.location.address, `${`${pickupPoint.location.postalCode} ${pickupPoint.location.city}`}`],
            lat: pickupPoint.location.latitude,
            lng: pickupPoint.location.longitude,
          })),
        );
        setPickupPoints(data);
      },
      // TODO: add logger
      onError: (): void => undefined,
    },
  );

  const activeMarkerUnselect: [number, number] | null = useMemo(
    (): [number, number] | null => {
      const shiftPositionValue: number = 0.00000001;
      if (activePoint) {
        return [activePoint.lat + shiftPositionValue, activePoint.lng + shiftPositionValue];
      } else {
        return null;
      }
    },
    [activePoint],
  );

  const onCloseStoreInfo: () => void = (): void => {
    setActivePointId(null);
    setPointDetails(null);
    setIsDeliveryListVisibleOnMobile(true);
    setShouldFlyToPoint(false);
    setPointPosition(activeMarkerUnselect);
  };

  const onLocationButtonClick: () => void = (): void => {
    if (currentPosition) {
      setShouldFlyToPoint(true);
      setActivePointId(null);
      setPointDetails(null);
      setPointPosition(currentPosition);
    }
  };

  const onPositionChange: (position: [number, number] | null) => void = (position: [number, number] | null): void => {
    if (position !== currentPosition) {
      setActivePointId(null);
      setPointDetails(null);
    }
    setShouldFlyToPoint(true);
    setCurrentPosition(position);
    setPointPosition(position);
  };

  const onSelectDeliveryPointId: (selectedPointId: number) => void = (selectedPointId: number): void => {
    setActivePointId(selectedPointId);
    setPointDetails(pickupPoints.find((point: PickupPointDetails): boolean => point.id === selectedPointId) ?? null);
    setShouldFlyToPoint(false);
  };

  const onSearchInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setInputValue(e.target.value);
    setPointPosition(null);
    setActivePointId(null);
  };

  useEffect(
    (): void => {
      if (activePoint) {
        setPointPosition([activePoint.lat, activePoint.lng]);
        setIsDeliveryListVisibleOnMobile(false);
        listRef.current?.scroll({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
    [activePoint],
  );

  return {
    inputValue,
    mapPoints,
    activePointId,
    pointPosition,
    shouldFlyToPoint,
    onSearchInputChange,
    onLocationButtonClick,
    onSelectDeliveryPointId,
    onPositionChange,
    setShouldFlyToPoint,
    setActivePointId,
    setPointPosition,
    setIsDeliveryListVisibleOnMobile,
    isDeliveryListVisibleOnMobile,
    onCloseStoreInfo,
    pointDetails,
    setPointDetails,
  };
};
