import { ConfigKey, RoutingPath } from '@chic/enums';
import { ConfigsContextType } from '@chic/models';

export const configsContextMock: ConfigsContextType = {
  menu: [],
  footer: [],
  disabledViews: [],
  defaultRoute: RoutingPath.Dashboard,
  systemConfig: Object.values(ConfigKey)
    .map((item: ConfigKey): Record<ConfigKey, boolean> => ({ [item]: false } as Record<ConfigKey, boolean>))
    .reduce((acc: Record<ConfigKey, boolean>, item: Record<ConfigKey, boolean>): Record<ConfigKey, boolean> => ({ ...acc, ...item })),
};
