export enum RoutingPath {
  // common paths
  Home = '/',
  ReturnFromCrm = '/login/crm',
  Dashboard = '/panel',
  SignIn = '/logowanie-wewnetrzne',
  RemindPassword = '/przypomnij-haslo',
  ChangePassword = '/zmiana-hasla',
  CrmSignIn = '/logowanie',
  CrmSignInRedirect = '/zaloguj-przez-crm',
  Profile = '/profil',
  UserRoleVerificationList = '/weryfikacja-uzytkownikow',
  UserRoleVerification = '/weryfikacja-uzytkownikow/:userId',
  NotFound = '*',
  // custom paths for forks apps
  Registration = '/rejestracja',
  RegistrationWithoutVerification = '/rejestracja-bez-weryfikacji-sms',
  SubscriptionContract = '/inspiration-plan/zamowienie/umowa-sprzedazy',
  SubscriptionDelivery = '/inspiration-plan/zamowienie/dostawa',
  SubscriptionDeliveryPickupPoint = '/inspiration-plan/zamowienie/dostawa/wybierz-punkt-odbioru',
  SubscriptionParametersChoose = '/inspiration-plan/zamowienie/ustawienia-planu',
  SubscriptionPayment = '/inspiration-plan/zamowienie/platnosc',
  SubscriptionProductsChoose = '/inspiration-plan/zamowienie/wybierz-produkty',
  SubscriptionSummary = '/inspiration-plan/zamowienie/podsumowanie',
  SubscriptionUserVerification = '/inspiration-plan/zamowienie/weryfikacja-uzytkownika',
}
