import {
  ApiReject,
  ApiResolve,
  BaseApiData,
  ConsumerLogin,
  ConsumerLoginData,
  SubscriptionDetails,
  SubscriptionData,
  ChangeUnverifiedUserEmailRequest,
  DeliveryDestination,
  ConsumerRegisterData,
  SmsVerificationData,
} from '@chic/models';
import { AxiosResponse } from 'axios';
import { api } from '../api.service';

export const requestSmsCodeForLogin = (login: string): Promise<BaseApiData> => new Promise(
  (resolve: ApiResolve<BaseApiData>, reject: ApiReject): Promise<void> => (
    api.post('/hostess/consumer/login', { login })
      .then((response: AxiosResponse): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const consumerLogin = (data: ConsumerLogin): Promise<ConsumerLoginData> => new Promise(
  (resolve: ApiResolve<ConsumerLoginData>, reject: ApiReject): Promise<void> => (
    api.post('/hostess/consumer/login', data)
      .then((response: AxiosResponse): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getSubscriptionDetails = (consumerId: number, subscriptionId: number): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject): Promise<void> => (
    api.get(`/hostess/consumer/${consumerId}/subscription/${subscriptionId}`)
      .then((response: AxiosResponse<SubscriptionDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);
      
export const getSubscriptionsList = (consumerId: number): Promise<SubscriptionData[]> => new Promise(
  (resolve: ApiResolve<SubscriptionData[]>, reject: ApiReject): Promise<void> => (
    api.get(`/hostess/consumer/${consumerId}/subscriptions`)
      .then((response: AxiosResponse<SubscriptionData[]>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const registerUserData: (data: ConsumerRegisterData) => Promise<void> = (data: ConsumerRegisterData): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject): Promise<void> => (
    api.post('/hostess/consumer/register', data)
      .then((response: AxiosResponse<void>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const resendVerificationSms: (data: SmsVerificationData) => Promise<void> = (
  data: SmsVerificationData,
): Promise<void> => new Promise(
  (resolve: ApiResolve<void>, reject: ApiReject): Promise<void> => (
    api.post('/hostess/consumer/verification-sms/resend', data)
      .then((response: AxiosResponse<void>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const changeUnverifiedUserEmail = (data: ChangeUnverifiedUserEmailRequest): Promise<BaseApiData> => new Promise(
  (resolve: ApiResolve<BaseApiData>, reject: ApiReject): Promise<void> => (
    api.post('/hostess/consumer/recovery', data)
      .then((response: AxiosResponse): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getLastUsedSubscriptionAddress = (customerId: number): Promise<DeliveryDestination> => new Promise(
  (resolve: ApiResolve<DeliveryDestination>, reject: ApiReject): Promise<void> => (
    api.get(`/hostess/consumer/${customerId}/delivery-destination`)
      .then((response: AxiosResponse<DeliveryDestination>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getCustomerAddresses = (customerId: number): Promise<DeliveryDestination[]> => new Promise(
  (resolve: ApiResolve<DeliveryDestination[]>, reject: ApiReject): Promise<void> => (
    api.get(`/hostess/consumer/${customerId}/addresses`)
      .then((response: AxiosResponse<DeliveryDestination[]>): void => resolve(response?.data))
      .catch(reject)
  ),
);
