import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { datadogLogs } from '@datadog/browser-logs';

import { GlobalStyle } from '../theme/globalStyle.theme';
import {
  DashboardView,
  NotFoundView,
  CrmSignInView,
  CrmSignInRedirectView,
  ChangePasswordView,
  SignInView,
  CrmSignInAfterRedirectView,
  ProfileView,
  RemindPasswordView,
  UserRoleVerificationListView,
  UserRoleVerificationView,
  RegistrationView,
  RegistrationWithoutSmsView,
  SubscriptionContractView,
  SubscriptionProductsChooseView,
  SubscriptionPaymentView,
  SubscriptionSummaryView,
  SubscriptionDeliveryView,
  SubscriptionDeliveryPickupPointChooseView,
  SubscriptionUserVerificationView,
  SubscriptionParametersChooseView,
} from '../views';
import { AppWrapper, CheckAuth } from '@chic/components';
import { LocalStorageKey, RoutingPath, SessionStorageKey } from '@chic/enums';
import { AppState, AuthContextType, AuthState, OrderContextType, OrderState, UseInitial } from '@chic/models';
import { useInitial } from '@chic/hooks';
import { appStateReducer, authReducer, orderReducer, setFullscreenAlertDataAction } from '@chic/reducers';
import { AuthContext, ConfigsContext, AppStateContext, OrderContext } from '@chic/contexts';
import { initialAppState } from '@chic/constans';
import { ComponentColorTheme, FullscreenAlertSettings, NotificationsProvider, UseLocalStorage, useLocalStorage } from '@chic-loyalty/ui';
import { appConfig } from '../appConfig.config';

const queryClient: QueryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } });

const App: React.FC = (): JSX.Element => {
  const [loggedUserData]: UseLocalStorage<string | null> = useLocalStorage<string | null>(LocalStorageKey.LoggedUserData, '');
  const [serializedAppState, setAppStateStorage]: UseLocalStorage<string | null> = useLocalStorage<string | null>(
    LocalStorageKey.AppState, '',
  );
  const orderData: string | null = sessionStorage.getItem(SessionStorageKey.OrderDetails);
  const [authState, authDispatch] = useReducer(authReducer, JSON.parse(loggedUserData || '{}') as AuthState);
  const [orderState, orderDispatch] = useReducer(orderReducer, JSON.parse(orderData || '{}') as OrderState);
  const appStateFromLocalStorage: AppState | null = serializedAppState ? (serializedAppState || '{}') as unknown as AppState : null;
  const [appState, appStateDispatch] = useReducer(
    appStateReducer,
    {
      ...initialAppState,
      ...(appStateFromLocalStorage ?? {}),
    },
  );
  const valueForAuthContext: AuthContextType = useMemo((): AuthContextType => [authState, authDispatch], [authState]);
  const valueForOrderContext: OrderContextType = useMemo((): OrderContextType => [orderState, orderDispatch], [orderState]);
  const { configs }: UseInitial = useInitial();
  const [loggerInitialized, setLoggerInitialized] = useState<boolean>(false);

  // TODO: Fix NotificationsProvider to take initial state and fix serialization for functions
  const fullscreenAlertData: FullscreenAlertSettings | undefined = useMemo(
    (): FullscreenAlertSettings | undefined => {
      if (appState.fullscreenAlertData) {
        return appState.fullscreenAlertData;
      }
    },
    [appStateFromLocalStorage],
  );

  useEffect(
    (): void => {
      setAppStateStorage(JSON.stringify(appState));
    },
    [appState],
  );

  useEffect(
    (): void => {
      sessionStorage.setItem(SessionStorageKey.OrderDetails, JSON.stringify(orderState));
    },
    [orderState],
  );

  useEffect(
    (): void => {
      setFullscreenAlertDataAction(appStateDispatch, null);
      if (!loggerInitialized) {
        datadogLogs.init({
          clientToken: appConfig.logsSystemClientToken,
          site: 'datadoghq.eu',
          service: appConfig.applicationName,
          forwardErrorsToLogs: true,
          sampleRate: 100,
          env: appConfig.applicationInstance,
          version: appConfig.applicationVersion,
        });
        setLoggerInitialized(true);
      }
    },
    [],
  );

  // TODO: add loader
  return configs ? (
    <NotificationsProvider colorTheme={ComponentColorTheme.IC}>
      <ConfigsContext.Provider value={configs}>
        <AuthContext.Provider value={valueForAuthContext}>
          <OrderContext.Provider value={valueForOrderContext}>
            <QueryClientProvider client={queryClient}>
              <AppStateContext.Provider value={[appState, appStateDispatch]}>
                <Router>
                  <GlobalStyle />
                  <AppWrapper fullscreenAlertData={fullscreenAlertData}>
                    <Routes>
                      {/* common */}
                      <Route path={RoutingPath.SignIn} element={
                        <CheckAuth><SignInView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.RemindPassword} element={
                        <CheckAuth><RemindPasswordView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.ChangePassword} element={
                        <CheckAuth><ChangePasswordView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.CrmSignIn} element={
                        <CheckAuth><CrmSignInView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.CrmSignInRedirect} element={
                        <CheckAuth><CrmSignInRedirectView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.ReturnFromCrm} element={
                        <CheckAuth><CrmSignInAfterRedirectView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.Dashboard} element={
                        <CheckAuth><DashboardView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.Profile} element={
                        <CheckAuth><ProfileView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.UserRoleVerificationList} element={
                        <CheckAuth><UserRoleVerificationListView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.UserRoleVerification} element={
                        <CheckAuth><UserRoleVerificationView /></CheckAuth>
                      } />
                      {/* custom for forks apps */}
                      <Route path={RoutingPath.Registration} element={
                        <CheckAuth><RegistrationView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.RegistrationWithoutVerification} element={
                        <CheckAuth><RegistrationWithoutSmsView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.SubscriptionContract} element={
                        <CheckAuth><SubscriptionContractView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.SubscriptionDelivery} element={
                        <CheckAuth><SubscriptionDeliveryView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.SubscriptionDeliveryPickupPoint} element={
                        <CheckAuth><SubscriptionDeliveryPickupPointChooseView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.SubscriptionParametersChoose} element={
                        <CheckAuth><SubscriptionParametersChooseView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.SubscriptionPayment} element={
                        <CheckAuth><SubscriptionPaymentView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.SubscriptionProductsChoose} element={
                        <CheckAuth><SubscriptionProductsChooseView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.SubscriptionSummary} element={
                        <CheckAuth><SubscriptionSummaryView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.SubscriptionUserVerification} element={
                        <CheckAuth><SubscriptionUserVerificationView /></CheckAuth>
                      } />
                      <Route path={RoutingPath.NotFound} element={
                        <CheckAuth><NotFoundView /></CheckAuth>
                      } />
                    </Routes>
                  </AppWrapper>
                </Router>
              </AppStateContext.Provider>
            </QueryClientProvider>
          </OrderContext.Provider>
        </AuthContext.Provider>
      </ConfigsContext.Provider>
    </NotificationsProvider>
  ) : <></>;
};

export default App;
