export enum AppStateAction {
  // common
  SetStateToInitial = 'setStateToInitial',
  SetFullscreenAlertData = 'setFullscreenAlertData',
  // custom for forks apps
  SetDeliveryData = 'setDeliveryData',
  SetDeliveryInpostData = 'setDeliveryInpostData',
  SetDeliveryPickupPoint = 'setDeliveryPickupPoint',
  SetPlanCategoryId = 'setPlanCategoryId',
  SetPlanId = 'setPlanId',
  AddProduct = 'addProduct',
  RemoveProduct = 'removeProduct',
  ChangeProductAmount = 'changeProductAmount',
  ClearSubscriptionData = 'clearSubscriptionData',
  RemoveProducts = 'removeProducts',
  SetConsumerData = 'setConsumerData',
  ClearConsumerData = 'clearConsumerData',
  SetSubscriptionSigningDate = 'setSubscriptionSigningDate',
  SetSubscriptionSigningLocation = 'setSubscriptionSigningLocation',
  SetSubscriptionContractNumber = 'setSubscriptionContractNumber',
  SetSubscriptionCreationMethod = 'setSubscriptionCreationMethod',
  SetFlowInProgress = 'setFlowInProgress',
}
