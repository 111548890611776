import { Breakpoint, Grid, PageTitle, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const MainContainer: StyledComponent<'div'> = styled.div`
  height: 100%;
  width: 100%;
  max-width: ${Grid.ICTablet};
  padding: 0 24px 62px;

  @media ${Breakpoint.Mobile} {
    padding: 0 24px 30px;
  }
`;

export const SmallPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  margin: 0 0 40px;
`;
