import React, { ChangeEvent } from 'react';
import * as Yup from 'yup';

import { useTitle } from '@chic/hooks';
import { TransProps, useTranslation } from 'react-i18next';

import { 
  AccordionInputs, 
  AgreementMessage, 
  AgreementsBox, 
  ButtonWrapper, 
  Container, 
  FinishButtonWrapper, 
  InputsWrapper, 
  PageTitleWrapper,
  RegistrationBox, 
  RequiredLabel, 
  SmsInput, 
  StyledAccordionSimpleItem, 
  StyledForm, 
  StyledPageTitle, 
  StyledValidationBar, 
  VerificationBox,
  VerificationDescription, 
} from './registration.styled';
import { 
  Button,
  ButtonTheme,
  Checkbox, 
  ComponentColorTheme, 
  Country, 
  Input, 
  InputDescriptionTheme, 
  InputTheme, 
  PageTitle, 
  PageTitleSize,
  SmsCounterBar,
} from '@chic-loyalty/ui';
import { Form, Formik, FormikProps } from 'formik';
import { RegistrationFormData, UseRegistration, VerificationFormData } from './registration.types';
import { AgreementName } from '@chic/enums';
import { RegistrationField } from './registration.enums';
import { useRegistration } from './registration.hooks';
import { useRegistrationValidation, useVerificationValidation } from './hooks';

export const RegistrationView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { 
    agreements, 
    handleRegistration, 
    handleRegistrationWithSmsCode, 
    toggleAllCheckbox, 
    sendSmsCode, 
    registrationApiError,
    registrationFormData,
    allAgreementsSelected,
    agreementPrefix,
    smsBoxRef,
    isFinishButtonLoading,
    isRegistrationButtonLoading,
  }: UseRegistration = useRegistration();
  useTitle(t('chic.hostess.registrationView.title'));
  const registrationValidationSchema: Yup.Schema<RegistrationFormData> = useRegistrationValidation();
  const verificationValidationSchema: Yup.Schema<VerificationFormData> = useVerificationValidation();

  return (
    <Container>
      <PageTitleWrapper>
        <PageTitle
          label={t('chic.hostess.registrationView.pageTitle')}
          size={PageTitleSize.Big}
        />
      </PageTitleWrapper>
      <RegistrationBox>
        <StyledPageTitle label={t('chic.hostess.registrationView.registrationBox.pageTitle')} />
        <Formik
          validationSchema={registrationValidationSchema}
          initialValues={{ 
            name: '', 
            surname: '', 
            email: '', 
            phone: '', 
            loyaltyProgramRegulations: false, 
            declarationOfAge: false, 
            personalDataAgreement: false, 
          }}
          onSubmit={handleRegistration}
        >
          {({ setFieldValue, errors, touched, values }: FormikProps<RegistrationFormData>): JSX.Element => (
            <Form>
              <InputsWrapper>
                <Input
                  onChange={(value: string): void => setFieldValue(RegistrationField.Name, value)}
                  label={t('chic.hostess.registrationView.name.label')}
                  placeholder={t('chic.hostess.registrationView.name.placeholder')}
                  value={values.name}
                  description={touched.name ? errors.name : undefined}
                  inputTheme={errors.name && touched.name ? InputTheme.Error : InputTheme.Standard}
                  colorTheme={ComponentColorTheme.IC}
                  disabled={!!registrationFormData}
                  required
                />
                <Input
                  onChange={(value: string): void => setFieldValue(RegistrationField.Surname, value)}
                  label={t('chic.hostess.registrationView.surname.label')}
                  placeholder={t('chic.hostess.registrationView.surname.placeholder')}
                  value={values.surname}
                  description={touched.surname ? errors.surname : undefined}
                  inputTheme={errors.surname && touched.surname ? InputTheme.Error : InputTheme.Standard}
                  colorTheme={ComponentColorTheme.IC}
                  disabled={!!registrationFormData}
                  required
                />
                <Input
                  onChange={(value: string): void => setFieldValue(RegistrationField.Email, value)}
                  label={t('chic.hostess.registrationView.email.label')}
                  placeholder={t('chic.hostess.registrationView.email.placeholder')}
                  value={values.email}
                  description={touched.email ? errors.email : undefined}
                  inputTheme={errors.email && touched.email ? InputTheme.Error : InputTheme.Standard}
                  colorTheme={ComponentColorTheme.IC}
                  disabled={!!registrationFormData}
                  required
                />
                <Input
                  onChange={(value: string): void => setFieldValue(RegistrationField.Phone, value)}
                  label={t('chic.hostess.registrationView.phone.label')}
                  placeholder={t('chic.hostess.registrationView.phone.placeholder')}
                  value={values.phone}
                  description={(touched.phone ? errors.phone : undefined) 
                      ?? t('chic.hostess.registrationView.phone.description')}
                  descriptionTheme={InputDescriptionTheme.Yellow}
                  inputTheme={errors.phone && touched.phone ? InputTheme.Error : InputTheme.Standard}
                  colorTheme={ComponentColorTheme.IC}
                  disabled={!!registrationFormData}
                  customPhonePrefixes={[
                    {
                      countryLabel: t('chic.hostess.global.poland'),
                      country: Country.Poland,
                      prefix: '+48',
                    },
                  ]}
                  hasPhonePrefix
                  required
                />
                <RequiredLabel>{t('chic.hostess.registrationView.requiredLabel.inputs')}</RequiredLabel>
                <StyledAccordionSimpleItem label={t('chic.hostess.registrationView.accordionItem.optional')}>
                  <AccordionInputs>
                    <Input
                      onChange={(value: string): void => setFieldValue(RegistrationField.Birthday, value)}
                      label={t('chic.hostess.registrationView.birthday.label')}
                      placeholder={t('chic.hostess.registrationView.birthday.placeholder')}
                      value={values.birthday}
                      description={touched.birthday ? errors.birthday : undefined}
                      inputTheme={errors.birthday && touched.birthday ? InputTheme.Error : InputTheme.Standard}
                      colorTheme={ComponentColorTheme.IC}
                      disabled={!!registrationFormData}
                      numericMask='##-##-####'
                    />
                    <Input
                      onChange={(value: string): void => setFieldValue(RegistrationField.PostalCode, value)}
                      label={t('chic.hostess.registrationView.postalCode.label')}
                      placeholder={t('chic.hostess.registrationView.postalCode.placeholder')}
                      value={values.postalCode}
                      description={touched.postalCode ? errors.postalCode : undefined}
                      inputTheme={errors.postalCode && touched.postalCode ? InputTheme.Error : InputTheme.Standard}
                      colorTheme={ComponentColorTheme.IC}
                      disabled={!!registrationFormData}
                      numericMask='##-###'
                    />
                  </AccordionInputs>
                </StyledAccordionSimpleItem>
              </InputsWrapper>
              {!!Object.entries(agreements).length && (
                <>
                  <AgreementsBox>
                    <Checkbox 
                      id='all'
                      label={t('chic.hostess.registrationView.checkbox.all')} 
                      onChange={(): void => toggleAllCheckbox(setFieldValue)}
                      colorTheme={ComponentColorTheme.IC} 
                      checked={allAgreementsSelected}
                      disabled={!!registrationFormData}
                    />
                  </AgreementsBox>
                  <AgreementsBox>
                    <Checkbox 
                      id={AgreementName.LoyaltyProgramRegulations}
                      label={t('chic.hostess.registrationView.checkbox.loyaltyProgramRegulations')}
                      onChange={(value: boolean): void => setFieldValue(AgreementName.LoyaltyProgramRegulations, value)}
                      colorTheme={ComponentColorTheme.IC} 
                      checked={values.loyaltyProgramRegulations}
                      disabled={!!registrationFormData}
                      error={touched.loyaltyProgramRegulations ? errors.loyaltyProgramRegulations : undefined}
                    />
                    <Checkbox 
                      id={AgreementName.DeclarationOfAge}
                      label={agreements[AgreementName.DeclarationOfAge].content + '*'}
                      onChange={(value: boolean): void => setFieldValue(AgreementName.DeclarationOfAge, value)}
                      colorTheme={ComponentColorTheme.IC} 
                      checked={values.declarationOfAge}
                      disabled={!!registrationFormData}
                      error={touched.declarationOfAge ? errors.declarationOfAge : undefined}
                    />
                    <Checkbox 
                      id={AgreementName.PersonalDataAgreement}
                      label={agreements[AgreementName.PersonalDataAgreement].content + '*'}
                      onChange={(value: boolean): void => setFieldValue(AgreementName.PersonalDataAgreement, value)}
                      colorTheme={ComponentColorTheme.IC} 
                      checked={values.personalDataAgreement}
                      disabled={!!registrationFormData}
                      error={touched.personalDataAgreement ? errors.personalDataAgreement : undefined}
                    />
                  </AgreementsBox>
                  <AgreementsBox>
                    <RequiredLabel>{t('chic.hostess.registrationView.requiredLabel.agreements')}</RequiredLabel>
                    <AgreementMessage text={agreementPrefix} />
                    <Checkbox 
                      id={AgreementName.MarketingAgreement}
                      label={agreements[AgreementName.MarketingAgreement].content}
                      onChange={(value: boolean): void => setFieldValue(AgreementName.MarketingAgreement, value)}
                      colorTheme={ComponentColorTheme.IC} 
                      checked={values.marketingAgreement}
                      disabled={!!registrationFormData}
                    />
                    <Checkbox 
                      id={AgreementName.MarketingAdditionalAgreement}
                      label={agreements[AgreementName.MarketingAdditionalAgreement].content}
                      onChange={(value: boolean): void => setFieldValue(AgreementName.MarketingAdditionalAgreement, value)}
                      colorTheme={ComponentColorTheme.IC} 
                      checked={values.marketingAdditionalAgreement}
                      disabled={!!registrationFormData}
                    />
                    <Checkbox 
                      id={AgreementName.MarketingSocialAgreement}
                      label={agreements[AgreementName.MarketingSocialAgreement].content}
                      onChange={(value: boolean): void => setFieldValue(AgreementName.MarketingSocialAgreement, value)}
                      colorTheme={ComponentColorTheme.IC} 
                      checked={values.marketingSocialAgreement}
                      disabled={!!registrationFormData}
                    />
                  </AgreementsBox>
                  <AgreementsBox>
                    <AgreementMessage text={t('chic.hostess.registrationView.agreements.message')} />
                  </AgreementsBox>
                </>
              )}
              <StyledValidationBar message={registrationApiError} colorTheme={ComponentColorTheme.IC} />
              <ButtonWrapper>
                <Button 
                  type='submit' 
                  label={t('chic.hostess.registrationView.button.register')} 
                  buttonTheme={ButtonTheme.ICPrimary} 
                  disabled={!!registrationFormData} 
                  isLoading={isRegistrationButtonLoading}
                />
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </RegistrationBox>
      {!!registrationFormData && (
        <Formik
          validationSchema={verificationValidationSchema}
          initialValues={{ smsCode: '' }}
          onSubmit={handleRegistrationWithSmsCode}
        >
          {({ values, setFieldValue, errors }: FormikProps<VerificationFormData>): JSX.Element => (
            <StyledForm>
              <VerificationBox ref={smsBoxRef}>
                <PageTitle label={t('chic.hostess.registrationView.verificationBox.pageTitle')} />
                <VerificationDescription>
                  {t('chic.hostess.registrationView.verificationBox.description')}
                </VerificationDescription>
                <SmsCounterBar 
                  countdownSeconds={180} 
                  buttonAction={sendSmsCode}
                  disabled={!registrationFormData}
                >
                  <SmsInput 
                    placeholder={t('chic.hostess.smsInput.placeholder')}
                    onChange={
                      (value: ChangeEvent<HTMLInputElement>): void => setFieldValue(RegistrationField.SmsCode, value.currentTarget.value)
                    }
                    value={values.smsCode}
                    disabled={!registrationFormData}
                  />
                </SmsCounterBar>
                <StyledValidationBar message={errors.smsCode} colorTheme={ComponentColorTheme.IC} />
              </VerificationBox>
              <FinishButtonWrapper>
                <Button 
                  type='submit' 
                  label={t('chic.hostess.registrationView.button.finish')} 
                  buttonTheme={ButtonTheme.ICPrimary} 
                  disabled={!registrationFormData} 
                  isLoading={isFinishButtonLoading}
                />
              </FinishButtonWrapper>
            </StyledForm>
          )}
        </Formik>
      )}
    </Container>
  );
};
