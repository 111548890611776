export enum RegistrationField {
  Name = 'name',
  Surname = 'surname',
  Phone = 'phone',
  Email = 'email',
  PostalCode = 'postalCode',
  Birthday = 'birthday',
  SmsCode = 'smsCode',
  Agreements = 'agreements',
}
