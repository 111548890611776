import {
  LoyaltySystemConstantType,
  QueryKey,
  RoutingPath,
  SubscriptionPlanLevel,
  SubscriptionPlanPropertyType,
} from '@chic/enums';
import {
  FrontendApiError,
  ObjectIdWithAmount,
  PlanStage,
  SubscriptionDetails,
  SubscriptionPlanGroup,
  SubscriptionPlanSimple,
  SubscriptionSimplePlanProduct,
  UseStatics,
} from '@chic/models';
import { AcceptContractActionData, UseSubscriptionContract } from './subscriptionContract.types';
import { getPlanPropertyValueByType } from '@chic/utils';
import { TransProps, useTranslation } from 'react-i18next';
import {
  ElementsTableRowData,
  FullscreenAlertTheme,
  UseNotifications,
  UseParsers,
  UseRedirect,
  UseState,
  useNotifications,
  useParsers,
  useRedirect,  
} from '@chic-loyalty/ui';
import duration from 'dayjs/plugin/duration';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { cancelSubscription, confirmSubscription, getSubscriptionPlans } from '@chic/api';
import { useMemo, useState } from 'react';
import { useStatics } from '@chic/hooks';

export const useSubscriptionContract: (currency: string) => UseSubscriptionContract = (currency: string): UseSubscriptionContract => {
  const { t }: TransProps<never> = useTranslation();
  const { parsePrice }: UseParsers = useParsers();
  const { redirect }: UseRedirect = useRedirect();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { getConstantValueFromStatics }: UseStatics = useStatics();
  const [isAcceptContractLoading, setIsAcceptContractLoading]: UseState<boolean> = useState<boolean>(false);
  const [subscriptionPlans, setSubscriptionPlans]: UseState<SubscriptionPlanSimple[] | null>
    = useState<SubscriptionPlanSimple[] | null>(null);
  const planStagesTableData: PlanStage[] | null = useMemo((): PlanStage[] | null => {
    if (!subscriptionPlans) {
      return null;
    }

    return subscriptionPlans.map((plan: SubscriptionPlanSimple): PlanStage => {
      const priceFrom: number = getPlanPropertyValueByType(plan.properties, SubscriptionPlanPropertyType.PriceFrom) ?? 0;
      const priceTo: number = getPlanPropertyValueByType(plan.properties, SubscriptionPlanPropertyType.PriceTo) ?? 0;
      const discount: number = getPlanPropertyValueByType(plan.properties, SubscriptionPlanPropertyType.Discount) ?? 0;
      const hasFreeDelivery: boolean = getPlanPropertyValueByType(plan.properties, SubscriptionPlanPropertyType.FreeDelivery) ?? false;
      let description: string = '';
      
      if (hasFreeDelivery) {
        description += t('chic.hostess.useSubscriptionContract.planStage.freeDelivery');
      }
      if (discount === 0) {
        description += `${description.length ? ' + ' : ''}${t('chic.hostess.useSubscriptionContract.planStage.regularPrice')}`;
      } else {
        description +=
          `${description.length ? ' + ' : ''}${t('chic.hostess.useSubscriptionContract.planStage.orderDiscount', { discount })}`;
      }
  
      return {
        priceRange: `${priceFrom} - ${priceTo} ${currency}`,
        discountDescription: description,
      };
    });
  }, [subscriptionPlans]);

  useQuery(
    [QueryKey.SubscriptionPlans],
    (): Promise<SubscriptionPlanSimple[]> => getSubscriptionPlans(),
    {
      onSuccess: setSubscriptionPlans,
      onError: (): void => undefined,
    },
  );

  const getPlanLevelProductsDetails: (planLevel: SubscriptionPlanLevel) => Record<number, SubscriptionSimplePlanProduct>
    = (planLevel: SubscriptionPlanLevel): Record<number, SubscriptionSimplePlanProduct> => {
      const userPlanStaticData: SubscriptionPlanSimple | undefined = subscriptionPlans?.find((plan: SubscriptionPlanSimple): boolean => {
        return plan.level === planLevel;
      });
      const productsDetailsById: Record<number, SubscriptionSimplePlanProduct> = {};

      userPlanStaticData?.productsGroups.forEach((group: SubscriptionPlanGroup<SubscriptionSimplePlanProduct>): void => {
        group.products.forEach((product: SubscriptionSimplePlanProduct): void => {
          if (!productsDetailsById[product.id]) {
            productsDetailsById[product.id] = product;
          }
        });
      });

      return productsDetailsById;
    };

  const getUserProductsTableData: (subscriptionDetails: SubscriptionDetails) => ElementsTableRowData[]
    = (subscriptionDetails: SubscriptionDetails): ElementsTableRowData[] => {
      const productsDetailsById: Record<number, SubscriptionSimplePlanProduct>
        = getPlanLevelProductsDetails(subscriptionDetails.plan.level);

      return subscriptionDetails.products.map((product: ObjectIdWithAmount): ElementsTableRowData => {
        const productDetails: SubscriptionSimplePlanProduct | undefined = productsDetailsById[product.id];

        return {
          nodes: [
            productDetails.name,
            product.amount.toString(),
            `${parsePrice(productDetails.price)} ${currency}`,
          ],
        };
      });
    };

  const handleCancelButtonClick: (subscriptionId: number, customerId: number) => void
    = (subscriptionId: number, customerId: number): void => {
      showFullscreenAlert({
        title: t('chic.hostess.useSubscriptionContract.cancelSubscriptionAlert.title'),
        acceptButtonSettings: {
          label: t('chic.crmApp.global.yes'),
          action: (): void => {
            cancelSubscription(subscriptionId, customerId).then((): void => {
              hideFullscreenAlert();
              redirect(RoutingPath.SubscriptionUserVerification);
            }).catch((error: FrontendApiError): void => {
              showFullscreenAlert({
                description: error.message ?? '',
                fullscreenAlertTheme: FullscreenAlertTheme.Error,
                acceptButtonSettings: { 
                  label: t('chic.crmApp.global.close'), 
                  action: () => hideFullscreenAlert(), 
                },
              });
            });
          },
        },
        cancelButtonSettings: {
          label: t('chic.crmApp.global.no'),
          action: hideFullscreenAlert,
        },
      });
    };

  const handleCloseAlertAction: () => void = (): void => {
    hideFullscreenAlert();
    redirect(RoutingPath.SubscriptionUserVerification);
  };

  const getDateExtendedByInterval: (date: Date, interval: string, format?: string) => string = (
    date: Date, interval: string, format: string = 'DD.MM.YYYY',
  ): string => {
    dayjs.extend(duration);
    const dateObject: dayjs.Dayjs = dayjs(date);
    const durationObject: duration.Duration = dayjs.duration(interval);
  
    return dateObject.add(durationObject).format(format);
  };
  
  const acceptContractAction: (values: AcceptContractActionData) => void = (values: AcceptContractActionData): void => {
    const { subscriptionDetails, contractAddress, smsCode }: AcceptContractActionData = values;

    if (!subscriptionDetails) {
      return;
    }

    setIsAcceptContractLoading(true);
    confirmSubscription({
      subscriptionId: subscriptionDetails.id,
      customerId: subscriptionDetails.customer.id,
      location: contractAddress,
      smsCode,
    }).then((response: SubscriptionDetails): void => {
      const expirationDate: string = getDateExtendedByInterval(
        new Date(),
        getConstantValueFromStatics(LoyaltySystemConstantType.PaymentValidityPeriod),
      );
      showFullscreenAlert({
        title: t('chic.crmApp.global.success'),
        description: t('chic.hostess.useSubscriptionContract.acceptContractAction.description'),
        fullscreenAlertTheme: FullscreenAlertTheme.Success,
        acceptButtonSettings: { label: t('chic.crmApp.global.close'), action: handleCloseAlertAction },
        nameValueFields: [{
          name: t('chic.hostess.useSubscriptionContract.acceptContractAction.paymentExpirationLabel'),
          value: expirationDate,
        }, {
          name: t('chic.hostess.useSubscriptionContract.acceptContractAction.orderValueLabel'),
          value: `${parsePrice(response.orders[0]?.price ?? 0)} ${currency}`,
        }],
      });
    }).catch((error: FrontendApiError): void => {
      showFullscreenAlert({
        description: error.message ?? '',
        fullscreenAlertTheme: FullscreenAlertTheme.Error,
        acceptButtonSettings: { 
          label: t('chic.crmApp.global.close'), 
          action: () => hideFullscreenAlert(), 
        },
      });
    }).finally((): void => setIsAcceptContractLoading(false));
  };

  return {
    acceptContractAction,
    getUserProductsTableData,
    handleCancelButtonClick,
    isAcceptContractLoading,
    planStagesTableData,
  };
};
