import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { birthdayRegexp, phoneRegexp, postalCodeRegexp } from '@chic/constans';
import { AgreementName } from '@chic/enums';
import { RegistrationField } from '../registration.enums';
import { RegistrationFormData } from '../registration.types';

export const useRegistrationValidation: () => Yup.Schema<RegistrationFormData> = (): Yup.Schema<RegistrationFormData> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    [RegistrationField.Name]: Yup.string()
      .min(2, t('chic.crmApp.global.wrongValue'))
      .max(64, t('chic.crmApp.global.wrongValue'))
      .required(t('chic.crmApp.global.requiredField')),
    [RegistrationField.Surname]: Yup.string()
      .min(2, t('chic.crmApp.global.wrongValue'))
      .max(64, t('chic.crmApp.global.wrongValue'))
      .required(t('chic.crmApp.global.requiredField')),
    [RegistrationField.Email]: Yup.string()
      .email(t('chic.crmApp.global.wrongValue'))
      .required(t('chic.crmApp.global.requiredField')),
    [RegistrationField.Phone]: Yup.string()
      .matches(phoneRegexp, t('chic.crmApp.global.wrongValue'))
      .required(t('chic.crmApp.global.requiredField')),
    [RegistrationField.Birthday]: Yup.string()
      .matches(birthdayRegexp, t('chic.crmApp.global.wrongValue')),
    [RegistrationField.PostalCode]: Yup.string()
      .matches(postalCodeRegexp, t('chic.crmApp.global.wrongValue')),
    [AgreementName.LoyaltyProgramRegulations]: Yup.bool()
      .required(t('chic.crmApp.global.requiredField'))
      .oneOf([true], t('chic.crmApp.global.requiredField')),
    [AgreementName.DeclarationOfAge]: Yup.bool()
      .required(t('chic.crmApp.global.requiredField'))
      .oneOf([true], t('chic.crmApp.global.requiredField')),
    [AgreementName.PersonalDataAgreement]: Yup.bool()
      .required(t('chic.crmApp.global.requiredField'))
      .oneOf([true], t('chic.crmApp.global.requiredField')),
    [AgreementName.MarketingAgreement]: Yup.bool().optional(),
    [AgreementName.MarketingAdditionalAgreement]: Yup.bool().optional(),
    [AgreementName.MarketingSocialAgreement]: Yup.bool().optional(),
  });
};
