import { Breakpoint, Button, Color, PageTitle, SearchDeliveryListItem, StyledComponent, disableScroll } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  position: relative;
  min-height: calc(100vh - 171px);
  padding: 24px 0 24px 24px;

  @media ${Breakpoint.Tablet} {
    padding: 24px 0 20px;
  }

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  padding: 8px 0;
  
  @media ${Breakpoint.Tablet} {
    padding: 8px 20px;
  }
`;

export const InputWrapper: StyledComponent<'div'> = styled.div`
  padding: 16px 20px 20px;
`;

export const InnerContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 24px;

  @media ${Breakpoint.Tablet} {
    flex-direction: column;
    height: 100%;
  }
`;

export const MapContainer: StyledComponent<'div'> = styled.div`
  height: 700px;
  width: 651px;
  margin-left: 20px;
  flex-shrink: 0;

  @media ${Breakpoint.Desktop} {
    width: 50%;
  }

  @media ${Breakpoint.Tablet} {
    order: 1;
    height: auto;
    width: 100%;
    margin-left: 0;

    .leaflet-custom-container {
      height: 40vh;
      max-height: 325px;
    }
  }
`;

export const SearchContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  @media ${Breakpoint.Tablet} {
    order: 2;
    padding: 20px;
    height: 375px;
  }
`;

export const ListContainer: StyledComponent<'div'> = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ${disableScroll};
`;

export const OuterListContainer: StyledComponent<'div'> = styled.div`
  position: relative;
  max-height: 240px;
  height: 100%;
  display: flex;

  @media ${Breakpoint.Mobile} {
    max-height: 254px;
  }
`;

export const StyledSearchDeliveryListItem: StyledComponent<typeof SearchDeliveryListItem> = styled(SearchDeliveryListItem)`
  display: flex;
  padding: 20px 0;

  & + & {
    padding-top: 20px;
    border-top: 1px solid ${Color.ICWhite100};
  }
`;

export const ButtonWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 10px 0 15px;
  margin-top: 30px;
  
  @media ${Breakpoint.Tablet} {
    justify-content: center;
    width: calc(100% + 40px);
    background-color: ${Color.ICBlack400};
    margin-left: -20px;
    position: sticky;
    bottom: 0;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin-left: 12px;

  @media ${Breakpoint.Tablet} {
    width: calc(100% - 52px);
  }
`;
