import { OrderAction } from '@chic/enums';
import { OrderActions, OrderState } from '@chic/models';

export const orderReducer = (state: OrderState, action: OrderActions): OrderState => {
  switch (action.type) {
    case OrderAction.ClearSessionStorage:
      return {};
    case OrderAction.SaveOrderStateValues:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
