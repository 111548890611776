import { AppState } from '@chic/models';

export const initialAppState: AppState = {
  // common
  fullscreenAlertData: null,
  // custom for forks apps
  flowInProgress: null,
  subscriptionChoices: {
    planId: null,
    subscriptionDeliveryDetails: null,
    products: [],
    signingDate: '',
    signingLocation: '',
    planCategoryId: null,
    contractNumber: '',
  },
  consumerData: {
    birthday: '',
    phone: '',
    ean: null,
    consumerId: null,
    name: '',
    surname: '',
    email: '',
    hasConfirmedEmail: null,
    permissions: [],
  },
};
