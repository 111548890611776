import { UseNotifications, UseRedirect, UseState, useNotifications, useRedirect } from '@chic-loyalty/ui';
import { registerUserData, getAgreements, resendVerificationSms } from '@chic/api';
import { AgreementName, RoutingPath, QueryKey, VerificationSmsType } from '@chic/enums';
import { useTitle } from '@chic/hooks';
import { AgreementType, ConsumerRegisterData, FrontendApiError } from '@chic/models';
import { RefObject, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { RegistrationFormData, UseRegistration, VerificationFormData } from './registration.types';

export const useRegistration: () => UseRegistration = (): UseRegistration => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { showFullscreenAlert, hideFullscreenAlert, addToast }: UseNotifications = useNotifications();
  const [agreements, setAgreements]: UseState<Record<string, AgreementType>> = useState<Record<string, AgreementType>>({});
  const [agreementPrefix, setAgreementPrefix]: UseState<string> = useState<string>('');
  const [allAgreementsSelected, setAllAgreementsSelected]: UseState<boolean> = useState<boolean>(false);
  const [registrationApiError, setRegistrationApiError]: UseState<string> = useState<string>('');
  const [registrationFormData, setRegistrationFormData]: UseState<ConsumerRegisterData | null> 
    = useState<ConsumerRegisterData | null>(null);
  const [isRegistrationButtonLoading, setIsRegistrationButtonLoading]: UseState<boolean> = useState<boolean>(false);
  const [isFinishButtonLoading, setIsFinishButtonLoading]: UseState<boolean> = useState<boolean>(false);
  useTitle(t('chic.hostess.registrationView.title'));
  const marketingAgreementPrefixRegex: RegExp = /^(?<before>.*)(\[prefix\])(?<linkLabel>.*)(\[\/prefix\])(?<after>.*)$/;
  const smsBoxRef: RefObject<HTMLDivElement> = useRef(null);

  const handleRegistration: (values: RegistrationFormData) => void = (values: RegistrationFormData): void => {  
    setIsRegistrationButtonLoading(true);
    const formData: ConsumerRegisterData = {
      name: values.name,
      surname: values.surname,
      email: values.email,
      phone: values.phone,
      birthday: values.birthday,
      postalCode: values.postalCode,
      agreements: [
        {
          name: AgreementName.DeclarationOfAge,
          value: values.declarationOfAge,
        },
        {
          name: AgreementName.PersonalDataAgreement,
          value: values.personalDataAgreement,
        },
        {
          name: AgreementName.MarketingAgreement,
          value: !!values.marketingAgreement,
        },
        {
          name: AgreementName.MarketingAdditionalAgreement,
          value: !!values.marketingAdditionalAgreement,
        },
        {
          name: AgreementName.MarketingSocialAgreement,
          value: !!values.marketingSocialAgreement,
        },
      ],
    };

    registerUserData(formData)
      .then((): void => {
        setRegistrationApiError('');
        setRegistrationFormData(formData);
        setTimeout(
          (): void => {
            smsBoxRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          },
          300,
        );
        addToast({ content: t('chic.hostess.useRegistration.registerUserData.success.toast') });
      })
      .catch((error: FrontendApiError): void => setRegistrationApiError(t(error.message ?? '')))
      .finally((): void => setIsRegistrationButtonLoading(false));
  };

  const handleRegistrationWithSmsCode: (values: VerificationFormData) => void = (values: VerificationFormData): void => {
    if (!registrationFormData) {
      return;
    }

    setIsFinishButtonLoading(true);
    registerUserData({
      ...registrationFormData,
      smsCode: values.smsCode,
    })
      .then((): void => {
        showFullscreenAlert({
          title: t('chic.hostess.useRegistration.fullscreenAlert.success.title'),
          description: t('chic.hostess.useRegistration.fullscreenAlert.success.description'),
          acceptButtonSettings: {
            label: t('chic.hostess.useRegistration.fullscreenAlert.success.buttonLabel'),
            action: (): void => {
              hideFullscreenAlert();
              redirect(RoutingPath.Dashboard);
            },
          },
        });
      })
      .catch((error: FrontendApiError): void => {
        showFullscreenAlert({
          title: t(error.message ?? ''),
          acceptButtonSettings: {
            label: t('chic.crmApp.global.close'),
            action: (): void => hideFullscreenAlert(),
          },
        });
      })
      .finally((): void => setIsFinishButtonLoading(false));
  };

  useQuery(
    [QueryKey.Agreements],
    (): Promise<AgreementType[]> => getAgreements(),
    {
      onSuccess: (data: AgreementType[]): void => {
        const agreementsFromApi: AgreementType[] = data
          .map((item: AgreementType): AgreementType => {
            const agreement: AgreementType = { ...item };
            // TODO: TEMPORARY FIX
            // eslint-disable-next-line no-useless-escape
            agreement.content = agreement.content.replace(/\„eSmoking\ Club\”\ 3\.0/g, '„Inspiration Club”');
            const matched: RegExpMatchArray | null = agreement.content.match(marketingAgreementPrefixRegex);
            if (matched?.groups?.linkLabel && !agreementPrefix) {
              setAgreementPrefix(matched?.groups?.linkLabel);
            }

            return {
              ...agreement,
              content: matched
                ? matched?.groups?.after ?? ''
                : agreement.content,
            };
          });

        const agreementsRecord: Record<string, AgreementType> = {};
        agreementsFromApi.forEach((agreement: AgreementType): void => {
          agreementsRecord[agreement.agreementName] = agreement;
        });
        setAgreements(agreementsRecord);
      },
      onError: (): void => undefined,
    },
  );

  const toggleAllCheckbox: (setFieldValue: (field: string, value: boolean) => void) => void = (
    setFieldValue: (field: string, value: boolean) => void,
  ): void => {
    if (Object.entries(agreements).length) {
      if (allAgreementsSelected) {
        setFieldValue(AgreementName.LoyaltyProgramRegulations, false);
        Object.entries(agreements).forEach((agreement: [string, AgreementType]) => {
          setFieldValue(agreement[0], false);
        });
      } else {
        setFieldValue(AgreementName.LoyaltyProgramRegulations, true);
        Object.entries(agreements).forEach((agreement: [string, AgreementType]) => {
          setFieldValue(agreement[0], true);
        });
      }
      setAllAgreementsSelected(!allAgreementsSelected);
    }
  };

  const sendSmsCode: () => void = (): void => {
    if (!registrationFormData?.phone) {
      return;
    }

    resendVerificationSms({
      type: VerificationSmsType.Registration,
      phone: registrationFormData.phone,
    })
      .then((): void => undefined)
      .catch((error: FrontendApiError): void => addToast({ content: t(error.message ?? '') }));
  };

  return { 
    agreements, 
    handleRegistration, 
    handleRegistrationWithSmsCode, 
    toggleAllCheckbox, 
    sendSmsCode, 
    registrationApiError,
    registrationFormData,
    allAgreementsSelected,
    agreementPrefix,
    smsBoxRef,
    isRegistrationButtonLoading,
    isFinishButtonLoading,
  };
};
