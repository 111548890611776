export enum SubscriptionExtraFieldType {
  TouchPoint = 'touchpoint',
  EmployeeId = 'employeeId',
  EmployeeName = 'employeeName',
  Attorney = 'attorney',
  AttorneysList = 'attorneysList',
  Location = 'location',
  SigningDate = 'signingDate',
  SigningLocation = 'signingLocation',
  CustomNumber = 'customNumber',
}
