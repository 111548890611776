import { Breakpoint, Button, Color, Grid, Input, Markdown, PageTitle, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';
import { SmallPageTitleProps } from './subscriptionDelivery.types';

export const MainContainer: StyledComponent<'div'> = styled.div`
  height: 100%;
  max-width: ${Grid.ICTablet};
  width: 100%;
  padding: 0 24px 62px;

  @media ${Breakpoint.Mobile} {
    padding: 0 24px 30px;
  }
`;

export const FormikForm: StyledComponent<'form'> = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SmallPageTitle: StyledComponent<typeof PageTitle, SmallPageTitleProps> = styled(PageTitle)<SmallPageTitleProps>`
  margin: ${({ $withMarginTop }: SmallPageTitleProps): string => $withMarginTop ? '62px 0 40px' : ' 0 0 40px'};
`;

export const Address: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 14px;
  line-height: 24px;
  color: ${Color.ICWhite100};
`;

export const AddressButton: StyledComponent<typeof Button> = styled(Button)`
  margin-top: 16px;
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  width: 100%;
  
  & + & {
    margin-top: 20px;
  }
`;

export const InputWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;

  ${StyledInput} {
    width: 50%;
    margin-top: 0;
  }
`;
