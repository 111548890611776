import { UseState } from '@chic-loyalty/ui';
import { useState } from 'react';

import { useQuery } from 'react-query';
import { DictionaryObject, LoyaltySystemConstant, NameCode, SubscriptionDictionary, UseStatics } from '@chic/models';
import {
  DictionaryObjectType,
  QueryKey,
  LoyaltySystemConstantType,
  CyclicOrderPeriod,
  PosPaymentOptions,
  SubscriptionParameterType,
} from '@chic/enums';
import { getSubscriptionDictionary } from '@chic/api';
import { SubscriptionStaticParameter } from '@chic/types';

export const useStatics: () => UseStatics = (): UseStatics => {
  const [constants, setConstants]: UseState<LoyaltySystemConstant[]> = useState<LoyaltySystemConstant[]>([]);
  const [dictionary, setDictionary]: UseState<DictionaryObject[]> = useState<DictionaryObject[]>([]);
  const [parameters, setParameters]: UseState<SubscriptionStaticParameter[]> = useState<SubscriptionStaticParameter[]>([]);
  const [isStaticsReady, setIsStaticsReady]: UseState<boolean> = useState<boolean>(false);

  useQuery(
    [QueryKey.SubscriptionStatic],
    (): Promise<SubscriptionDictionary> => getSubscriptionDictionary(),
    {
      onSuccess: (data: SubscriptionDictionary): void => {
        setConstants(data.constants);
        setDictionary(data.dictionary);
        setParameters(data.parameters);
        setIsStaticsReady(true);
      },
      // TODO: add logger
      onError: (): void => undefined,
    },
  );

  const getConstantValueFromStatics: (name: LoyaltySystemConstantType) => string = (name: LoyaltySystemConstantType): string => {
    return constants.find((constant: LoyaltySystemConstant): boolean => constant.name === name)?.value ?? '';
  };

  const getValueFromDictionary: (type: DictionaryObjectType) => string | string[] = (type: DictionaryObjectType) => {
    return dictionary.find((obj: DictionaryObject): boolean => type === obj.name)?.value ?? [];
  };

  const getParameterLabel: (parameterType: SubscriptionParameterType) => string = (parameterType: SubscriptionParameterType): string => {
    return parameters
      .find((item: SubscriptionStaticParameter): boolean => item.type === parameterType)
      ?.name ?? '';
  };

  const getParameterOptionLabel: (
    parameterType: SubscriptionParameterType, optionName: CyclicOrderPeriod | PosPaymentOptions
  ) => string = (
    parameterType: SubscriptionParameterType, optionName: CyclicOrderPeriod | PosPaymentOptions,
  ): string => {
    const parameterOptions: NameCode[] = parameters
      .find((item: SubscriptionStaticParameter): boolean => item.type === parameterType)
      ?.values ?? [];

    return parameterOptions.find((item: NameCode): boolean => item.code === optionName)?.name ?? '';
  };
  
  return {
    getConstantValueFromStatics,
    getValueFromDictionary,
    getParameterLabel,
    getParameterOptionLabel,
    parameters,
    isStaticsReady,
  };
};
