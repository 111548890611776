import { RoutingPath } from '@chic/enums';

export const orderPaths: RoutingPath[] = [
  RoutingPath.SubscriptionContract,
  RoutingPath.SubscriptionDelivery,
  RoutingPath.SubscriptionDeliveryPickupPoint,
  RoutingPath.SubscriptionPayment,
  RoutingPath.SubscriptionProductsChoose,
  RoutingPath.SubscriptionParametersChoose,
  RoutingPath.SubscriptionSummary,
  RoutingPath.SubscriptionUserVerification,
];
