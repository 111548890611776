import React from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { Container, LogoBox, StyledLogo, StyledButton, LoginBox, InnerBlackBox, Title } from './crmSignIn.styled';
import { RoutingPath } from '@chic/enums';
import { useTitle } from '@chic/hooks';
import { useMediaQuery } from 'react-responsive';
import { Breakpoint, ButtonTheme, LogoTheme, UseRedirect, useRedirect } from '@chic-loyalty/ui';

export const CrmSignInView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  useTitle(t('chic.crmApp.crmSignIn.title'));
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });

  return (
    <Container>
      <LogoBox>
        {!isMobile && <StyledLogo logoTheme={LogoTheme.ICWhite} />}
      </LogoBox>
      <LoginBox>
        <InnerBlackBox>
          <Title text={t('chic.hostess.global.title')} />
          <StyledButton 
            label={t('chic.crmApp.crmSignIn.button.label')}
            buttonTheme={ButtonTheme.ICPrimary}
            onClick={(): void => redirect(RoutingPath.CrmSignInRedirect)}
          />
        </InnerBlackBox>
      </LoginBox>
    </Container>
  );
};
