import { SubscriptionPlanSimple, SubscriptionSimplePlanProduct } from '@chic/models';

export const findPlanProductByKey: (key: number, plan: SubscriptionPlanSimple) => SubscriptionSimplePlanProduct | undefined = (
  key: number, plan: SubscriptionPlanSimple,
): SubscriptionSimplePlanProduct | undefined => {
  for (const group of plan.productsGroups) {
    for (const product of group.products) {
      if (product.id === key) {
        return product;
      }
    }
  }
};
