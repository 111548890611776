import { Breakpoint, Color, DetailsEditBox, PageTitle, StyledComponent, TransactionProductBox, hexToRgba } from '@chic-loyalty/ui';
import styled from 'styled-components';
import { ImageBoxProps } from './subscriptionSummary.types';

export const MainContainer: StyledComponent<'div'> = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 24px 62px;

  @media ${Breakpoint.Mobile} {
    padding: 0 0 30px;
  }
`;

export const ImageBox: StyledComponent<'div', ImageBoxProps> = styled.div<ImageBoxProps>`
  width: 100%;
  height: 229px;
  position: relative;
  background: url('${({ $image }: ImageBoxProps): string => $image}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media ${Breakpoint.Mobile} {
    height: 138px;
    width: 100%;
  }
`;

export const BadgesRow: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  left: 20px;
  top: -20px;

  @media ${Breakpoint.Mobile} {
    left: unset;
    top: unset;
    bottom: -12px;
    right: 20px;
  }
`;

export const Badge: StyledComponent<'div'> = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.ICWhite100};
  box-shadow: 0px 0px 24px 0px ${hexToRgba(Color.ICBlack500, 0.29)};
`;

export const BadgeImg: StyledComponent<'img'> = styled.img`
  height: 36px;
  width: auto;
`;

export const DetailsBoxes: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 20px;
  margin: 40px 0;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    padding: 0 24px;
  }
`;

export const DetailsBoxContainer: StyledComponent<'div'> = styled.div`
  padding: 15px 30px;
  min-width: 50%;
  width: 50%;
  height: 100%;
  background-color: ${Color.ICBlack100};
  clip-path: polygon(-12px 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, -12px 100%);

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  margin-bottom: 20px;

  @media ${Breakpoint.Mobile} {
    padding: 0 24px;
  }
`;

export const StyledTransactionProductBox: StyledComponent<typeof TransactionProductBox> = styled(TransactionProductBox)`
  padding: 24px 0;
  border-top: 1px solid ${Color.ICGray500};
  border-bottom: 1px solid ${Color.ICGray500};

  @media ${Breakpoint.Mobile} {
    margin: 0 24px;
  }
`;

export const StyledDetailsEditBox: StyledComponent<typeof DetailsEditBox> = styled(DetailsEditBox)``;

export const DetailsBoxesColumn: StyledComponent<'div'> = styled.div`
  width: calc(50% - 20px);

  ${StyledDetailsEditBox} + ${StyledDetailsEditBox} {
    margin-top: 20px;
  }

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;
