import { AxiosResponse } from 'axios';

import {
  ApiResolve,
  ApiReject,
  SubscriptionCreateRequestData,
  SubscriptionConfirmData,
  SubscriptionDetails,
  SubscriptionSimulationData,
  SubscriptionSimulation,
} from '@chic/models';
import { api } from '../api.service';

export const createSubscription = (data: SubscriptionCreateRequestData): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject): Promise<void> => (
    api.post('/hostess/subscription/create', data)
      .then((response: AxiosResponse<SubscriptionDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const confirmSubscription = (data: SubscriptionConfirmData): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject): Promise<void> => (
    api.post('/hostess/subscription/confirm', data)
      .then((response: AxiosResponse<SubscriptionDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const simulateSubscription = (data: SubscriptionSimulationData): Promise<SubscriptionSimulation> => new Promise(
  (resolve: ApiResolve<SubscriptionSimulation>, reject: ApiReject): Promise<void> => (
    api.post('/hostess/subscription/simulation', data)
      .then((response: AxiosResponse<SubscriptionSimulation>): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const cancelSubscription = (subscriptionId: number, customerId: number): Promise<SubscriptionDetails> => new Promise(
  (resolve: ApiResolve<SubscriptionDetails>, reject: ApiReject): Promise<void> => (
    api.post('/hostess/subscription/cancel', { subscriptionId, customerId })
      .then((response: AxiosResponse<SubscriptionDetails>): void => resolve(response?.data))
      .catch(reject)
  ),
);
