import React, { RefObject, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { BaseViewForOrders } from '@chic/components';
import { 
  ContentBox, 
  DetailsBox, 
  DetailsInfoGroupBox, 
  EmptyListCoverWrapper, 
  ImageBox, 
  MainContainer, 
  ProductsGrid, 
  ProductsGroupBox, 
  StyledFilters, 
  StyledInput, 
  StyledTransactionProductBox, 
  TransactionProductBoxes, 
} from './subscriptionProductsChoose.styled';
import { 
  ComponentColorTheme, 
  DetailsInfoGroup, 
  EmptyListCover, 
  PageTitle, 
  ProductBox, 
  ProductBoxSize, 
  SubscriptionSummaryBox, 
  useRedirect, 
  UseRedirect, 
  UseState, 
} from '@chic-loyalty/ui';
import { 
  SubscriptionChosenProduct, 
  SubscriptionPlanGroup, 
  SubscriptionSimplePlanProduct, 
} from '@chic/models';
import { FileFromViews, RoutingPath } from '@chic/enums';
import { UseSubscriptionProductsChoose } from './subscriptionProductsChoose.types';
import { useSubscriptionProductsChoose } from './subscriptionProductsChoose.hooks';

export const SubscriptionProductsChooseView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const {
    onProductsSave,
    selectedProducts,
    priceRanges,
    discounts,
    productsAmount,
    simulationData,
    setInputQuery,
    filters,
    setActiveFilter,
    activeFilter,
    filteredProducts,
    isProductAvailable,
    updateProductAmount,
    highestPlanPrice,
    detailsInfoSettings,
    planCurrentPrice,
  }: UseSubscriptionProductsChoose = useSubscriptionProductsChoose();
  const [stepNumber, setStepNumber]: UseState<number> = useState<number>(1);
  const productsGroupRef: RefObject<Record<string, HTMLDivElement | null>> = useRef({});
  const currency: string = 'zł';

  return (
    <BaseViewForOrders
      pageTitle={t(`chic.hostess.subscriptionProductsChooseView.${stepNumber === 1 ? 'chooseProducts' : 'chosenProducts'}.pageTitle`)}
      pageTitleAction={(): void => stepNumber === 1 ? redirect(RoutingPath.SubscriptionUserVerification) : setStepNumber(1)}
      acceptButtonSettings={{
        label: t('chic.hostess.subscriptionProductsChooseView.goToDeliveryButton'),
        onClick: onProductsSave,
        disabled: !selectedProducts.length,
      }}
      cancelButtonSettings={{
        label: t(`chic.hostess.subscriptionProductsChooseView.${stepNumber === 1 ? 'chosenProducts' : 'goBackToList'}`),
        onClick: (): void => setStepNumber(stepNumber === 1 ? 2 : 1),
      }}
      withContentScroll={stepNumber === 1}
    >
      {stepNumber === 1 && (
        <MainContainer>
          {!!priceRanges.length && (
            <SubscriptionSummaryBox 
              priceRanges={priceRanges} 
              discounts={discounts} 
              value={!Object.keys(productsAmount).length ? 0 : simulationData?.productsPrice.regular ?? 0} 
              finalValue={!Object.keys(productsAmount).length ? 0 : simulationData?.productsPrice.discounted ?? 0}        
            />
          )}
          <StyledInput 
            placeholder={t('chic.hostess.subscriptionProductsChooseView.input.placeholder')}
            onChange={setInputQuery}
            colorTheme={ComponentColorTheme.IC}
          />
          <StyledFilters 
            elements={filters} 
            onChange={setActiveFilter} 
            activeElementName={activeFilter}
          />
          {!!filteredProducts.length && filteredProducts
            .map((group: SubscriptionPlanGroup<SubscriptionSimplePlanProduct>): JSX.Element => (
              <ProductsGroupBox 
                key={group.id} 
                ref={(ref: HTMLDivElement): HTMLDivElement | false => !!productsGroupRef.current && (
                  productsGroupRef.current[group.id] = ref
                )}
              >
                <PageTitle label={group.name} />
                <ProductsGrid>
                  {group.products.map((product: SubscriptionSimplePlanProduct): JSX.Element => (
                    <ProductBox 
                      label={product.name}
                      price={`${product.price} ${currency}`}  
                      isAvailable={isProductAvailable(product)}
                      colorTheme={ComponentColorTheme.IC}
                      initialValue={productsAmount[product.id] ?? 0}   
                      image={product.photos.length ? product.photos[0] : undefined}   
                      size={ProductBoxSize.Big}
                      onChangeProductCount={(value: number): void => updateProductAmount(product.id, value)}
                      maxValue={
                        Math.floor(
                          (highestPlanPrice - planCurrentPrice) / product.price,
                        ) + (productsAmount[product.id] || 0)
                      }
                      key={product.id}
                    />
                  ))}
                </ProductsGrid>
              </ProductsGroupBox>
            ))}
        </MainContainer>
      )}
      {stepNumber === 2 && (
        <MainContainer>
          <DetailsBox>
            <ImageBox $image={FileFromViews.SubscriptionProductsChooseCover}>
              {/* TODO: fix when be will be ready */}
              {/* <BadgesRow>
                {[Glo, Velo].map((badge: string): JSX.Element => (
                  <Badge key={badge}><BadgeImg src={badge} /></Badge>
                ))}
              </BadgesRow> */}
            </ImageBox>
            <ContentBox>
              <DetailsInfoGroupBox>
                <DetailsInfoGroup 
                  items={detailsInfoSettings} 
                  colorTheme={ComponentColorTheme.IC} 
                />
              </DetailsInfoGroupBox>
            </ContentBox>
          </DetailsBox>
          <SubscriptionSummaryBox 
            priceRanges={priceRanges} 
            discounts={discounts} 
            value={!Object.keys(productsAmount).length ? 0 : simulationData?.productsPrice.regular ?? 0}
            finalValue={!Object.keys(productsAmount).length ? 0 : simulationData?.productsPrice.discounted ?? 0}
          />
          <TransactionProductBoxes>
            {selectedProducts.length ? selectedProducts.map((selectedProduct: SubscriptionChosenProduct): JSX.Element => (
              <StyledTransactionProductBox 
                name={selectedProduct.productDetails.name}
                amount={selectedProduct.amount}
                category={selectedProduct.productDetails.category}
                image={selectedProduct.productDetails.photos.length ? selectedProduct.productDetails.photos[0] : undefined}
                colorTheme={ComponentColorTheme.IC}       
                price={selectedProduct.productDetails.price}
                onChangeProductCount={(value: number): void => updateProductAmount(selectedProduct.id, value)}
                maxAmountValue={
                  Math.floor(
                    (highestPlanPrice - planCurrentPrice) / selectedProduct.productDetails.price,
                  ) + (productsAmount[selectedProduct.id] || 0)
                }
                key={selectedProduct.id}
              />
            )) : (
              <EmptyListCoverWrapper>
                <EmptyListCover 
                  header={t('chic.hostess.subscriptionProductsChooseView.emptyListCover.header')}
                  subheader={t('chic.hostess.subscriptionProductsChooseView.emptyListCover.subheader')}      
                />
              </EmptyListCoverWrapper>
            )}
          </TransactionProductBoxes>
        </MainContainer>
      )}
    </BaseViewForOrders>
  );
};
