import { Breakpoint, Color, Filters, Input, StyledComponent, TransactionProductBox, hexToRgba } from '@chic-loyalty/ui';
import styled from 'styled-components';
import { ImageBoxProps } from './subscriptionProductsChoose.types';

export const MainContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 24px 62px;

  @media ${Breakpoint.Mobile} {
    padding: 0 0 30px;
  }
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  margin: 20px 0;

  @media ${Breakpoint.Mobile} {
    margin: 20px 24px;
  }
`;

export const StyledFilters: StyledComponent<typeof Filters> = styled(Filters)`
  @media ${Breakpoint.Mobile} {
    margin: 0 24px;
  }
`;

export const ProductsGroupBox: StyledComponent<'div'> = styled.div`
  margin: 48px 0 0;

  @media ${Breakpoint.Mobile} {
    margin: 48px 24px 0;
  }
`;

export const ProductsGrid: StyledComponent<'div'> = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 302px));
  gap: 20px;
  margin-top: 24px;

  @media ${Breakpoint.Mobile} {
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 162px));
  }

  @media ${Breakpoint.SmallMobile} {
    gap: 10px;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
`;

export const StyledTransactionProductBox: StyledComponent<typeof TransactionProductBox> = styled(TransactionProductBox)`
  padding: 24px 0;
  border-bottom: 1px solid ${Color.ICGray500};
`;

export const TransactionProductBoxes: StyledComponent<'div'> = styled.div`
  margin-top: 24px;

  @media ${Breakpoint.Mobile} {
    margin: 24px 24px 0;
  }
`;

export const DetailsBox: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
  }
`;

export const ImageBox: StyledComponent<'div', ImageBoxProps> = styled.div<ImageBoxProps>`
  width: 50%;
  position: relative;
  background: url('${({ $image }: ImageBoxProps): string => $image}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media ${Breakpoint.Mobile} {
    height: 138px;
    width: 100%;
  }
`;

export const BadgesRow: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  left: 20px;
  top: -20px;

  @media ${Breakpoint.Mobile} {
    left: unset;
    top: unset;
    bottom: -12px;
    right: 20px;
  }
`;

export const Badge: StyledComponent<'div'> = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.ICWhite100};
  box-shadow: 0px 0px 24px 0px ${hexToRgba(Color.ICBlack500, 0.29)};
`;

export const BadgeImg: StyledComponent<'img'> = styled.img`
  height: 36px;
  width: auto;
`;

export const ContentBox: StyledComponent<'div'> = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${Breakpoint.Mobile} {
    width: 100%;
  }
`;

export const DetailsInfoGroupBox: StyledComponent<'div'> = styled.div`
  padding: 30px;
  background: linear-gradient(85.01deg, ${Color.ICBlack100} 0.65%, ${Color.ICBlack500} 110.02%);
`;

export const EmptyListCoverWrapper: StyledComponent<'div'> = styled.div`
  margin: 24px 0;
`;
