import { AppConfig, InjectedWindowValues } from '@chic/models';
import { ApplicationInstance, ConfigsDirName } from '@chic/enums';

declare const window: InjectedWindowValues;

export const appConfig: AppConfig = {
  api: process.env.REACT_APP_API_URL || '/api',
  externalAssets: process.env.REACT_APP_EXTERNAL_ASSETS || 'https://s3.docker.mug.pl/chic',
  applicationName: process.env.REACT_APP_APPLICATION_NAME || 'Panel Hostessy',
  configsDirName: process.env.REACT_APP_CONFIGS_DIR_NAME || ConfigsDirName.HostessPanel,
  applicationVersion: process.env.REACT_APP_VERSION
    ? process.env.REACT_APP_VERSION
    : (typeof window !== 'undefined')
      ? window.APP_VERSION
      : '',
  applicationInstance: process.env.REACT_APP_INSTANCE
    ? process.env.REACT_APP_INSTANCE as ApplicationInstance
    : (typeof window !== 'undefined')
      ? window.APP_INSTANCE as ApplicationInstance
      : ApplicationInstance.Unknown,
  logsSystemClientToken: process.env.REACT_APP_LOGS_CLIENT_TOKEN ?? '',
  configFilenames: ['menu', 'footer', 'disabledViews', 'defaultRoute'],
  overridedConfigFilenames: ['menu', 'footer', 'disabledViews', 'defaultRoute'],
};
