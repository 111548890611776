import { AxiosResponse } from 'axios';

import {
  ApiReject,
  ApiResolve,
  AgreementType,
  DeliveryTypeDetails,
  PickupPointsParams,
  PickupPointDetails,
  KeyValue,
  SubscriptionDictionary,
  SubscriptionPlanSimple,
  ConfigElement,
} from '@chic/models';

import { api } from '../api.service';
import { keyValuesToQueryParams } from '@chic/utils';
import { DeliveryPickupPointType } from '@chic/enums';

export const getAgreements: () => Promise<AgreementType[]> = (): Promise<AgreementType[]> => new Promise(
  (resolve: ApiResolve<AgreementType[]>, reject: ApiReject): Promise<void> => (
    api.get('/hostess/static/agreements')
      .then((response: AxiosResponse): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getDeliveries: () => Promise<DeliveryTypeDetails[]> = (): Promise<DeliveryTypeDetails[]> => new Promise(
  (resolve: ApiResolve<DeliveryTypeDetails[]>, reject: ApiReject): Promise<void> => (
    api.get('/hostess/static/deliveries')
      .then((response: AxiosResponse): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getPickupPoints = (params: Partial<PickupPointsParams>): Promise<PickupPointDetails[]> => {
  const queryParams: string = keyValuesToQueryParams(params as KeyValue);

  return new Promise(
    (resolve: ApiResolve<PickupPointDetails[]>, reject: ApiReject): Promise<void> => (
      api.get(`/hostess/static/pickup-points?${queryParams}`)
        .then((response: AxiosResponse): void => resolve(response?.data))
        .catch(reject)
    ),
  );
};

export const getPickupPointDetails = (type: DeliveryPickupPointType, id: string): Promise<PickupPointDetails> => {
  return new Promise(
    (resolve: ApiResolve<PickupPointDetails>, reject: ApiReject): Promise<void> => (
      api.get(`/hostess/static/pickup-points/${type}/${id}`)
        .then((response: AxiosResponse): void => resolve(response?.data))
        .catch(reject)
    ),
  );
};

export const getSubscriptionDictionary: () => Promise<SubscriptionDictionary> = (): Promise<SubscriptionDictionary> => new Promise(
  (resolve: ApiResolve<SubscriptionDictionary>, reject: ApiReject): Promise<void> => (
    api.get('/hostess/static/subscription')
      .then((response: AxiosResponse): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getSubscriptionPlans: () => Promise<SubscriptionPlanSimple[]> = (): Promise<SubscriptionPlanSimple[]> => new Promise(
  (resolve: ApiResolve<SubscriptionPlanSimple[]>, reject: ApiReject): Promise<void> => (
    api.get('/hostess/static/subscription-plan')
      .then((response: AxiosResponse): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getSubscriptionPlanDetails: (id: string) => Promise<SubscriptionPlanSimple> = (
  id: string,
): Promise<SubscriptionPlanSimple> => new Promise(
  (resolve: ApiResolve<SubscriptionPlanSimple>, reject: ApiReject): Promise<void> => (
    api.get(`/hostess/static/subscription-plan/${id}`)
      .then((response: AxiosResponse): void => resolve(response?.data))
      .catch(reject)
  ),
);

export const getConfig: () => Promise<ConfigElement[]> = (): Promise<ConfigElement[]> => new Promise(
  (resolve: ApiResolve<ConfigElement[]>, reject: ApiReject): Promise<void> => (
    api.get('/hostess/static/config')
      .then((response: AxiosResponse): void => resolve(response?.data))
      .catch(reject)
  ),
);
