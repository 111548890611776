import { DeliveryPickupPointType } from '@chic/enums';
import { PickupPointDetails, UseSubscriptionOrder } from '@chic/models';
import { TransProps, useTranslation } from 'react-i18next';

export const useSubscriptionOrder: () => UseSubscriptionOrder = (): UseSubscriptionOrder => {
  const { t }: TransProps<never> = useTranslation();

  const getPickupPointAddress: (point: PickupPointDetails) => string = (point: PickupPointDetails): string => {
    const isDeliveryWithExternalId: boolean = [
      DeliveryPickupPointType.InpostMachine, 
      DeliveryPickupPointType.DpdPickupPoint,
    ].includes(point.type);
    
    return `**${isDeliveryWithExternalId
      ? `${t('chic.hostess.subscriptionDeliveryView.getParcelLockerAddress.parcelMachine', { value: point.externalId })},`
      : point.name}**
      ${isDeliveryWithExternalId ? `${point.name},` : ''}
      ${point.location.address}, ${point.location.postalCode} ${point.location.city}
    `;
  };

  return { getPickupPointAddress };
};
