import React, { RefObject, useRef, useState } from 'react';
import {
  Image,
  FormWrapper,
  StyledInput,
  StyledButton,
  MiddleContent,
} from './subscriptionUserVerification.styled';
import {
  ButtonTheme,
  ComponentColorTheme,
  PageTitle,
  PageTitleSize,
  ValidationBar,
  UseState,
  Breakpoint,
  IconName,
  eanOrEmailRegexp,
  ValidationBarTheme,
  UseRedirect,
  useRedirect,
  UseNotifications,
  useNotifications,
} from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { ValidationBarData, VerificationFormData } from './subscriptionUserVerification.types';
import { useUserVerification } from './subscriptionUserVerification.hooks';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';
import { consumerLogin, getSubscriptionsList } from '@chic/api';
import { ApiError, ConsumerLoginData, ObjectNameWithValue, SubscriptionData, UseOrder } from '@chic/models';
import { VerificationFormFields } from './subscriptionUserVerification.enum';
import { BaseViewForOrders } from '@chic/components';
import { FileFromViews, PermissionName, RoutingPath, SubscriptionStatus } from '@chic/enums';
import { useOrder } from '@chic/hooks';

export const SubscriptionUserVerificationView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { showFullscreenAlert, hideFullscreenAlert }: UseNotifications = useNotifications();
  const { clearSessionStorage, saveOrderStateValues }: UseOrder = useOrder();
  const [validationBarData, setValidationBarData]: UseState<ValidationBarData | null> = useState<ValidationBarData | null>(null);
  const [isUserVerified, setIsUserVerified]: UseState<boolean> = useState<boolean>(false);
  const [isUserVerifiedButtonLoading, setIsUserVerifiedButtonLoading]: UseState<boolean> = useState<boolean>(false);
  const [verifiedUserData, setVerifiedUserData]: UseState<ConsumerLoginData | null> = useState<ConsumerLoginData | null>(null);
  const [isSmsCodeInputActive, setIsSmsCodeInputActive]: UseState<boolean> = useState<boolean>(false);
  const [isSmsCodeButtonLoading, setIsSmsCodeButtonLoading]: UseState<boolean> = useState<boolean>(false);
  const VerificationFormValidationSchema: Yup.Schema<VerificationFormData> = useUserVerification();
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const formikRef: RefObject<FormikProps<VerificationFormData>> = useRef(null);

  const handleVerificationError: (error: ApiError) => void = (error: ApiError): void => {
    setValidationBarData({
      barTheme: ValidationBarTheme.Red,
      message: error.message ?? '',
      icon: IconName.SmallInfo,
    });
    setIsUserVerified(false);
  };

  const verifyUser: (values: VerificationFormData) => void = (values: VerificationFormData): void => {
    setIsSmsCodeButtonLoading(true);
    consumerLogin(values)
      .then((data: ConsumerLoginData): void => {
        setValidationBarData({
          barTheme: ValidationBarTheme.GrayGradient,
          message: t('chic.hostess.subscriptionUserVerificationView.verifiedSuccessfully'),
          icon: IconName.SmallCheck,
        });
        setVerifiedUserData(data);
        getSubscriptionsList(data.id)
          .then((subscriptions: SubscriptionData[]): void => {
            const activeSubscription: SubscriptionData | undefined = subscriptions.find((plan: SubscriptionData): boolean => (
              plan.status !== SubscriptionStatus.Inactive
            ));

            if (activeSubscription) {
              showFullscreenAlert({
                title: t('chic.hostess.subscriptionUserVerificationView.activeSubscription.title'),
                description: t('chic.hostess.subscriptionUserVerificationView.activeSubscription.description'),
                acceptButtonSettings: {
                  label: t('chic.hostess.global.goBack'),
                  action: (): void => {
                    setValidationBarData(null);
                    setVerifiedUserData(null);
                    setIsSmsCodeInputActive(false);
                    formikRef.current?.resetForm();
                    hideFullscreenAlert();
                  },
                },
              });
            } else {
              setIsUserVerified(true);
            }
          });
      })
      .catch(handleVerificationError)
      .finally((): void => setIsSmsCodeButtonLoading(false));
  };

  const handleIdentifierFieldChange: (login: string) => void = (login: string): void => {
    if (eanOrEmailRegexp.test(login)) {
      setIsUserVerifiedButtonLoading(true);
      consumerLogin({ login })
        .then((): void => {
          setValidationBarData({
            barTheme: ValidationBarTheme.GrayGradient,
            message: t('chic.hostess.subscriptionUserVerificationView.smsCodeRequired'),
            icon: IconName.SmallInfo,
          });
          setIsSmsCodeInputActive(true);
        })
        .catch(handleVerificationError)
        .finally((): void => setIsUserVerifiedButtonLoading(false));
    }
  };

  const goToProductsChooseAction: () => void = (): void => {
    if (!verifiedUserData) {
      return;
    }

    const canPayByCreditCard: boolean = !!verifiedUserData.permissions
      .find((permission: ObjectNameWithValue): boolean => permission.name === PermissionName.CanPayByCreditCard)?.value;

    clearSessionStorage();
    saveOrderStateValues({
      customerId: verifiedUserData.id,
      email: verifiedUserData.email,
      phone: verifiedUserData.phone,
      canPayByCreditCard: canPayByCreditCard,
      customerCardEan: verifiedUserData.ean,
    });
    
    redirect(RoutingPath.SubscriptionProductsChoose);
  };

  return (
    <BaseViewForOrders
      pageTitle={t('chic.hostess.subscriptionUserVerificationView.pageTitle')}
      acceptButtonSettings={{
        label: t('chic.hostess.subscriptionUserVerificationView.orderInspirationPlan'),
        onClick: goToProductsChooseAction,
        disabled: !isUserVerified,
      }}
      cancelButtonSettings={{
        label: t('chic.crmApp.global.cancel'),
        onClick: (): void => redirect(RoutingPath.Dashboard),
      }}
    >
      <MiddleContent>
        <Image src={isMobile
          ? FileFromViews.SubscripitonUserVerificationCoverMobile
          : FileFromViews.SubscripitonUserVerificationCover}
        />
        <FormWrapper>
          <PageTitle label={t('chic.hostess.subscriptionUserVerificationView.formTitle')} size={PageTitleSize.Regular} />
          <Formik
            initialValues={{ login: '' }}
            onSubmit={verifyUser}
            validationSchema={VerificationFormValidationSchema}
            innerRef={formikRef}
          >
            {({
              errors,
              touched,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              values,
            }: FormikProps<VerificationFormData>): JSX.Element => (
              <Form onSubmit={handleSubmit}>
                <StyledInput
                  onChange={(value: string): void => setFieldValue(VerificationFormFields.Login, value)}
                  label={t('chic.hostess.subscriptionUserVerificationView.identifierInput.label')}
                  placeholder={t('chic.hostess.subscriptionUserVerificationView.identifierInput.placeholder')}
                  colorTheme={ComponentColorTheme.IC}
                  message={t('chic.hostess.subscriptionUserVerificationView.identifierInput.message')}
                  icon={!errors.login && touched.login ? IconName.Check : undefined}
                  onBlur={(): void => setFieldTouched(VerificationFormFields.Login, true)}
                  value={values.login}
                  disabled={isUserVerified}
                  required
                />
                {!!isSmsCodeInputActive && (
                  <StyledInput
                    onChange={(value: string): void => setFieldValue(VerificationFormFields.SmsCode, value)}
                    label={t('chic.hostess.subscriptionUserVerificationView.smsCodeInput.label')}
                    placeholder={t('chic.hostess.subscriptionUserVerificationView.smsCodeInput.placeholder')}
                    colorTheme={ComponentColorTheme.IC}
                    onBlur={(): void => setFieldTouched(VerificationFormFields.SmsCode, true)}
                    icon={!errors.smsCode && touched.smsCode ? IconName.Check : undefined}
                    value={values.smsCode}
                    disabled={isUserVerified}
                    required
                  />
                )}
                <StyledButton
                  label={isSmsCodeInputActive ? t('chic.hostess.subscriptionUserVerificationView.verify') : 'Zweryfikuj identyfikator'}
                  onClick={(): void => isSmsCodeInputActive ? handleSubmit() : handleIdentifierFieldChange(values.login)}
                  buttonTheme={ButtonTheme.ICPrimary}
                  fullWidth={isMobile}
                  isLoading={(isSmsCodeInputActive && isSmsCodeButtonLoading) || (isUserVerifiedButtonLoading)}
                  disabled={(
                    isSmsCodeInputActive 
                      ? !(!errors.smsCode && touched.smsCode) 
                      : !(!errors.login && touched.login)
                  ) || isUserVerified}
                />
                {validationBarData && (
                  <ValidationBar
                    barTheme={validationBarData.barTheme}
                    message={validationBarData.message}
                    colorTheme={ComponentColorTheme.IC}
                    icon={validationBarData.icon}
                  />
                )}
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </MiddleContent>
    </BaseViewForOrders>
  );
};
