import React from 'react';
import { ButtonsContainer, ChildrenContainer, Container, StyledPageTitle } from './baseViewForOrders.styled';
import { BaseViewForOrdersProps } from './baseViewForOrders.types';
import { Breakpoint, Button, ButtonTheme, PageTitleSize } from '@chic-loyalty/ui';
import { useMediaQuery } from 'react-responsive';

export const BaseViewForOrders: React.FC<BaseViewForOrdersProps> = (props: BaseViewForOrdersProps): JSX.Element => {
  const { 
    pageTitle, 
    acceptButtonSettings, 
    cancelButtonSettings, 
    pageTitleAction, 
    children,
    withContentScroll = false, 
    className, 
  }: BaseViewForOrdersProps = props;
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });

  return (
    <Container className={className}>
      <StyledPageTitle label={pageTitle} size={PageTitleSize.Big} onClick={pageTitleAction} />
      <ChildrenContainer $withContentScroll={withContentScroll}>{children}</ChildrenContainer>
      <ButtonsContainer>
        <Button {...acceptButtonSettings} buttonTheme={ButtonTheme.ICPrimary} fullWidth={isMobile} />
        <Button {...cancelButtonSettings} buttonTheme={ButtonTheme.ICSecondary} fullWidth={isMobile} />
      </ButtonsContainer>
    </Container>
  );
};
