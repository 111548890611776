/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';

import {
  ComponentColorTheme,
  ContractHeader,
  ElementsTable,
  ElementsTableRowData,
  Input,
  Loader,
  PageTitle,
  PageTitleSize,
  TextAlign,
  UseFormatDate,
  UseParsers,
  UseRedirect,
  UseState,
  useFormatDate,
  useParsers,
  useRedirect,
} from '@chic-loyalty/ui';
import { useOrder, useStatics, useTitle } from '@chic/hooks';
import { DictionaryObjectType, LoyaltySystemConstantType, QueryKey, RoutingPath, SubscriptionPlanPropertyType } from '@chic/enums';
import { TransProps, useTranslation } from 'react-i18next';
import {
  AttachmentsContainer,
  StyledDetailsInfoGroup,
  StyledPlansStagesTable,
  AlphabeticalList,
  Container,
  GrayBox,
  ListItem,
  LoadingContainer,
  MainContainer,
  OrderedList,
  ParagraphHeader,
  ParagraphsSection,
  SectionText,
  SectionTextItalic,
  SectionWithHeadline,
} from './subscriptionContract.styled';
import { SubscriptionDetails, SubscriptionSimulation, UseOrder, UseStatics } from '@chic/models';
import { getSubscriptionDetails, simulateSubscription } from '@chic/api';
import { useQuery } from 'react-query';
import { emptyRequest, getPlanPropertyValueByType } from '@chic/utils';
import { UseSubscriptionContract } from './subscriptionContract.types';
import { useSubscriptionContract } from './subscriptionContract.hooks';
import { BaseViewForOrders } from '@chic/components';

export const SubscriptionContractView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { parsePrice }: UseParsers = useParsers();
  const { formatDate }: UseFormatDate = useFormatDate();
  useTitle(t('chic.hostess.subscriptionContractView.title'));
  const { redirect }: UseRedirect = useRedirect();
  const { orderData }: UseOrder = useOrder();
  const { getConstantValueFromStatics, getValueFromDictionary }: UseStatics = useStatics();
  const currency: string = 'zł';
  const {
    acceptContractAction,
    getUserProductsTableData,
    handleCancelButtonClick,
    planStagesTableData,
    isAcceptContractLoading,
  }: UseSubscriptionContract = useSubscriptionContract(currency);
  const [smsCode, setSmsCode]: UseState<string> = useState<string>('');
  const [subscriptionDetails, setSubscriptionDetails]: UseState<SubscriptionDetails | null> = useState<SubscriptionDetails | null>(null);
  const attorneys: string[] = useMemo(
    (): string[] => [...getValueFromDictionary(DictionaryObjectType.Attorney)],
    [getValueFromDictionary],
  );
  const [userProductsTableData, setUserProductsTableData]: UseState<ElementsTableRowData[] | null> =
    useState<ElementsTableRowData[] | null>(null);
  const [subscriptionSimulation, setSubscriptionSimulation]: UseState<SubscriptionSimulation | null> =
    useState<SubscriptionSimulation | null>(null);
  const planDiscount: number = useMemo((): number => {
    return getPlanPropertyValueByType(subscriptionDetails?.plan.properties ?? [], SubscriptionPlanPropertyType.Discount) ?? 0;
  }, [subscriptionDetails]);
  const contractAddress: string = useMemo(
    (): string => getConstantValueFromStatics(LoyaltySystemConstantType.AmendmentDefaultLocation),
    [getConstantValueFromStatics],
  );

  useEffect((): void => {
    if (orderData && !orderData.subscriptionId) {
      redirect(RoutingPath.SubscriptionUserVerification);
      return;
    }
  }, [orderData]);

  useQuery(
    [QueryKey.SubscriptionDetails],
    (): Promise<SubscriptionDetails> => getSubscriptionDetails(orderData.customerId ?? 0, orderData.subscriptionId ?? 0),
    {
      enabled: !!orderData.subscriptionId && !!orderData.customerId,
      onSuccess: (response: SubscriptionDetails): void => {
        setSubscriptionDetails(response);
        setUserProductsTableData(getUserProductsTableData(response, currency));
      },
      onError: (): void => undefined,
    },
  );

  useQuery(
    [QueryKey.SubscriptionSimulation, subscriptionDetails],
    (): Promise<SubscriptionSimulation | void> => subscriptionDetails ? simulateSubscription({
      customerId: subscriptionDetails.customer.id,
      planId: subscriptionDetails.plan.id,
      subscriptionId: subscriptionDetails.id,
      deliveryType: subscriptionDetails.deliveryDestination.type.code,
      products: subscriptionDetails.products,
    }) : emptyRequest(),
    {
      enabled: !!subscriptionDetails && !subscriptionSimulation,
      onSuccess: setSubscriptionSimulation,
    },
  );

  const scrollToBottom = (): void => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  if (subscriptionDetails) {
    return (
      <BaseViewForOrders
        pageTitle={t('chic.hostess.subscriptionContractView.title')}
        acceptButtonSettings={{
          label: t('chic.hostess.subscriptionContractView.button.accept'),
          onClick: (): void => acceptContractAction({ subscriptionDetails, smsCode, contractAddress }),
          isLoading: isAcceptContractLoading,
          disabled: !smsCode.length,
        }}
        cancelButtonSettings={{
          label: t('chic.crmApp.global.cancel'),
          onClick: (): void => handleCancelButtonClick(subscriptionDetails.id, subscriptionDetails.customer.id),
        }}
      >
        <Container>
          <MainContainer>
            <ContractHeader
              header={t(
                'chic.hostess.subscriptionContractView.contractHeader.header',
                { contractCode: subscriptionDetails.contract.code ?? '', interpolation: { escapeValue: false } },
              )}
              buttonSettings={{
                label: t('chic.hostess.subscriptionContractView.contractHeader.buttonLabel'),
                onClick: scrollToBottom,
              }}
            />
            <SectionWithHeadline>
              <PageTitle size={PageTitleSize.Small} label='**Zawarta**' />
              <SectionText text={`dnia **${formatDate(new Date().getTime(), 'DD.MM.YYYY')}** roku w **${contractAddress}**`} />
            </SectionWithHeadline>
            <SectionWithHeadline>
              <PageTitle size={PageTitleSize.Small} label='**Pomiędzy**' />
              <SectionText text='
                **CHIC spółka z ograniczoną odpowiedzialnością** z siedzibą w Ostrzeszowie, Al. Wojska Polskiego 23c, 63-500 Ostrzeszów,
                zarejestrowaną w rejestrze przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto
                i Wilda w Poznaniu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000908623, posługująca się nadanymi
                jej numerami NIP: 9721147850 oraz REGON 300443550, BDO: 000004162, posiadającą status dużego przedsiębiorcy w rozumieniu
                Ustawy o przeciwdziałaniu nadmiernym opóźnieniom w transakcjach handlowych, o kapitale zakładowym w wysokości
                155.050,00 złotych,
            ' />
            </SectionWithHeadline>
            <SectionWithHeadline>
              <PageTitle size={PageTitleSize.Small} label='**Reprezentowaną przez:**' />
              {attorneys.map((attorney: string, index: number): JSX.Element => (
                <SectionText text={attorney} key={index} />
              ))}
              <SectionText text='dalej zwaną **„CHIC”**' />
            </SectionWithHeadline>
            <SectionWithHeadline>
              <PageTitle size={PageTitleSize.Small} label='**a**' />
              <SectionTextItalic text={`**${subscriptionDetails.customer.name} ${subscriptionDetails.customer.surname}**`} />
              <GrayBox>
                <SectionText text='Oświadczam, że jestem pełnoletnim konsumentem wyrobów tytoniowych, nikotynowych, powiązanych.' />
                <SectionText text='
                Oświadczam, że na mocy niniejszej Umowy, nabywam produkty tytoniowe, nikotynowe, powiązane osobiście i wyłącznie
                do własnej konsumpcji, zobowiązuję się nie przekazywać ich innym osobom, ani nie upoważniać do odbioru dostaw
                Produktów CHIC żadnej innej osoby
              ' />
                {!!orderData.customerCardEan && <SectionText text={`Nr karty członka Inspiration Club: **${orderData.customerCardEan}**`} />}
                {!!orderData.phone && <SectionText text={`Nr tel: **${orderData.phone}**`} />}
                <SectionText text={`E-mail: **${subscriptionDetails.customer.email}**`} />
                <SectionText text={`Adres właściwy dla realizacji Umowy: **${contractAddress}**`} />
              </GrayBox>
              <SectionText text='dalej zwanym **„Uczestnikiem”**,' />
              <SectionText text='zwanymi dalej łącznie także **„Stronami”**, a każda z osobna **„Stroną”**,' />
            </SectionWithHeadline>
            <SectionWithHeadline>
              <PageTitle size={PageTitleSize.Small} label='**DEFINICJE**' />
              <SectionText text='
                **1. CHIC** - CHIC Spółka z ograniczoną odpowiedzialnością z siedzibą w Ostrzeszowie, Al. Wojska Polskiego 23c, 63-500
                Ostrzeszów, zarejestrowana w rejestrze przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań
                - Nowe Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000908623, posługująca
                się nadanymi jej numerami NIP: 9721147850 oraz REGON 300443550, BDO: 000004162, posiadającą status dużego przedsiębiorcy
                w rozumieniu Ustawy o przeciwdziałaniu nadmiernym opóźnieniom w transakcjach handlowych, o kapitale zakładowym w wysokości
                155.050,00 złotych.
            '/>
              <SectionText text='
                **2. Uczestnik** - osoba fizyczna, która ukończyła 18 rok życia, posiada pełną zdolność do czynności prawnych oraz jest
                konsumentem wyrobów tytoniowych, nikotynowych lub powiązanych w tym m.in. papierosów elektronicznych, wyrobów tytoniowych
                neo&trade;, rekwizytów tytoniowych glo&trade;, wkładów nikotynowych veo&trade; lub saszetek nikotynowych oraz dokonała rejestracji w
                programie Inspiration Club, którego organizatorem jest CHIC.
              ' />
              <SectionText text='
                **3. Umowa** - niniejszy dokument określający m.in. prawa i obowiązki Stron w związku z udziałem w programie Inspiration Plan.
              ' />
              <SectionText text='
                **4. Zlecenie Wysyłki** - składane przez Uczestnika, na warunkach opisanych w Umowie, zlecenie wysyłki dotyczące konkretnego
                Pakietu Produktów CHIC.
              ' />
              <SectionText text='
                **5. Pakiet** - wybrany przez Uczestnika, przy zawarciu Umowy, wariant zestawu Produktów CHIC wraz z cenami jednostkowymi
                oraz łączną wartością (ceną) za cały Pakiet, wskazujący ilości i rodzaje Produktów CHIC, które Uczestnik będzie otrzymywać
                od CHIC na warunkach wskazanych w Umowie (z zastrzeżeniem § 10 Umowy), w ramach Zleceń Wysyłki, określony w Załączniku
                nr 1 do Umowy.
              ' />
              <SectionText text='
                **6. Produkty CHIC** - wyroby tytoniowe, w tym w szczególności nowatorskie wyroby tytoniowe, papierosy elektroniczne
                i pojemniki zapasowe, kartridże do papierosów elektronicznych, oraz wkłady tytoniowe i nikotynowe do rekwizytów tytoniowych,
                a także saszetki nikotynowe oraz wszelkie inne wyroby produkowane lub dystrybuowane przez grupę British American Tobacco na
                rynku polskim, dostępne w ramach Inspiration Planu dostępnego na stronie www.plan.inspirationclub.pl
              ' />
              <SectionText text='
                **7. Punkt Sprzedaży** - punkt sprzedaży detalicznej należący do CHIC, działający pod nazwą eSmoking World lub
                Inspiration Store, z wyłączeniem punktów franczyzowych. Lista Punktów Sprzedaży dostępna pod adresem:
                www.plan.inspirationclub.pl/znajdz-nas.
              ' />
              <SectionText text='
                **8. Program Inspiration Club** - program lojalnościowy, w ramach którego zarejestrowani członkowie mogą uzyskiwać nagrody
                za zakupy dokonywane w Punktach Sprzedaży, na warunkach określonych w regulaminie programu dostępnym na stronie internetowej
                Programu Inspiration Club, którego organizatorem jest CHIC.
              ' />
              <SectionText text='
                **9. Strona Programu Inspiration Club** - strona internetowa dostępna dla użytkowników Internetu pod adresem
                inspirationclub.pl.
              ' />
              <SectionText text='
                **10. Konto Użytkownika** - miejsce w systemie elektronicznym Programu Inspiration Club, dostępne za pośrednictwem Strony
                Programu Inspiration Club, w którym Uczestnik ma dostęp do wglądu i modyfikacji swoich danych osobowych, sprawdzenia danych
                dotyczących Zlecenia Wysyłki, oraz możliwość wypowiedzenia Umowy oraz innych funkcjonalności udostępnionych przez CHIC.
              ' />
              <SectionText text='**11. Autoryzacja Płatności** - zaksięgowanie środków na koncie operatora płatności.' />
              <SectionText text='**12. Dni Robocze** - dni od poniedziałku do piątku, z wyłączeniem dni ustawowo wolnych od pracy.' />
              <SectionText text='
                **13. Poziom Inspiration Plan** - obowiązujący na dzień zawarcia Umowy przedział cenowy, odpowiedni dla Pakietu wybranego
                przez Uczestnika, od którego to przedziału cenowego uzależniona jest wysokość przyznanego Uczestnikowi rabatu; Poziom ten,
                może różnić się w zależności od danego Pakietu; w ramach danego Poziomu Inspiration Planu Uczestnik może dokonywać dopasowania
                Zlecenia Wysiłki na zasadach określonych w Umowie.
              ' />
            </SectionWithHeadline>
            <ParagraphsSection>
              <ListItem>
                <ParagraphHeader>Przedmiot Umowy</ParagraphHeader>
                <OrderedList>
                  <ListItem>
                    Poprzez zawarcie niniejszej Umowy, Uczestnik akceptuje ofertę złożoną przez CHIC, stanowiącą Pakiet określony
                    w Załączniku nr 1 do niniejszej Umowy i decyduje się na zakup Produktów CHIC na warunkach wskazanych w Umowie.
                    Strony zgodnie potwierdzają, że niniejsza Umowa zawarta została w Punkcie Sprzedaży przy jednoczesnej fizycznej obecności
                    Stron, a Zlecenia Wysyłki Produktów CHIC realizowane na mocy niniejszej Umowy, nie stanowią odrębnych umów, ani odrębnych
                    oświadczeń woli Uczestnika o przyjęciu oferty, a wyłącznie realizację zobowiązań Stron wynikających z Umowy.
                  </ListItem>
                  <ListItem>
                    Przedmiotem niniejszej Umowy jest ustalenie warunków realizacji Zleceń Wysyłki dotyczących Produktów CHIC, zgodnie
                    z wybranym przez Uczestnika Pakietem oraz kolejnych Zleceń Wysyłki tożsamych z Pakietem lub zmienionych w ramach
                    dopasowania, o którym mowa w § 10 Umowy, ale wyłącznie w ramach danego Poziomu Inspiration Planu, tj. w ramach zakresu
                    cenowo – rabatowego odpowiedniego dla wybranego Pakietu. Zlecenia Wysyłki będą realizowane według cen jednostkowych
                    obowiązujących na dzień Zlecenia Wysyłki oraz według rabatów określonych dla danego Poziomu Inspiration Planu,
                    stanowiącego integralną część Umowy.
                  </ListItem>
                  {planStagesTableData && (
                    <ListItem>
                      CHIC ustala następujące Poziomy Inspiration Planu, obowiązujące na dzień zawarcia Umowy:
                      <StyledPlansStagesTable stages={planStagesTableData} />
                    </ListItem>
                  )}
                  <ListItem>
                    Wysokość rabatu uzależniona jest od Poziomu Inspiration Planu odpowiedniego dla wybranego przez Uczestnika Pakietu.
                    W przypadku zmiany Produktów CHIC w ramach dopasowania Zlecenia Wysyłki, zgodnie z § 10 Umowy, pierwotny rabat przypisany
                    do Pakietu nie ulega zmianie. Rabaty są stałe i obowiązują przez cały okres obowiązywania Umowy. Rabaty naliczane są
                    od cen jednostkowych obowiązujących na dzień Zlecenia Wysyłki.
                  </ListItem>
                  <ListItem>
                    Umowa zostaje zawarta na czas nieokreślony.
                  </ListItem>
                  <ListItem>
                    Umowa może zostać zawarta wyłącznie przy jednoczesnej fizycznej obecności Stron.
                  </ListItem>
                  <ListItem>
                    Strony zobowiązują się przestrzegać warunków określonych w Umowie.
                  </ListItem>
                  <ListItem>
                    Pojęcia pisane wielką literą mają znaczenie nadane im w Umowie, chyba że inaczej wynika z treści danego postanowienia.
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                <ParagraphHeader>Oświadczenia Stron</ParagraphHeader>
                <OrderedList>
                  <ListItem>
                    CHIC oświadcza, że jest dystrybutorem wyrobów tytoniowych, nikotynowych oraz powiązanych, w tym w szczególności
                    nowatorskich wyrobów tytoniowych, papierosów elektronicznych i pojemników zapasowych, kartridży do papierosów
                    elektronicznych, rekwizytów tytoniowych oraz wkładów tytoniowych oraz nikotynowych do rekwizytów tytoniowych, saszetek
                    nikotynowych a także wszelkich innych Produktów CHIC produkowanych lub dystrybuowanych przez Grupę British American
                    Tobacco na rynek polski.
                  </ListItem>
                  <ListItem>
                    Uczestnik oświadcza, że jest pełnoletnim konsumentem wyrobów wskazanych w punkcie 2.1. powyżej.
                  </ListItem>
                  <ListItem>
                    Uczestnik potwierdza, że na mocy niniejszej Umowy nabywa produkty tytoniowe, nikotynowe i powiązane osobiście i
                    wyłącznie do własnej konsumpcji, nie będzie przekazywał ich innym osobom, ani nie upoważni do odbioru dostaw Produktów
                    CHIC żadnej innej osoby.
                  </ListItem>
                  <ListItem>
                    Uczestnik posiada aktywny status członka Programu Inspiration Club, który jest warunkiem koniecznym do zawarcia i
                    realizacji Umowy. Uczestnik przyjmuje do wiadomości, że wystąpienie z Programu Inspiration Club jest równoznaczne z
                    zamknięciem Konta
                    Użytkownika w Programie Inspiration Club, co będzie skutkowało automatycznym rozwiązaniem Umowy. Szczegółowe warunki
                    zostały opisane w „§7 Rozwiązanie i wypowiedzenie Umowy” poniżej.
                  </ListItem>
                  <ListItem>
                    Zakup objętych Umową Produktów CHIC nie jest związany z działalnością gospodarczą lub zawodową Uczestnika.
                  </ListItem>
                  <ListItem>
                    Przedmiotowa Umowa jest umową nienazwaną, tym samym w kwestiach nieuregulowanych rozstrzygające są przepisy kodeksu
                    cywilnego, w szczególności dotyczące umowy zlecenia.
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                <ParagraphHeader>Obowiązki Uczestnika</ParagraphHeader>
                <OrderedList>
                  <ListItem>
                    Na podstawie Umowy Uczestnik zobowiązuje się składać Zlecenia Wysyłki zgodnie z 3.2. poniżej, na określone Produkty CHIC
                    wskazane w Pakiecie, po cenach jednostkowych obowiązujących w chwili Zlecenia Wysyłki, z uwzględnieniem rabatów
                    odpowiednich dla danego Poziomu Inspiration Planu zgodnie z wybranym Pakietem, dokonać płatności za każdą transzę
                    Produktów CHIC objętą Zleceniem Wysyłki oraz odbierać wszystkie transze Produktów CHIC objęte Zleceniami Wysyłki zgodnie
                    z pkt. 2.3. Umowy oraz §5 Umowy.
                  </ListItem>
                  <ListItem>
                    Uczestnik będzie składać Zlecenia Wysyłki na Produkty CHIC objęte wybranym przez niego Pakietem, za pośrednictwem swojego
                    Konta Użytkownika. Treść pierwszego Zlecenia Wysyłki będzie tożsama z treścią Pakietu określonego w Załączniku nr 1 do
                    Umowy i nie może obejmować jedynie niektórych Produktów CHIC oraz nie może zostać zmieniona poprzez dopasowanie na
                    zasadach określonych w § 10 Umowy.
                  </ListItem>
                  <ListItem>
                    Kolejne Zlecenia Wysyłki Produktów CHIC objętych Pakietem, będą generowane zgodnie z dyspozycją Uczestnika wyrażoną
                    poprzez platformę Inspiration Club. Uczestnik po 30 dniach od  realizacji Zlecenia Wysyłki Produktów CHIC otrzyma
                    wiadomość mailową informującą o możliwości złożenia kolejnego Zlecenia Wysyłki.
                  </ListItem>
                  <ListItem>
                    Uczestnik w ramach dopasowania Zlecenia Wysyłki Produktów CHIC, zgodnie z postanowieniami wskazanymi w § 10 Umowy, może
                    Zlecać Wysyłkę maksymalnie 3 (trzy) razy w ciągu każdego miesiąca obowiązywania Umowy, przy czym wszystkie pojedyncze
                    Zlecenia Wysyłki w danym miesiącu obowiązywania Umowy nie mogą przekroczyć wartości (łącznej ceny zamawianych
                    Produktów CHIC) określonej w ramach Poziomu Inspiration Planu odpowiedniego dla wybranego Pakietu. Uczestnik w trakcie
                    obowiązywania Umowy nie ma obowiązku dokonywać kolejnych Zleceń Wysyłki Produktów CHIC objętych Pakietem lub objętych
                    modyfikacją o której mowa w § 10 Umowy.
                  </ListItem>
                  <ListItem>
                    Uczestnik może dokonać zmiany adresu dostawy Pakietu Produktów CHIC przed dokonaniem płatności za zamówienie, przy czym
                    Uczestnik potwierdza, że zmiana adresu wynika z jego personalnych potrzeb i w związku z nią, dostępu do Produktów CHIC
                    dostarczanych w ramach Zlecenia Wysyłki nie uzyskają inne osoby, ani nie upoważni on do odbioru dostaw Produktów CHIC
                    żadnej innej osoby. Zmiana musi zostać wprowadzona za pośrednictwem Konta Użytkownika, poprzez wybór pola „Zmień adres
                    dostawy” w zakładce Inspiration Plan.
                  </ListItem>
                  <ListItem>
                    Uczestnik zobowiązuje się dokonać płatności za każdą transzę Produktów CHIC objętych Zleceniem Wysyłki, najpóźniej w dniu
                    złożenia Zlecenia Wysyłki. Jeśli Uczestnik przekroczy termin płatności wskazany powyżej, każdy dzień opóźnienia w
                    płatności będzie skutkować opóźnieniem wysyłki danej transzy Pakietu Produktów CHIC o jeden Dzień Roboczy, przy czym CHIC
                    rozpoczyna realizację Zlecenia Wysyłki zgodnie z punktem 4.1. Umowy.
                  </ListItem>
                  <ListItem>
                    Warunki sprzedaży Produktów CHIC objętych Umową, w szczególności ceny Produktów CHIC objętych Umową, obowiązują do momentu
                    wprowadzenia zmian w cenniku CHIC. CHIC informuje o zmianach dotyczących Produktów CHIC w drodze korespondencji e-mail
                    na 14 dni przed ich wprowadzeniem.
                  </ListItem>
                  <ListItem>
                    W związku z przedmiotową zmianą cen, Uczestnik otrzymuje wiadomość e-mail na podany przez siebie adres e-mail o zmianie
                    cen oraz o możliwości zawarcia aneksu do Umowy w formie dokumentowej, tj. zdalnie za pośrednictwem Konta Użytkownika.
                    Powyższe nie dotyczy zmiany cennika CHIC, o którym mowa w punkcie 3.9. Umowy, poniżej. W przypadku braku zawarcia aneksu,
                    następuje rozwiązanie Umowy przez CHIC z upływem okresu wypowiedzenia, o którym mowa pkt 7.2. Umowy, na warunkach
                    określonych w Umowie.
                  </ListItem>
                  <ListItem>
                    W przypadku, gdy zmiana cennika CHIC spowoduje wzrost cen Produktów CHIC wchodzących w zakres Pakietu wybranego przez
                    Uczestnika przy zawarciu Umowy lub wchodzących w zakres dopasowanego Zlecenia Wysyłki na podstawie § 10 Umowy, w takim
                    stopniu, iż jego realizacja spowodowałaby zmianę ustalonego w Umowie Poziomu Inspiration Planu, wówczas realizacja danego
                    Zlecenia Wysyłki nie jest możliwa i wymaga to podpisania nowej umowy w Punkcie Sprzedaży.
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                <ParagraphHeader>Obowiązki CHIC</ParagraphHeader>
                <OrderedList>
                  <ListItem>
                    CHIC rozpoczyna realizację Zlecenia Wysyłki po Autoryzacji Płatności będącej zapłatą za daną transzę Produktów CHIC,
                    tego samego dnia w którym dokonano Autoryzacji Płatności, jeżeli nastąpiła ona do godz. 14:00, lub następnego dnia,
                    jeżeli Autoryzacja Płatności nastąpiła po godz. 14:00.
                  </ListItem>
                  <ListItem>
                    Po Autoryzacji Płatności będącej zapłatą za daną transzę Produktów CHIC przez Uczestnika, CHIC zobowiązuje się do
                    przygotowania Produktów CHIC i do wysyłki ich na wskazany w Zleceniu Wysyłki adres w ciągu 5 dni roboczych.
                  </ListItem>
                  <ListItem>
                    CHIC zobowiązany jest dostarczyć daną transzę Produktów CHIC, na adres wskazany w Zleceniu Wysyłki przez Uczestnika
                    zgodnie z §5 Umowy. Koszty wysyłki każdej transzy Produktów CHIC również zostały opisane w §5 Umowy.
                  </ListItem>
                  <ListItem>
                    Strony zgodnie postanawiają, że na potrzeby Umowy, chwilą wydania Produktów CHIC Uczestnikowi, jest moment przekazania
                    danej transzy Produktów CHIC przedsiębiorstwu kurierskiemu wybranemu przez Uczestnika.
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                <ParagraphHeader>Warunki Dostawy</ParagraphHeader>
                <OrderedList>
                  <ListItem>
                    Dostawy Produktów CHIC objętych Zleceniem Wysyłki realizowane są wyłącznie za pośrednictwem przedsiębiorstw kurierskich,
                    po zapoznaniu się z kosztami dostawy. Dostawa Produktów CHIC do Paczkomatu InPost o wartości powyżej 150 zł jest dla
                    Uczestnika darmowa, poniżej tej wartości wynosi 9,99 zł.
                  </ListItem>
                  <ListItem>
                    W przypadku wyboru dostawy przesyłką kurierską koszt takiej usługi jest dodatkowo płatny i wynosi 15 zł.
                  </ListItem>
                  <ListItem>
                    CHIC przekaże każdą transzę Produktów CHIC do wysyłki przedsiębiorstwu kurierskiemu w terminach określonych
                    w pkt. 4.2. Umowy.
                  </ListItem>
                  <ListItem>
                    Uczestnik potwierdza, że będzie odbierał wszystkie transze Produktów CHIC osobiście i nie będzie przekazywał ich
                    innym osobom, ani nie upoważni do ich odbioru żadnej innej osoby.
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                <ParagraphHeader>Zmiana warunków Umowy</ParagraphHeader>
                <OrderedList>
                  <ListItem>
                    W ramach Umowy Uczestnik uprawniony jest do składania Zleceń Wysyłki, wyłącznie na warunkach określonych jego Pakietem,
                    z uwzględnieniem § 10 Umowy.
                  </ListItem>
                  <ListItem>
                    Strony zgodnie postanawiają, że w razie woli zmiany Pakietu przez Uczestnika, Uczestnik w dowolnym momencie trwania Umowy
                    może udać się do Punktu Sprzedaży, złożyć tam ustne oświadczenie o wypowiedzeniu Umowy na dotychczasowych warunkach,
                    zgodnie z punktem 7.1. Umowy i zawrzeć nową umowę na uczestnictwo w programie Inspiration Plan, wybierając inny zestaw
                    Produktów CHIC, stanowiący nowy Pakiet.
                  </ListItem>
                  <ListItem>
                    Przedmiotowa Umowa może ulec zmianie w sposób opisany w punkcie 6.2. również w przypadku wskazanym punkcie 3.3. Umowy.
                  </ListItem>
                  <ListItem>
                    Zmiana adresu dostawy Produktów CHIC objętych Zleceniem Wysyłki jest możliwa do dokonania zdalnie, poprzez Konto
                    Użytkownika w zakładce „Inspiration Plan”, wybór pola „Zmień Adres dostawy” i podanie zaktualizowanych danych.
                  </ListItem>
                  <ListItem>
                    Wszelkie zmiany danych osobowych wskazanych przez Uczestnika w niniejszej Umowie, dokonane zdalnie przez Uczestnika
                    poprzez Konto Użytkownika Programu Inspiration Club lub za pośrednictwem bezpośredniego kontaktu z CHIC, są jednoznaczne
                    ze zmianą danych osobowych dotyczących realizacji postanowień niniejszej Umowy.
                  </ListItem>
                  <ListItem>
                    Zmiana Umowy, w związku ze zmianą cennika CHIC została określona w punkcie 3.8. Umowy.
                  </ListItem>
                  <ListItem>
                    CHIC nie wyraża zgody na cesję praw i wierzytelności Uczestnika na inną osobę fizyczną.
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                <ParagraphHeader>Rozwiązanie i wypowiedzenie Umowy</ParagraphHeader>
                <OrderedList>
                  <ListItem>
                    Umowa zawarta zostaje na czas nieokreślony. Każda ze Stron ma prawo wypowiedzieć Umowę w dowolnym momencie jej trwania,
                    na warunkach wskazanych poniżej.
                  </ListItem>
                  <ListItem>
                    Uczestnik ma prawo wypowiedzieć Umowę w dowolnym momencie jej trwania, ze skutkiem natychmiastowym, z zastrzeżeniem
                    pkt. 7.4. Umowy. Podstawą wypowiedzenia musi być ważny powód, który motywuje przesłankę zawartą w art. 746 k.c.
                    w szczególności mogą to być powody zdrowotne, finansowe lub wynikające z rażącego opóźnienia w realizacji zamówienia
                    przez CHIC. Wypowiedzenie musi zostać złożone przez Uczestnika przy wykorzystaniu Konta Użytkownika, w zakładce
                    Inspiration Plan, poprzez wybranie opcji „Dezaktywuj Inspiration Plan”, które równoznaczne będzie ze złożeniem CHIC
                    przez Uczestnika oświadczenia woli o wypowiedzeniu Umowy lub poprzez ustne złożenie oświadczenia woli pracownikowi
                    w Punkcie Sprzedaży uprawnionemu do przyjęcia takich oświadczeń, po potwierdzeniu tożsamości Uczestnika.
                  </ListItem>
                  <ListItem>
                    CHIC ma prawo wypowiedzieć Umowę w dowolnym momencie jej trwania, z zachowaniem 14 dniowego okresu wypowiedzenia,
                    na skutek zaistnienia jednej z podanych poniżej ważnych przyczyn:
                    <AlphabeticalList>
                      <ListItem>
                        zmiany przepisów prawa lub wydania przez uprawniony organ lub sąd orzeczenia, wymagającego rozwiązania Umowy
                        lub wprowadzenia do niej zmian;
                      </ListItem>
                      <ListItem>
                        konieczności zapewnienia bezpiecznej realizacji postanowień Umowy i zapobiegania nadużyciom;
                      </ListItem>
                      <ListItem>
                        konieczności ochrony uzasadnionych interesów Uczestnika lub CHIC;
                      </ListItem>
                      <ListItem>
                        potrzeby dostosowania warunków Umowy, w szczególności Pakietu, do aktualnej oferty produktowej w Punktach Sprzedaży
                        CHIC;
                      </ListItem>
                      <ListItem>
                        z innego ważnego powodu, w szczególności w przypadku zmiany cen lub zmiany Produktów CHIC objętych daną Umową
                        oraz zaistnienia okoliczności wskazanych w punkcie 3.7. oraz 3.8. Umowy, przy czym CHIC zobowiązany
                        jest poinformować drugą stronę o zmianie z 14 dniowym wyprzedzeniem, dając jej tym samym czas na podjęcie
                        decyzji dotyczącej dalszej współpracy.
                      </ListItem>
                    </AlphabeticalList>
                  </ListItem>
                  <ListItem>
                    CHIC musi złożyć wypowiedzenie w formie dokumentowej poprzez przesłanie Uczestnikowi oświadczenia o wypowiedzeniu
                    przy wykorzystaniu poczty elektronicznej na adres poczty e-mail Uczestnika wskazany w Umowie. Informacja o wypowiedzeniu
                    Umowy przez CHIC zostanie również umieszczona na Koncie Użytkownika w zakładce Inspiration Plan.
                  </ListItem>
                  <ListItem>
                    Jeśli wypowiedzenie złożone skutecznie drugiej Stronie ma skutek na dzień przypadający w trakcie realizacji Zlecenia
                    Wysyłki, tj. po założeniu Zlecenia Wysyłki przez Uczestnika i po Autoryzacji Płatności będącej zapłatą za daną transzę
                    Produktów CHIC, ale przed wydaniem Produktów CHIC objętych Zleceniem Wysyłki Uczestnikowi tj. przed przekazaniem danej
                    transzy Produktów CHIC przewoźnikowi, Strony potwierdzają, że tak złożone wypowiedzenie będzie miało skutek po
                    zakończeniu realizacji Zlecenia Wysyłki.
                  </ListItem>
                  <ListItem>
                    Jeśli wypowiedzenie złożone skutecznie drugiej Stronie ma skutek na dzień przypadający po złożeniu Zlecenia Wysyłki
                    przez Uczestnika lecz przed Autoryzacją Płatności będącej zapłatą za daną transzę Produktów CHIC, wypowiedzenie
                    spowoduje anulowanie złożonego, lecz nieopłaconego Zlecenia Wysyłki danej transzy Produktów CHIC.
                  </ListItem>
                  <ListItem>
                    Warunki dostawy Produktów CHIC, obowiązują do chwili wskazanej w punkcie 3.7. lub 3.8. przedmiotowej Umowy.
                  </ListItem>
                  <ListItem>
                    Jeżeli w trakcie trwania Umowy Uczestnik przestanie być członkiem Programu Inspiration Club, ze względu na brak możliwości
                    realizacji świadczeń na warunkach wskazanych w Umowie, Umowa wygasa z dniem wypisania się z Programu Inspiration Club
                    tj. z dniem zamknięcia Konta Użytkownika, przy czym Zlecenia Wysyłki będące w trakcie realizacji, lecz jeszcze nieopłacone
                    ulegną anulowaniu, a Zlecenia Wysyłki opłacone zostaną zrealizowane na dotychczasowych warunkach.
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                <ParagraphHeader>Reklamacje</ParagraphHeader>
                <OrderedList>
                  <ListItem>
                    CHIC ma obowiązek dostarczyć Uczestnikowi Produkty CHIC objęte Zleceniem Wysyłki, wolne od wad fizycznych i prawnych.
                  </ListItem>
                  <ListItem>
                    Jeżeli Produkty CHIC objęte Zleceniem Wysyłki mają wady, które tkwiły w nich w chwili otrzymania przez Uczestnika danej
                    transzy Produktów CHIC lub Produkty CHIC zostały uszkodzone podczas dostawy, a także jeśli Zlecenie Wysyłki zostało
                    zrealizowane przez CHIC niezgodnie z jego warunkami, w szczególności dostarczone zostały Produkty CHIC inne niż objęte
                    Zleceniem Wysyłki, lub dostarczona transza Produktów CHIC była niekompletna, Uczestnik jest uprawniony do złożenia
                    reklamacji. Reklamacje związane ze Zleceniem Wysyłki mogą być zgłaszane w Punkcie Sprzedaży obsługującym ramowe umowy
                    sprzedaży lub poprzez formularz kontaktowy dostępny na stronie https://www.esmokingworld.com/pl/kontakt
                  </ListItem>
                  <ListItem>
                    Reklamacja dotycząca wad lub uszkodzeń Produktów CHIC może być złożona przez Uczestnika w dowolnym Punkcie Sprzedaży,
                    których aktualna lista znajduje się na stronie www.esmokingworld.com.
                    Wzór formularza reklamacji dostępny jest pod adresem
                    https://s.inspirationclub.pl/regulations/inspiration_club/formularz_reklamacji_z_tytulu_gwarancji_rekojmia_ic.pdf.
                    Reklamacja dotycząca wad lub uszkodzeń Produktów CHIC może być składana także pisemnie na adres: CHIC Spółka z ograniczoną
                    odpowiedzialnością z siedzibą w Ostrzeszowie, Al. Wojska Polskiego 23c, 63-500 Ostrzeszów
                  </ListItem>
                  <ListItem>
                    Reklamacja związana ze Zleceniem Wysyłki powinna zawierać imię i nazwisko Uczestnika, jego Numer Karty Członka
                    Inspiration Club oraz jego adres, na który zostanie przesłana odpowiedź na reklamację w przypadku reklamacji w formie
                    pisemnej, jak również dokładny opis i wskazanie przyczyny reklamacji oraz oryginał albo czytelną kopię dowodu zakupu.
                  </ListItem>
                  <ListItem>
                    CHIC zapewnia, że w razie potrzeby zwróci się do Uczestnika z prośbą o uzupełnienie reklamacji, jeśli zajdzie taka
                    potrzeba. Ewentualne dodatkowe zapytania lub prośby ze strony CHIC będą miały na celu wyłącznie dobro Uczestnika
                    jak i najlepszy oraz najszybszy sposób załatwienia zgłoszonej przez Uczestnika reklamacji i nie mają wpływu na termin
                    udzielania odpowiedzi na złożoną reklamację.
                  </ListItem>
                  <ListItem>
                    Reklamacje związane ze Zleceniem Wysyłki rozpatrywane będą w terminie 14 dni od dnia doręczenia. Uczestnik składający
                    reklamację związaną ze Zleceniem Wysyłki zostanie powiadomiony o jej rozpatrzeniu listem poleconym na adres podany
                    w reklamacji, a w przypadku reklamacji składanych w formie wiadomości elektronicznej - w formie wiadomości e-mail.
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                <ParagraphHeader>Dane osobowe</ParagraphHeader>
                <OrderedList>
                  <ListItem>
                    Administratorem danych osobowych przetwarzanych w związku z zawarciem niniejszej Umowy jest CHIC Spółka z ograniczoną
                    odpowiedzialnością z siedzibą w Ostrzeszowie, Al. Wojska Polskiego 23c, 63-500 Ostrzeszów, zarejestrowaną w rejestrze
                    przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań - Nowe Miasto i Wilda w Poznaniu,
                    IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000908623, posługująca się nadanymi jej numerami
                    NIP: 9721147850 oraz REGON 300443550.
                  </ListItem>
                  <ListItem>
                    CHIC wyznaczył Inspektora Ochrony Danych, z którym Uczestnik może skontaktować się listownie pod adresem wskazanym w
                    pkt 9.1. powyżej oraz w formie mailowej na adres: iod@esmokingworld.com.
                  </ListItem>
                  <ListItem>
                    Dane osobowe Uczestnika będą przetwarzane w celu:
                    <AlphabeticalList>
                      <ListItem>
                        zawarcia i realizacji niniejszej Umowy. Podstawą prawną przetwarzania danych osobowych w celu wykonania Umowy jest
                        art. 6 ust. 1 lit b) RODO,
                      </ListItem>
                      <ListItem>
                        realizacji prawnie uzasadnionych interesów CHIC poprzez zapewnienie sprawnego zarządzania kwestiami dotyczącymi
                        realizacji Umowy, między innymi poprzez zaadaptowanie wszelkich zmian danych osobowych dokonanych zdalnie przez
                        Uczestnika na jego koncie w Programie Inspiration Club dla potrzeb prawidłowej realizacji postanowień Umowy,
                        a także realizacji ustawowego oraz umownego obowiązku w ramach gwarancji lub rękojmi. Podstawą prawną przetwarzania
                        danych osobowych w celu realizacji prawnie uzasadnionych interesów CHIC jest art. 6 ust. 1 lit f) RODO.
                      </ListItem>
                    </AlphabeticalList>
                  </ListItem>
                  <ListItem>
                    Odbiorcami danych mogą być:
                    <AlphabeticalList>
                      <ListItem>
                        inne spółki z grupy kapitałowej CHIC Group m.in. w celach zarządzania systemami informatycznymi, świadczenia usług
                        księgowych na podstawie umowy między spółkami z grupy, dotyczącej przekazywania danych osobowych zawartej zgodnie
                        ze standardowymi klauzulami ochrony danych przyjętymi przez Komisję Europejską.
                      </ListItem>
                      <ListItem>
                        franczyzobiorcy sieci sprzedaży CHIC
                      </ListItem>
                      <ListItem>
                        Podmioty przetwarzające dane osobowe w imieniu CHIC, którym zostaną zlecone usługi związane z przetwarzaniem danych
                        osobowych, takie jak firmy kurierskie bądź firmy obsługujące płatności elektroniczne. Podmioty te przetwarzają dane
                        na podstawie zawartej z CHIC umowy oraz zgodnie z naszymi poleceniami.
                      </ListItem>
                    </AlphabeticalList>
                  </ListItem>
                  <ListItem>
                    W celach związanych z realizacją Umowy CHIC będzie przekazywać dane osobowe poza Europejski Obszar Gospodarczy, jednakże
                    zawsze na podstawie umowy zawartej zgodnie ze standardowymi klauzulami ochrony danych przyjętymi przez Komisję Europejską.
                  </ListItem>
                  <ListItem>
                    Dane osobowe będą przechowywane do upływu okresu przedawnienia ewentualnych roszczeń.
                  </ListItem>
                  <ListItem>
                    Uczestnikowi przysługują następujące prawa związane z przetwarzaniem danych osobowych:
                    <AlphabeticalList>
                      <ListItem>
                        prawo dostępu do danych osobowych, czyli prawo do uzyskania potwierdzenia, czy przetwarzane są Państwa dane osobowe
                        oraz uzyskania informacji o celach przetwarzania, kategoriach danych osobowych, a także uzyskania innych informacji
                        zgodnie z art. 15 RODO, w tym także do uzyskania kopii danych.
                      </ListItem>
                      <ListItem>
                        prawo żądania sprostowania danych osobowych,
                      </ListItem>
                      <ListItem>
                        prawo żądania usunięcia danych osobowych,
                      </ListItem>
                      <ListItem>
                        prawo żądania ograniczenia przetwarzania Twoich danych osobowych, w przypadkach wskazanych w art. 18 RODO,
                      </ListItem>
                      <ListItem>
                        prawo do przenoszenia danych osobowych, tj. prawo otrzymania od CHIC danych osobowych, w ustrukturyzowanym,
                        powszechnie używanym formacie informatycznym nadającym się do odczytu maszynowego oraz żądania od CHIC przesłania
                        danych do innego administratora. Jednakże tylko jeśli takie przesłanie jest technicznie możliwe oraz tylko w stosunku
                        do danych, które są przetwarzane na podstawie umowy.
                      </ListItem>
                    </AlphabeticalList>
                    Aby skorzystać z powyższych praw, należy skontaktować się z nami lub z naszym Inspektorem ochrony danych (dane kontaktowe
                    w punktach 9.1. i 9.2. powyżej).
                  </ListItem>
                  <ListItem>
                    Uczestnikowi przysługuje także prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych,
                    tj. Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2 00-193 Warszawa tel. 22 531 03 00.
                  </ListItem>
                  <ListItem>
                    Dane osobowe Uczestnika nie będą podlegały profilowaniu.
                  </ListItem>
                  <ListItem>
                    Podanie danych osobowych jest niezbędne do zawarcia niniejszej Umowy i jej prawidłowej realizacji.
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                <ParagraphHeader>Dopasowanie zamówienia</ParagraphHeader>
                <OrderedList>
                  <ListItem>
                    Uczestnik przez cały czas obowiązywania Umowy, ma możliwość modyfikacji Zlecenia Wysyłki w zakresie Produktów CHIC
                    dostępnych w ramach portfolio produktowego CHIC dostępnego w programie Inspiration Plan. Przez modyfikację Zlecenia
                    Wysyłki Produktów CHIC Strony rozumieją zmiany dokonywane w ramach tego samego Poziomu Inspiration Planu, tj. przy
                    zachowaniu Poziomu Inspiration Planu właściwego dla pierwotnie wybranego Pakietu. Dopasowanie może polegać na Zleceniu
                    Wysyłki części (niektórych) Produktów CHIC objętych Pakietem lub na Zleceniu Wysyłki Produktów CHIC innych, niż wskazane
                    w Pakiecie, które są dostępne w programie Inspiration Plan.
                  </ListItem>
                  <ListItem>
                    Przedmiotowe dopasowanie może odbywać się każdorazowo przed opłaceniem kolejnego Zlecenia Wysyłki.
                  </ListItem>
                  <ListItem>
                    Uczestnik może dokonywać dopasowania Zlecenia Wysyłki Produktów CHIC za pośrednictwem Konta Użytkownika w ramach Programu
                    Inspiration Club w dowolnym czasie, zgodnie z warunkami dotyczącymi obowiązywania Umowy.
                  </ListItem>
                  <ListItem>
                    Dopasowanie Zlecenia Wysyłki nie jest zmianą Umowy. Uczestnik zachowuje ustalony w Pakiecie Poziom Inspiration Planu
                    oraz przyznany rabat, a także inne warunki ustalone w Umowie.
                  </ListItem>
                  <ListItem>
                    Dopasowania można dokonywać wyłącznie w ramach Poziomu Inspiration Planu (zakresu cenowo – rabatowego) właściwego dla
                    wybranego Pakietu.  Powyższe oznacza, iż Uczestnik nie ma możliwości zmiany Pakietu na inny, lub dokonywania modyfikacji
                    w obrębie Produktów CHIC objętych Zleceniem Wysyłki, tak by ich łączna wartość w danym miesiącu przekroczyła zadeklarowany
                    Poziom Inspiration Planu. Maksymalna ilość zamówień w ramach dopasowania Zlecenia Wysyłki Produktów CHIC, dostępna dla
                    każdego Uczestnika, została określona w punkcie 3.4. Umowy.
                  </ListItem>
                  <ListItem>
                    Dopasowanie Zlecenia Wysyłki następuje po prawidłowo dokonanej zmianie w programie Inspiration Plan i prawidłowym
                    opłaceniu Zlecenia Wysyłki. Od tego czasu będzie to domyślne Zlecenie Wysyłki Uczestnika w zakresie Produktów CHIC,
                    chyba, że przy kolejnym Zleceniu Wysyłki Uczestnik dokona następnego dopasowania, zgodnie z postanowieniami wskazanymi
                    w § 10 Umowy. Tym samym w celu dokonaniu kolejnych zmian w zakresie określonym powyżej, Uczestnik musi dokonać
                    kolejnego dopasowania.
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                <ParagraphHeader>Odstąpienie od Umowy</ParagraphHeader>
                <OrderedList>
                  <ListItem>
                    W przypadku zawarcia Umowy poza lokalem przedsiębiorstwa (za pośrednictwem hostessy lub doradcy) konsument ma prawo
                    odstąpić od Umowy w ciągu 14 dniu bez podawania jakichkolwiek powodów.
                  </ListItem>
                  <ListItem>
                    Aby odstąpić od Umowy należy poinformować o tym CHIC z zachowaniem terminu 14 dni od dnia dokonania transakcji.
                  </ListItem>
                  <ListItem>
                    Produkt CHIC należy odesłać do CHIC najpóźniej 14 dni od dnia poinformowania o odstąpieniu.
                  </ListItem>
                  <ListItem>
                    Uczestnik ponosi jedynie bezpośrednie koszty zwrotu Produktu CHIC.
                  </ListItem>
                  <ListItem>
                    CHIC w sposób niezwłoczny, nie później niż 14 dni od otrzymania oświadczenia o odstąpieniu od Umowy zwróci Uczestnikowi
                    wszystkie dokonane przez niego płatności, w tym koszt dostarczenia rzeczy. CHIC może wstrzymać się z dokonaniem wypłaty
                    do momentu otrzymania rzeczy z powrotem.
                  </ListItem>
                  <ListItem>
                    Jeżeli na wyraźne żądanie Uczestnika (konsumenta) wykonanie usługi zaczyna się niezwłocznie, wówczas w przypadku
                    odstąpienia od Umowy Uczestnik ma obowiązek zapłaty za świadczenia spełnione do chwili odstąpienia od Umowy.
                  </ListItem>
                  <ListItem>
                    Uczestnik odpowiada wyłącznie za wszelkie zmniejszenie wartości Produktów CHIC wynikające z obchodzenia się Produktami
                    CHIC w sposób inny niż konieczny do stwierdzenia charakteru, cech i funkcjonowania Produktów CHIC.
                  </ListItem>
                  <ListItem>
                    W przypadku zakupu saszetek nikotynowych Velo, po jego odpieczętowaniu, 14 dniowy okres odstąpienia od Umowy nie
                    obowiązuje ze względu ochrony zdrowia oraz względów higienicznych towaru.
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                <ParagraphHeader>Postanowienia końcowe</ParagraphHeader>
                <OrderedList>
                  <ListItem>
                    Wszelkie zmiany do Umowy z wyjątkiem zmian podlegających sukcesji uniwersalnej w rozumieniu obowiązujących przepisów
                    prawa, mogą zostać dokonane jedynie przy jednoczesnej fizycznej obecności Stron lub fizycznej obecności Uczestnika
                    i reprezentanta CHIC oraz za ich wyraźną zgodą wyrażoną w aneksie do Umowy, z tym zastrzeżeniem, że rozwiązanie
                    i wypowiedzenie Umowy może zostać dokonane w formie dokumentowej, poprzez zdalne złożenie oświadczenia woli zgodnie
                    z postanowieniami § 7 Umowy.
                  </ListItem>
                  <ListItem>
                    W sprawach nieuregulowanych Umową stosuje się przepisy prawa polskiego w szczególności Kodeksu cywilnego oraz Ustawy
                    o ochronie zdrowia przed następstwami używania tytoniu i wyrobów tytoniowych.
                  </ListItem>
                  <ListItem>
                    CHIC udostępnia numer telefonu: 800 610 610 pod którym codziennie w godzinach od 8:00 do 20:00
                    można uzyskać informacje związane z Umową. Koszt połączenia telefonicznego ustalany jest według taryfy/cennika operatora.
                  </ListItem>
                  <ListItem>
                    Wszystkie czynności prawne i faktyczne związane z Umową podlegają polskiemu prawu i jurysdykcji polskich organów
                    i sądów polskich.
                  </ListItem>
                  <ListItem>
                    Ewentualne spory powstałe w trakcie realizacji Umowy podlegają rozpoznaniu przez sąd właściwy dla siedziby CHIC.
                  </ListItem>
                  <ListItem>
                    Umowę sporządzono w dwóch jednobrzmiących egzemplarzach, po jednym dla każdej ze Stron.
                  </ListItem>
                </OrderedList>
              </ListItem>
            </ParagraphsSection>
            <SectionWithHeadline>
              <PageTitle size={PageTitleSize.Small} label='**Załącznik nr 1**' />
              <AttachmentsContainer>
                {userProductsTableData && (
                  <ElementsTable
                    colorTheme={ComponentColorTheme.IC}
                    columns={[{
                      name: 'productName',
                      label: t('chic.hostess.subscriptionContractView.elementsTable.productName'),
                    }, {
                      name: 'productQuantity',
                      label: t('chic.hostess.subscriptionContractView.elementsTable.productQuantity'),
                      options: { align: TextAlign.Center },
                    }, {
                      name: 'productPrice',
                      label: t('chic.hostess.subscriptionContractView.elementsTable.productPrice'),
                      options: { align: TextAlign.Right },
                    }]}
                    rows={userProductsTableData}
                  />
                )}
                {subscriptionSimulation && (
                  <StyledDetailsInfoGroup 
                    items={[
                      {
                        label: t('chic.hostess.subscriptionContractView.detailsInfoGroup.regularPrice'),
                        value: `${parsePrice(subscriptionSimulation.productsPrice.regular)} ${currency}`,
                      },
                      {
                        label: t('chic.hostess.subscriptionContractView.detailsInfoGroup.discount'),
                        value: planDiscount > 0 ? `${planDiscount}%` : '-',
                      },
                      {
                        label: t('chic.hostess.subscriptionContractView.detailsInfoGroup.finalPrice'),
                        value: `${parsePrice(subscriptionSimulation.productsPrice.discounted)} ${currency}`,
                      },
                    ]}      
                    colorTheme={ComponentColorTheme.IC}
                  />
                )}
              </AttachmentsContainer>
            </SectionWithHeadline>
            <SectionWithHeadline>
              <PageTitle size={PageTitleSize.Small} label={t('chic.hostess.subscriptionContractView.smsVerification')} />
              <SectionText text={t('chic.hostess.subscriptionContractView.smsVerificationTitle')} />
              <Input 
                label={t('chic.hostess.subscriptionContractView.smsCodeInput.label')}
                placeholder={t('chic.hostess.subscriptionContractView.smsCodeInput.placeholder')}
                onChange={setSmsCode}
                value={smsCode} 
                colorTheme={ComponentColorTheme.IC}
                required
              />
            </SectionWithHeadline>
          </MainContainer>
        </Container>
      </BaseViewForOrders>
    );
  }

  return (
    <LoadingContainer>
      <Loader colorTheme={ComponentColorTheme.IC} />
    </LoadingContainer>
  );
};
