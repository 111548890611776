import React, { useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';

import { 
  Container, 
  LogoBox, 
  StyledLogo, 
  StyledInput, 
  StyledValidationBar, 
  RemindPasswordWrapper, 
  StyledLink,
  ButtonWrapper,
  FormContainer,
  LoginBox,
  InnerBlackBox,
  InnerForm,
  Title, 
} from './signIn.styled';
import { classicLogin } from '@chic/api';
import { FrontendApiError, LoginData, LoginErrorData, SimpleAuthData, UseAuth } from '@chic/models';
import { useAuth, useTitle } from '@chic/hooks';
import { Formik, FormikProps, FormikValues } from 'formik';
import { RoutingPath, UserLoginErrorType } from '@chic/enums';
import { stringify } from 'query-string';
import { 
  Breakpoint, 
  Button, 
  ButtonTheme, 
  ComponentColorTheme, 
  InputTheme, 
  LogoTheme, 
  PageTitle, 
  UseRedirect, 
  UseState,
  useRedirect, 
} from '@chic-loyalty/ui';
import { useMediaQuery } from 'react-responsive';

export const SignInView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { signIn }: UseAuth = useAuth();
  const { redirect }: UseRedirect = useRedirect();
  const [errorMessage, setErrorMessage]: UseState<string | null> = useState<string | null>(null);
  const [isLoading, setIsLoading]: UseState<boolean> = useState<boolean>(false);
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  useTitle(t('chic.crmApp.global.signIn.title'));

  const loginMethod: (values: SimpleAuthData) => void = (values: SimpleAuthData): void => {
    setIsLoading(true);
    classicLogin(values)
      .then((data: LoginData): void => {
        setIsLoading(false);
        signIn({ user: data.user, token: data.token });
      })
      .catch((error: FrontendApiError<LoginErrorData | {}>): void => {
        if ((error.data as LoginErrorData).type === UserLoginErrorType.ChangePasswordRequired) {
          redirect(
            `${RoutingPath.ChangePassword}?${stringify({ t: (error.data as LoginErrorData).token.value }, { skipEmptyString: true })}`,
          );
        }
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  return (
    <Container>
      <LogoBox>
        {!isMobile && <StyledLogo logoTheme={LogoTheme.ICWhite} />}
      </LogoBox>
      <LoginBox>
        <InnerBlackBox>
          <Formik initialValues={{ login: '', password: '' }} onSubmit={loginMethod}>
            {({ handleSubmit, setFieldValue }: FormikProps<FormikValues>): JSX.Element => (
              <FormContainer onSubmit={handleSubmit}>
                <InnerForm>
                  <PageTitle label={t('chic.crmApp.global.signIn.title')} />
                  <Title text={t('chic.hostess.global.title')} />
                  <StyledInput
                    onChange={(value: string): void => setFieldValue('login', value)}
                    label={t('chic.crmApp.global.login.label')}
                    placeholder={t('chic.crmApp.global.login.placeholder')}
                    colorTheme={ComponentColorTheme.IC}
                    inputTheme={errorMessage ? InputTheme.Error : InputTheme.Standard}
                    required
                  />
                  <StyledInput
                    onChange={(value: string): void => setFieldValue('password', value)}
                    label={t('chic.crmApp.global.password.label')}
                    placeholder={t('chic.crmApp.global.password.placeholder')}
                    type='password'
                    colorTheme={ComponentColorTheme.IC}
                    inputTheme={errorMessage ? InputTheme.Error : InputTheme.Standard}
                    required
                  />
                  <RemindPasswordWrapper>
                    <StyledLink to={RoutingPath.RemindPassword}>{t('chic.crmApp.loginFormSection.remindPassword')}</StyledLink>
                  </RemindPasswordWrapper>
                  {errorMessage && (
                    <StyledValidationBar message={errorMessage} colorTheme={ComponentColorTheme.IC} />
                  )}
                </InnerForm>
                <ButtonWrapper>
                  <Button
                    type='submit'
                    isLoading={isLoading}
                    buttonTheme={ButtonTheme.ICPrimary}
                    label={t('chic.crmApp.global.login.buttonLabel')}
                  />
                </ButtonWrapper>
              </FormContainer>
            )}
          </Formik>
        </InnerBlackBox>
      </LoginBox>
    </Container>
  );
};
