import React from 'react';
import { PlansStagesProps } from './plansStagesTable.types';
import { Table, TableCell, TableHeader, TableRow } from './plansStagesTable.styled';
import { useTranslation, TransProps } from 'react-i18next';
import { PlanStage } from '@chic/models';

export const PlansStagesTable: React.FC<PlansStagesProps> = (props: PlansStagesProps): JSX.Element => {
  const { stages, className }: PlansStagesProps = props;
  const { t }: TransProps<never> = useTranslation();

  return (
    <Table className={className}>
      <TableRow>
        <TableHeader>{t('chic.hostess.subscriptionContractView.plansStagesTable.stageValue')}</TableHeader>
        <TableHeader>{t('chic.hostess.subscriptionContractView.plansStagesTable.stageDiscount')}</TableHeader>
      </TableRow>
      {stages.map((stage: PlanStage, index: number): JSX.Element => (
        <TableRow key={index}>
          <TableCell>{stage.priceRange}</TableCell>
          <TableCell>{stage.discountDescription}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
};
