import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { RegistrationField } from '../registration.enums';
import { VerificationFormData } from '../registration.types';

export const useVerificationValidation: () => Yup.Schema<VerificationFormData> = (): Yup.Schema<VerificationFormData> => {
  const { t }: TransProps<never> = useTranslation();

  return Yup.object().shape({
    [RegistrationField.SmsCode]: Yup.string()
      .min(2, t('chic.crmApp.global.wrongValue'))
      .max(10, t('chic.crmApp.global.wrongValue'))
      .required(t('chic.crmApp.global.requiredField')),
  });
};
