import { Breakpoint, Color, Grid, PageTitle, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  padding: 30px 24px 0;
  width: 100%;
`;

export const MainContainer: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICTablet} + 48px);
  width: 100%;
  padding: 0 24px 62px;
  flex: 1;
`;

export const SmallPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  margin: 62px 0 30px;
`;

export const ButtonsContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 120px;
  background: linear-gradient(188.65deg, ${Color.ICBlack100} -36.36%, ${Color.ICBlack500} 126.99%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    gap: 12px;
    height: 132px;
  }
`;

export const RadioButtonContainer: StyledComponent<'div'> = styled.div`
  padding: 20px;
  background: ${Color.ICBlack400};
  cursor: pointer;

  & + & {
    margin-top: 5px;
  }
`;

export const SectionDescription: StyledComponent<'div'> = styled.div`
  color: ${Color.ICGray200};
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 30px;
`;

