import { Dispatch } from 'react';

import { AppStateAction } from '@chic/enums';
import { AppStateActions } from '@chic/models';
import { FullscreenAlertSettings } from '@chic-loyalty/ui';

export const setFullscreenAlertDataAction: (dispatch: Dispatch<AppStateActions>, alertData: FullscreenAlertSettings | null) => void = (
  dispatch: Dispatch<AppStateActions>, alertData: FullscreenAlertSettings | null,
): void => {
  dispatch({ type: AppStateAction.SetFullscreenAlertData, payload: alertData });
};
