export enum PermissionName {
  CanViewSubscriptions = 'canViewSubscriptions',
  CanCreateSubscriptions = 'canCreateSubscriptions',
  CanEditSubscriptionProducts = 'canEditSubscriptionProducts',
  CanEditSubscriptionDelivery = 'canEditSubscriptionDelivery',
  CanEditSubscriptionDeliveryDate = 'canEditSubscriptionDeliveryDate',
  CanChangePaymentMethod = 'canChangePaymentMethod',
  CanChoosePickupPointAsDelivery = 'canChoosePickupPointAsDelivery',
  CanPayByCreditCard = 'canPayByCreditCard',
}
