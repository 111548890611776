import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { BaseViewForOrders } from '@chic/components';

import { 
  ComponentColorTheme,
  RadioButton, 
  useRedirect, 
  UseRedirect, 
  UseState, 
} from '@chic-loyalty/ui';
import { NameCode, UseOrder, UseStatics } from '@chic/models';
import { RoutingPath, SubscriptionParameterType } from '@chic/enums';
import { MainContainer, RadioButtonContainer, SectionDescription, SmallPageTitle } from './subscriptionParametersChoose.styled';
import { ParameterComponentType } from './subscriptionParametersChoose.enums';
import { ParameterDetails } from './subscriptionParametersChoose.types';
import { useOrder, useStatics } from '@chic/hooks';
import { SubscriptionStaticParameter } from '@chic/types';

export const SubscriptionParametersChooseView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const [parametersDetails, setParametersDetails]: UseState<ParameterDetails[]> = useState<ParameterDetails[]>([]);
  const { parameters }: UseStatics = useStatics();
  const { orderData, saveOrderStateValues }: UseOrder = useOrder();
  const [parametersValues, setParametersValues]: UseState<Partial<Record<SubscriptionParameterType, string>>>
    = useState<Partial<Record<SubscriptionParameterType, string>>>(orderData.parameters ?? {} as Record<SubscriptionParameterType, string>);

  const onSave: () => void = (): void => {
    saveOrderStateValues({ parameters: { [SubscriptionParameterType.CyclicOrder]: parametersValues.cyclicOrder } });
    redirect(RoutingPath.SubscriptionSummary);
  };


  useEffect(
    (): void => {
      if (!parameters.length) {
        return;
      }

      const parametersEditableStatus: Record<SubscriptionParameterType, boolean> = {
        [SubscriptionParameterType.CyclicOrder]: true,
        [SubscriptionParameterType.PosPayment]: false,
      };
      setParametersDetails(parameters
        .map((param: SubscriptionStaticParameter): ParameterDetails | null => {
          if (!parametersEditableStatus[param.type]) {
            return null;
          }
          const paramDetails: ParameterDetails = {
            type: ParameterComponentType.Radiogroup,
            name: param.type,
            header: param.name,
            options: param.values,
          };
          switch (param.type) {
            case SubscriptionParameterType.CyclicOrder:
              paramDetails.description = t('chic.hostess.subscriptionParametersChooseView.parameters.cyclicOrder.description');
              break;
          }

          return paramDetails;
        })
        .filter((item: ParameterDetails | null): item is ParameterDetails => !!item),
      );
    },
    [parameters],
  );

  const changeParametersValues: (parameterName: string, parameterValue: string) => void = (
    parameterName: string, parameterValue: string,
  ): void => {
    setParametersValues({ ...parametersValues, [parameterName]: parameterValue });
  };

  const goBack: () => void = (): void => {
    if (orderData.canPayByCreditCard) {
      redirect(RoutingPath.SubscriptionPayment);
    } else {
      redirect( RoutingPath.SubscriptionDelivery);
    } 
  };

  return (
    <BaseViewForOrders
      pageTitle={t('chic.hostess.subscriptionParametersChooseView.pageTitle')}
      pageTitleAction={goBack}
      acceptButtonSettings={{
        label: t('chic.hostess.subscriptionParametersChooseView.acceptActionLabel'),
        onClick: onSave,
        disabled: !Object.keys(parametersValues).length,
      }}
      cancelButtonSettings={{
        label: t('chic.hostess.global.goBack'),
        onClick: goBack,
      }}
      withContentScroll
    >
      <MainContainer>
        {parametersDetails.map((item: ParameterDetails): JSX.Element => (
          <React.Fragment key={item.name}>
            <SmallPageTitle label={item.header} />
            {item.description && (<SectionDescription>{item.description}</SectionDescription>)}
            {item.type === ParameterComponentType.Radiogroup && (
              <>
                {item.options.map((option: NameCode): JSX.Element => (
                  <RadioButtonContainer
                    key={option.code}
                    onClick={(): void => changeParametersValues(item.name, option.code)}
                  >
                    <RadioButton
                      checked={parametersValues[item.name] === option.code}
                      label={option.name}
                      id={option.code}
                      colorTheme={ComponentColorTheme.IC}
                    />
                  </RadioButtonContainer>
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </MainContainer>
    </BaseViewForOrders>
  );
};
