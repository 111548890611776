export enum PermissionScopeName {
  // common paths
  AuthUser = 'auth_user',
  AuthUserScopes = 'auth_user_scopes',
  AuthUserUpdateSelfPassword = 'auth_user_update_self_password',
  Scopes = 'scopes',
  AuthUserProfile = 'auth_user_profile',
  ActiveRolesList = 'active_roles_list',
  UserVerificationList = 'user_verification_list',
  UserCrmDetails = 'user_crm_details',
  UserVerificationVerify = 'user_verification_verify',
  ExternalUserCreate = 'external_user_create',
  ExternalUserList = 'external_user_list',
  ExternalUserActivity = 'external_user_activity',
  // custom paths for forks apps
  SubscriptionPlans = 'subscription_plans',
  SubscriptionPlansDetails = 'subscription_plans_details',
  SubscriptionCreate = 'subscription_create',
  SubscriptionConfirm = 'subscription_confirm',
  SubscriptionConfirmCodeResend = 'subscription_confirm_code_resend',
  SubscriptionCancel = 'subscription_cancel',
  SubscriptionSimulation = 'subscription_simulation',
  StaticAgreements = 'static_agreements',
  StaticConfig = 'static_config',
  StaticDeliveryTypes = 'static_delivery_types',
  StaticSubscriptionDictionary = 'static_subscription_dictionary',
  InpostParcelList = 'inpost_parcel_list',
  InpostParcelSearch = 'inpost_parcel_search',
  ConsumerAddressesList = 'consumer_addresses_list',
  ConsumerAddressesCreate = 'consumer_addresses_create',
  ConsumerCheckContactData = 'consumer_check_contact_data',
  ConsumerLogin = 'consumer_login',
  ConsumerRecovery = 'consumer_recovery',
  ConsumerRegisterCard = 'consumer_register_card',
  ConsumerRegisterUser = 'consumer_register_user',
  ConsumerRegisterUserData = 'consumer_register_user_data',
  ConsumerRegisterSubscriptions = 'consumer_register_subscriptions',
  ConsumerResendVerificationSms = 'consumer_resend_verification_sms',
  ConsumerSubscriptionDetails = 'consumer_subscription_details',
  BackofficeMatchUser = 'backoffice_match_user',
  BackofficeRegisterUser = 'backoffice_register_user',
  BackofficeSubscriptionCreate = 'backoffice_subscription_create',
  PickupPointsDetails = 'pickup_points_details',
  PickupPointsSearch = 'pickup_points_search',
}
