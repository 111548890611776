import { initialAppState } from '@chic/constans';
import { AppStateAction } from '@chic/enums';
import { AppState, AppStateActions, ObjectIdWithAmount, ProductItem } from '@chic/models';

export const appStateReducer = (state: AppState, action: AppStateActions): AppState => {
  switch (action.type) {
    // common
    case AppStateAction.SetFullscreenAlertData:
      return { ...state, fullscreenAlertData: action.payload };
    case AppStateAction.SetStateToInitial:
      return { ...initialAppState };
    // custom for forks apps
    case AppStateAction.ClearSubscriptionData:
      return { ...state, subscriptionChoices: initialAppState.subscriptionChoices };
    case AppStateAction.ClearConsumerData:
      return { ...state, consumerData: initialAppState.consumerData };
    case AppStateAction.SetPlanId:
      return {
        ...state,
        subscriptionChoices: {
          ...state.subscriptionChoices,
          planId: action.payload,
        },
      };
    case AppStateAction.SetPlanCategoryId:
      return {
        ...state,
        subscriptionChoices: {
          ...state.subscriptionChoices,
          planCategoryId: action.payload,
        },
      };
    case AppStateAction.SetSubscriptionSigningLocation:
      return {
        ...state,
        subscriptionChoices: {
          ...state.subscriptionChoices,
          signingLocation: action.payload,
        },
      };
    case AppStateAction.SetSubscriptionContractNumber:
      return {
        ...state,
        subscriptionChoices: {
          ...state.subscriptionChoices,
          contractNumber: action.payload,
        },
      };
    case AppStateAction.SetSubscriptionSigningDate:
      return {
        ...state,
        subscriptionChoices: {
          ...state.subscriptionChoices,
          signingDate: action.payload,
        },
      };
    case AppStateAction.SetFlowInProgress:
      return {
        ...state,
        flowInProgress: action.payload,
      };
    case AppStateAction.RemoveProducts:
      return {
        ...state,
        subscriptionChoices: {
          ...state.subscriptionChoices,
          products: [],
        },
      };
    case AppStateAction.AddProduct:
      return {
        ...state,
        subscriptionChoices: {
          ...state.subscriptionChoices,
          products: [...state.subscriptionChoices.products, action.payload],
        },
      };
    case AppStateAction.RemoveProduct:
      return {
        ...state,
        subscriptionChoices: {
          ...state.subscriptionChoices,
          products: [...state.subscriptionChoices.products.filter((product: ProductItem)=> product.id !== action.payload)],
        },
      };
    case AppStateAction.ChangeProductAmount:
      const itemToChangeIndex: number = state.subscriptionChoices.products.findIndex(
        (item: ObjectIdWithAmount) => item.id === action.payload.id,
      );
      state.subscriptionChoices.products[itemToChangeIndex] = action.payload;
      return { ...state };
    case AppStateAction.SetDeliveryData:
      return {
        ...state,
        subscriptionChoices: {
          ...state.subscriptionChoices,
          subscriptionDeliveryDetails: action.payload,
        },
      };
    case AppStateAction.SetDeliveryInpostData:
      return {
        ...state,
        subscriptionChoices: {
          ...state.subscriptionChoices,
          subscriptionDeliveryDetails: {
            name: '',
            address:'',
            postalCode: '',
            city: '',
            email: '',
            phone:'',
            deliveryType: null,
            ...(state.subscriptionChoices.subscriptionDeliveryDetails ? state.subscriptionChoices.subscriptionDeliveryDetails : {}),
            inpostData: action.payload,
          },
        },
      };
    case AppStateAction.SetDeliveryPickupPoint:
      return {
        ...state,
        subscriptionChoices: {
          ...state.subscriptionChoices,
          subscriptionDeliveryDetails: {
            name: '',
            address:'',
            postalCode: '',
            city: '',
            email: '',
            phone:'',
            deliveryType: null,
            ...(state.subscriptionChoices.subscriptionDeliveryDetails ? state.subscriptionChoices.subscriptionDeliveryDetails : {}),
            pickupPoint: action.payload,
          },
        },
      };
    case AppStateAction.SetConsumerData:
      return {
        ...state,
        consumerData: {
          ...state.consumerData,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
