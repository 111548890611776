import { eanOrEmailRegexp } from '@chic-loyalty/ui';
import { VerificationFormData } from './subscriptionUserVerification.types';
import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { smsCodeRegexp } from '@chic/constans';

export const useUserVerification: () => Yup.Schema<VerificationFormData> = (): Yup.Schema<VerificationFormData> => {
  const { t }: TransProps<never> = useTranslation();
  
  return Yup.object().shape({
    login: Yup.string()
      .required(t('chic.crmApp.global.requiredField'))
      .matches(eanOrEmailRegexp, t('chic.crmApp.global.wrongValue')),
    smsCode: Yup.string()
      .required(t('chic.crmApp.global.requiredField'))
      .matches(smsCodeRegexp, t('chic.crmApp.global.wrongValue')),
  });
};
