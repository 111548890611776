import { ConfigElement, ConfigsContextType, UseInitial } from '@chic/models';
import { useEffect, useMemo, useState } from 'react';
import { appConfig } from '../appConfig.config';
import { getConfig } from '@chic/api';
import { ConfigKey, LocalStorageKey } from '@chic/enums';
import { UseLocalStorage, UseState, useLocalStorage } from '@chic-loyalty/ui';

export const useInitial: () => UseInitial = (): UseInitial => {
  const [configs, setConfigs] = useState<ConfigsContextType | null>(null);
  const [authToken]: UseLocalStorage<string | null> = useLocalStorage<string | null>(LocalStorageKey.LoggedUserToken, '');
  const [systemConfigLoaded, setSystemConfigLoaded]: UseState<boolean> = useState<boolean>(false);
  const initSystemConfig: Record<ConfigKey, boolean> = useMemo(
    (): Record<ConfigKey, boolean> => Object.values(ConfigKey)
      .map((item: ConfigKey): Record<ConfigKey, boolean> => ({ [item]: false } as Record<ConfigKey, boolean>))
      .reduce((acc: Record<ConfigKey, boolean>, item: Record<ConfigKey, boolean>): Record<ConfigKey, boolean> => ({ ...acc, ...item })),
    [],
  );

  useEffect(
    (): void => {
      let configsValue: ConfigsContextType = {} as ConfigsContextType;
      let overridedConfigsValue: ConfigsContextType = {} as ConfigsContextType;

      const promises: Promise<void>[] = appConfig.configFilenames.map((configName: string): Promise<void> => {
        return new Promise((resolve: (value: void) => void): void => {
          import(`../configs/${configName}.config`)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((content: any) => {
              const fieldName: string = `${configName}Config`;
              configsValue = { ...configsValue, [configName]: content[fieldName] };
              resolve();
            })
            .catch((): void => undefined);
        });
      });

      const promisesForOverrided: Promise<void>[] = appConfig.overridedConfigFilenames.map((configName: string): Promise<void> => {
        return new Promise((resolve: (value: void) => void): void => {
          import(`../configs/overrides/${configName}.config`)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((content: any) => {
              const fieldName: string = `${configName}Config`;
              overridedConfigsValue = { ...overridedConfigsValue, [configName]: content[fieldName] };
              resolve();
            })
            .catch((): void => resolve());
        });
      });

      Promise.all([
        ...promises,
        ...promisesForOverrided,
      ]).then((): void => {
        setConfigs({
          ...configsValue,
          ...overridedConfigsValue,
          systemConfig: initSystemConfig,
        });
      });
    },
    [],
  );

  useEffect(
    (): void => {
      if (authToken && configs && !systemConfigLoaded) {
        getConfig()
          .then((data: ConfigElement[]): void => {
            const systemConfig: Record<ConfigKey, boolean> = data.reduce(
              (acc: Record<ConfigKey, boolean>, item: ConfigElement): Record<ConfigKey, boolean> => {

                return {
                  ...acc,
                  [item.name]: item.value,
                };
              },
              initSystemConfig,
            );

            setSystemConfigLoaded(true);
            setConfigs({
              ...configs,
              systemConfig,
            });
          })
          .catch((): void => undefined);
      }
    },
    [authToken, configs],
  );

  return { configs };
};
