import { BaseViewForOrders } from '@chic/components';
import React, { useEffect, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { 
  Address, 
  AddressButton, 
  FormikForm, 
  InputWrapper, 
  MainContainer, 
  SmallPageTitle, 
  StyledInput, 
} from './subscriptionDelivery.styled';
import { 
  ButtonTheme, 
  ComponentColorTheme, 
  InputTheme, 
  OptionSwitchOption, 
  OptionsSwitch, 
  OptionsSwitchSize, 
  UseRedirect, 
  UseState, 
  useRedirect, 
} from '@chic-loyalty/ui';
import { 
  DeliveryPickupPointType,
  DeliveryType, 
  RoutingPath, 
  SubscriptionDeliveryField, 
  SubscriptionPaymentMethod, 
} from '@chic/enums';
import { Formik, FormikProps } from 'formik';
import { 
  ConfigsContextType,
  DeliveryDestination,
  ObjectIdWithAmount, 
  PickupPointDetails, 
  SubscriptionDeliveryCost, 
  SubscriptionSimulation, 
  SubscriptionSimulationData, 
  UseOrder,
  UseSubscriptionOrder, 
} from '@chic/models';
import { useSubscriptionOrderEditDeliveryValidations } from './subscriptionDelivery.hooks';
import { Schema } from 'yup';
import { useConfig, useOrder } from '@chic/hooks';
import { getCustomerAddresses, getPickupPointDetails, simulateSubscription } from '@chic/api';
import { isDeliveryPickupPointType } from '@chic/utils';
import { UpdateSubscriptionDestinationBasicData } from './subscriptionDelivery.types';
import { pickupPointsDeliveryTypes } from '@chic/constans';
import { useSubscriptionOrder } from '../hooks';

export const SubscriptionDeliveryView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { orderData, saveOrderStateValues }: UseOrder = useOrder();
  const { getPickupPointAddress }: UseSubscriptionOrder = useSubscriptionOrder();
  const [selectedDelivery, setSelectedDelivery]: UseState<DeliveryType | null> = useState<DeliveryType | null>(null);
  const [formikValues, setFormikValues]: UseState<UpdateSubscriptionDestinationBasicData | null>
    = useState<UpdateSubscriptionDestinationBasicData | null>(null);
  const [isFormValid, setIsFormValid]: UseState<boolean> = useState<boolean>(true);
  const [deliveries, setDeliveries]: UseState<SubscriptionDeliveryCost[]> = useState<SubscriptionDeliveryCost[]>([]);
  const [lastUsedAddress, setLastUsedAddress]: UseState<DeliveryDestination | null> = useState<DeliveryDestination | null>(null);
  const [pickupPoint, setPickupPoint]: UseState<PickupPointDetails | null> = useState<PickupPointDetails | null>(null);
  const [isCustomerAddressUsed, setIsCustomerAddressUsed]: UseState<boolean> = useState<boolean>(false);
  const deliveryUpdateFormSchema: Schema<UpdateSubscriptionDestinationBasicData> = useSubscriptionOrderEditDeliveryValidations();
  const currency: string = 'zł';
  const { systemConfig }: ConfigsContextType = useConfig();

  useEffect(
    (): void => {
      const productsFromStorage: Record<number, number> | undefined = orderData.products;
      const planId: number | undefined = orderData.planId;
      const customerId: number | undefined = orderData.customerId;

      if (!!productsFromStorage && !!planId && !!customerId && !deliveries.length) {
        const simulateData: SubscriptionSimulationData = {
          planId: planId,
          customerId: customerId,
          products: Object.entries(productsFromStorage).map((product: [string, number]): ObjectIdWithAmount => ({
            id: parseInt(product[0], 10),
            amount: product[1],
          })),
        };

        simulateSubscription(simulateData)
          .then((data: SubscriptionSimulation): void => setDeliveries(data.deliveriesCosts))
          .catch((): void => undefined);
      }
    },
    [orderData],
  );

  const isDeliveryTypePickupPointAndInSimulation: (type: DeliveryType | DeliveryPickupPointType) => boolean = (
    type: DeliveryType | DeliveryPickupPointType,
  ): boolean => {
    return !!deliveries.find((delivery: SubscriptionDeliveryCost): boolean => (
      isDeliveryPickupPointType(delivery.code) &&
      delivery.code === type
    ));
  };

  useEffect(
    (): void => {
      if (!deliveries.length) {
        return;
      }

      const customerId: number | undefined = orderData.customerId;
      const pickupPointStorage: PickupPointDetails | undefined = orderData.deliveryDestination?.pickupPoint;
      const selectedDeliveryType: DeliveryType | undefined = orderData.selectedDelivery;

      if (customerId && !lastUsedAddress && !isCustomerAddressUsed) {
        getCustomerAddresses(customerId)
          .then((data: DeliveryDestination[]): void => {
            const lastDelivery: DeliveryDestination | undefined = data.find((destination: DeliveryDestination): boolean => !!deliveries
              .find((delivery: SubscriptionDeliveryCost): boolean => delivery.code === destination.type.code));

            if (lastDelivery) {
              setLastUsedAddress(lastDelivery);

              if (!selectedDeliveryType) {
                setSelectedDelivery(lastDelivery.type.code);
                saveOrderStateValues({ selectedDelivery: lastDelivery.type.code });
              }
            }

            if (selectedDeliveryType) {
              setSelectedDelivery(selectedDeliveryType);
              saveOrderStateValues({ selectedDelivery: selectedDeliveryType });
            }

            if (
              lastDelivery?.pickupPoint 
              && isDeliveryTypePickupPointAndInSimulation(lastDelivery.pickupPoint.type)
              && !pickupPointStorage
            ) {
              getPickupPointDetails(lastDelivery.pickupPoint.type, String(lastDelivery.pickupPoint.id))
                .then((pickupPointData: PickupPointDetails): void => {
                  setPickupPoint(pickupPointData);
                  saveOrderStateValues({ deliveryDestination: { ...orderData.deliveryDestination, pickupPoint: pickupPointData } });
                })
                .catch((): void => undefined);
            } else if (
              pickupPointStorage?.id 
              && isDeliveryPickupPointType(orderData.selectedDelivery) 
              && isDeliveryTypePickupPointAndInSimulation(orderData.selectedDelivery)
            ) {
              getPickupPointDetails(
                orderData.selectedDelivery,
                String(pickupPointStorage.id),
              )
                .then((pickupPointData: PickupPointDetails): void => {
                  setPickupPoint(pickupPointData);
                  saveOrderStateValues({ deliveryDestination: { ...orderData.deliveryDestination, pickupPoint: pickupPointData } });
                })
                .catch((): void => undefined);
            }
          })
          .catch((): void => undefined)
          .finally((): void => setIsCustomerAddressUsed(true));
      }
    },
    [deliveries, orderData],
  );

  const onDeliverySave: () => void = (): void => {
    if (isFormValid && !!selectedDelivery && !!formikValues) {
      saveOrderStateValues({
        deliveryDestination: {
          deliveryType: selectedDelivery,
          pickupPoint: pickupPoint ?? undefined,
          name: formikValues.name,
          address: formikValues.address,
          city: formikValues.city,
          postalCode: formikValues.postalCode,
        },
        email: formikValues.email,
        phone: formikValues.phone,
        paymentMethod: orderData.canPayByCreditCard ? undefined : SubscriptionPaymentMethod.Payu,
      });

      if (orderData.canPayByCreditCard) {
        redirect(RoutingPath.SubscriptionPayment);
      } else if (systemConfig.subscriptionParameterCyclicOrderEnabled) {
        redirect(RoutingPath.SubscriptionParametersChoose);
      } else {
        redirect(RoutingPath.SubscriptionSummary);
      }
    }
  };

  return (
    <BaseViewForOrders 
      pageTitle={t('chic.hostess.subscriptionDeliveryView.pageTitle')} 
      pageTitleAction={(): void => redirect(RoutingPath.SubscriptionProductsChoose)}
      acceptButtonSettings={{
        label: t('chic.hostess.global.goNext'),
        onClick: onDeliverySave,
        disabled: !isFormValid || (
          !!selectedDelivery 
          && pickupPointsDeliveryTypes.includes(selectedDelivery) 
          && !pickupPoint
        ) || !selectedDelivery, 
      }} 
      cancelButtonSettings={{
        label: t('chic.hostess.global.goBack'),
        onClick: (): void => redirect(RoutingPath.SubscriptionProductsChoose),
      }}
    >
      <Formik
        initialValues={{
          name: orderData.deliveryDestination?.name ?? lastUsedAddress?.name ?? '',
          address: orderData.deliveryDestination?.address ?? lastUsedAddress?.address ?? '',
          postalCode: orderData.deliveryDestination?.postalCode ?? lastUsedAddress?.postalCode ?? '',
          city: orderData.deliveryDestination?.city ?? lastUsedAddress?.city ?? '',
          email: orderData.email ?? '',
          phone: orderData.phone ?? '',
        }}
        innerRef={(formikActions: FormikProps<UpdateSubscriptionDestinationBasicData> | null) => {
          setFormikValues(formikActions?.values ?? null);
          setIsFormValid(formikActions?.isValid ?? false);
        }}
        onSubmit={(): void => undefined}
        validationSchema={deliveryUpdateFormSchema}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, errors, values }: FormikProps<UpdateSubscriptionDestinationBasicData>): JSX.Element => (
          <FormikForm onSubmit={handleSubmit} style={{ width: '100%' }}>
            <MainContainer>
              <SmallPageTitle label={t('chic.hostess.subscriptionDeliveryView.deliveryType.label')} />
              <OptionsSwitch
                options={deliveries.map((delivery: SubscriptionDeliveryCost): OptionSwitchOption => ({
                  name: delivery.code,
                  label: delivery.name,
                  description: !delivery.cost 
                    ? t('chic.crmApp.global.free') 
                    : `${`(${delivery.cost} ${currency})`}`,
                }))}
                onOptionChange={(name: string): void => {
                  setSelectedDelivery(name as DeliveryType);
                  saveOrderStateValues({ selectedDelivery: name as DeliveryType });
                }}
                activeOptionName={selectedDelivery ?? undefined}
                colorTheme={ComponentColorTheme.IC}
                size={OptionsSwitchSize.Small}
              />
              {!!selectedDelivery 
              && isDeliveryTypePickupPointAndInSimulation(selectedDelivery)
              && (
                <>
                  <SmallPageTitle label={t('chic.hostess.subscriptionDeliveryView.address.label')} $withMarginTop />
                  <Address text={pickupPoint?.location
                    ? getPickupPointAddress(pickupPoint)
                    : t('chic.hostess.subscriptionDeliveryView.address.emptyPickupPoint')
                  } />
                  <AddressButton 
                    label={t('chic.hostess.global.change')}
                    buttonTheme={ButtonTheme.ICText} 
                    onClick={(): void => redirect(RoutingPath.SubscriptionDeliveryPickupPoint)} 
                  />
                </>
              )}
              <SmallPageTitle
                label={`**${!!selectedDelivery && pickupPointsDeliveryTypes.includes(selectedDelivery)
                  ? t('chic.hostess.subscriptionDeliveryView.billingAddress.label')
                  : t('chic.hostess.subscriptionDeliveryView.address.label')
                }**`}
                $withMarginTop
              />
              <StyledInput 
                label={t('chic.hostess.subscriptionDeliveryView.input.name.label')}
                placeholder={t('chic.hostess.subscriptionDeliveryView.input.name.placeholder')}
                type='text'
                onChange={(value: string): void => setFieldValue(SubscriptionDeliveryField.Name, value)}
                value={values[SubscriptionDeliveryField.Name]}
                inputTheme={errors[SubscriptionDeliveryField.Name] ? InputTheme.Error : InputTheme.Standard}
                message={errors[SubscriptionDeliveryField.Name]}
                colorTheme={ComponentColorTheme.IC}
                required
              />
              <StyledInput 
                label={t('chic.hostess.subscriptionDeliveryView.input.address.label')}
                placeholder={t('chic.hostess.subscriptionDeliveryView.input.address.placeholder')}
                type='text'
                onChange={(value: string): void => setFieldValue(SubscriptionDeliveryField.Address, value)}
                value={values[SubscriptionDeliveryField.Address]}
                inputTheme={errors[SubscriptionDeliveryField.Address] ? InputTheme.Error : InputTheme.Standard}
                message={errors[SubscriptionDeliveryField.Address]}
                colorTheme={ComponentColorTheme.IC}
                required
              />
              <InputWrapper>
                <StyledInput 
                  label={t('chic.hostess.subscriptionDeliveryView.input.postalCode.label')}
                  placeholder={t('chic.hostess.subscriptionDeliveryView.input.postalCode.placeholder')}
                  type='text'
                  onChange={(value: string): void => setFieldValue(SubscriptionDeliveryField.PostalCode, value)}
                  value={values[SubscriptionDeliveryField.PostalCode]}
                  inputTheme={errors[SubscriptionDeliveryField.PostalCode] ? InputTheme.Error : InputTheme.Standard}
                  message={errors[SubscriptionDeliveryField.PostalCode]}
                  colorTheme={ComponentColorTheme.IC}
                  required
                />
                <StyledInput 
                  label={t('chic.hostess.subscriptionDeliveryView.input.city.label')}
                  placeholder={t('chic.hostess.subscriptionDeliveryView.input.city.placeholder')}
                  type='text'
                  onChange={(value: string): void => setFieldValue(SubscriptionDeliveryField.City, value)}
                  value={values[SubscriptionDeliveryField.City]}
                  inputTheme={errors[SubscriptionDeliveryField.City] ? InputTheme.Error : InputTheme.Standard}
                  message={errors[SubscriptionDeliveryField.City]}
                  colorTheme={ComponentColorTheme.IC}
                  required
                />
              </InputWrapper>
              <SmallPageTitle label={t('chic.hostess.subscriptionDeliveryView.contact.label')} $withMarginTop />
              <StyledInput 
                label={t('chic.hostess.subscriptionDeliveryView.input.phone.label')}
                placeholder={t('chic.hostess.subscriptionDeliveryView.input.phone.placeholder')}
                description={t('chic.hostess.subscriptionDeliveryView.input.phone.description')}
                type='text'
                onChange={(value: string): void => setFieldValue(SubscriptionDeliveryField.Phone, value)}
                value={values[SubscriptionDeliveryField.Phone]}
                inputTheme={errors[SubscriptionDeliveryField.Phone] ? InputTheme.Error : InputTheme.Standard}
                message={errors[SubscriptionDeliveryField.Phone]}
                colorTheme={ComponentColorTheme.IC}
                hasPhonePrefix
                disabled
                required
              />
              <StyledInput 
                label={t('chic.hostess.subscriptionDeliveryView.input.email.label')}
                placeholder={t('chic.hostess.subscriptionDeliveryView.input.email.placeholder')}
                description={t('chic.hostess.subscriptionDeliveryView.input.email.description')}
                type='text'
                onChange={(value: string): void => setFieldValue(SubscriptionDeliveryField.Email, value)}
                value={values[SubscriptionDeliveryField.Email]}
                inputTheme={errors[SubscriptionDeliveryField.Email] ? InputTheme.Error : InputTheme.Standard}
                message={errors[SubscriptionDeliveryField.Email]}
                colorTheme={ComponentColorTheme.IC}
                disabled
                required
              />
            </MainContainer>
          </FormikForm>
        )}
      </Formik>
    </BaseViewForOrders>
  );
};
