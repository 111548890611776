import { TransProps, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { phoneRegexp, postalCodeRegexp } from '@chic/constans';
import { UpdateSubscriptionDestinationBasicData } from './subscriptionDelivery.types';

export const useSubscriptionOrderEditDeliveryValidations: () => Yup.Schema<UpdateSubscriptionDestinationBasicData> 
  = (): Yup.Schema<UpdateSubscriptionDestinationBasicData> => {
    const { t }: TransProps<never> = useTranslation();

    return Yup.object().shape({
      name: Yup.string()
        .min(2, t('chic.hostess.global.validations.name'))
        .max(64, t('chic.hostess.global.maxLength'))
        .required(t('chic.hostess.global.requiredField')),
      address: Yup.string()
        .min(2, t('chic.hostess.global.validations.address'))
        .max(128, t('chic.hostess.global.maxLength'))
        .required(t('chic.hostess.global.requiredField')),
      postalCode: Yup.string()
        .matches(postalCodeRegexp, t('chic.hostess.global.wrongValue'))
        .required(t('chic.hostess.global.requiredField')),
      city: Yup.string()
        .min(2, t('chic.hostess.global.validations.city'))
        .max(64, t('chic.hostess.global.maxLength'))
        .required(t('chic.hostess.global.requiredField')),
      email: Yup.string()
        .email(t('chic.hostess.global.wrongValue'))
        .required(t('chic.hostess.global.requiredField')),
      phone: Yup.string()
        .transform((value: string | null | undefined): string => value ? value.replace(/\s+/g, '') : '')
        .matches(phoneRegexp, t('chic.hostess.global.validations.invalidPhone'))
        .required(t('chic.hostess.global.requiredField')),
    });
  };
