import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { 
  ButtonWrapper, 
  InnerContainer, 
  InputWrapper, 
  ListContainer, 
  Container,
  MapContainer, 
  OuterListContainer, 
  SearchContainer, 
  StyledButton, 
  StyledSearchDeliveryListItem,
  StyledPageTitle, 
} from './subscriptionDeliveryPickupPointChoose.styled';
import { 
  Breakpoint, 
  UseState, 
  InputWithLocation, 
  ComponentColorTheme,
  MapPointDelivery, 
  ButtonTheme, 
  DeliveryPointMap,
  UseRedirect,
  useRedirect, 
} from '@chic-loyalty/ui';
import { useMediaQuery } from 'react-responsive';
import { UseSubscriptionDeliveryPickupPointChoose } from './subscriptionDeliveryPickupPointChoose.types';
import { useSubscriptionDelliveryPickupPointChoose } from './subscriptionDeliveryPickupPointChoose.hooks';
import { QueryKey, RoutingPath } from '@chic/enums';
import { PickupPointDetails, UseOrder } from '@chic/models';
import { useOrder } from '@chic/hooks';
import { getPickupPointDetails } from '@chic/api';
import { useQuery } from 'react-query';
import { emptyRequest, isDeliveryPickupPointType } from '@chic/utils';
import { pickupPointsDeliveryTypes } from '@chic/constans';

export const SubscriptionDeliveryPickupPointChooseView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { orderData, saveOrderStateValues }: UseOrder = useOrder();
  const [isMapEnabled, setIsMapEnabled]: UseState<boolean> = useState<boolean>(false);
  const listRef: RefObject<HTMLDivElement> = useRef(null);
  const isTablet: boolean = useMediaQuery({ query: Breakpoint.Tablet });
  const isMobile: boolean = useMediaQuery({ query: Breakpoint.Mobile });
  const pointId: string = useMemo(
    (): string => String(orderData.deliveryDestination?.pickupPoint?.id ?? ''),
    [orderData],
  );
  const {
    inputValue,
    mapPoints,
    activePointId,
    pointPosition,
    shouldFlyToPoint,
    onSearchInputChange,
    onLocationButtonClick,
    onSelectDeliveryPointId,
    onPositionChange,
    setShouldFlyToPoint,
    setActivePointId,
    setPointPosition,
    isDeliveryListVisibleOnMobile,
    onCloseStoreInfo,
    setIsDeliveryListVisibleOnMobile,
    pointDetails,
    setPointDetails,
  }: UseSubscriptionDeliveryPickupPointChoose = useSubscriptionDelliveryPickupPointChoose(
    listRef, pointId,
  );

  useQuery(
    [QueryKey.PickupPointDetails, pointId],
    (): Promise<PickupPointDetails | void> => isDeliveryPickupPointType(orderData.selectedDelivery) 
      ? getPickupPointDetails(orderData.selectedDelivery, pointId) 
      : emptyRequest(),
    {
      enabled: !!pointId && !!orderData.selectedDelivery && !!isDeliveryPickupPointType(orderData.selectedDelivery) 
        && pickupPointsDeliveryTypes.includes(orderData.selectedDelivery),
      onSuccess: (data: PickupPointDetails): void => {
        setPointPosition([data.location.latitude, data.location.longitude]);
        setActivePointId(data.id);
        setPointDetails(data);
      },
      // TODO: add logger
      onError: (): void => undefined,
    },
  );

  useEffect((): void => setIsMapEnabled(true), [mapPoints]);

  const onSavePickupPoint: () => void = (): void => {
    if (!pointDetails) {
      return;
    }

    saveOrderStateValues({ 
      deliveryDestination: { 
        ...(orderData.deliveryDestination ? orderData.deliveryDestination : {}),
        pickupPoint: pointDetails,
      },
    });
    redirect(RoutingPath.SubscriptionDelivery);
  };
  
  return (
    <Container>
      <StyledPageTitle 
        label={t('chic.hostess.subscriptionDeliveryPickupPointChooseView.pageTitle')} 
        onClick={(): void => redirect(RoutingPath.SubscriptionDelivery)}
      />
      {isTablet && (
        <InputWrapper>
          <InputWithLocation
            inputValue={inputValue}
            onChange={onSearchInputChange}
            onLocationButtonClick={onLocationButtonClick}
            onPositionChange={onPositionChange}
            placeholder={t('chic.hostess.subscriptionDeliveryPickupPointChooseView.inputPlaceholder')}
            colorTheme={ComponentColorTheme.IC}
          />
        </InputWrapper>
      )}
      <InnerContainer>
        {(!isMobile || (isMobile && isDeliveryListVisibleOnMobile)) && (
          <SearchContainer>
            {!isTablet && (
              <InputWithLocation
                inputValue={inputValue}
                onChange={onSearchInputChange}
                onLocationButtonClick={onLocationButtonClick}
                onPositionChange={onPositionChange}
                placeholder={t('chic.hostess.subscriptionDeliveryPickupPointChooseView.inputPlaceholder')}
                colorTheme={ComponentColorTheme.IC}
              />
            )}  
            <OuterListContainer>
              <ListContainer ref={listRef}>
                {mapPoints.map((point: MapPointDelivery): JSX.Element => (
                  <StyledSearchDeliveryListItem
                    colorTheme={ComponentColorTheme.IC}
                    data={point}
                    onChoose={(itemId: string): void => onSelectDeliveryPointId(parseInt(itemId, 10))}
                    radioButtonName={point.id.toString()}
                    checked={point.id === activePointId}
                    key={point.id}
                  />
                ))}
              </ListContainer>
            </OuterListContainer>
            <ButtonWrapper>
              <StyledButton
                label={t('chic.hostess.subscriptionDeliveryPickupPointChooseView.confirm')}
                fullWidth={isMobile}
                onClick={onSavePickupPoint}
                disabled={!activePointId}
                buttonTheme={ButtonTheme.ICPrimary}
              />
            </ButtonWrapper>
          </SearchContainer>
        )}
        {isMapEnabled && (
          <MapContainer>
            <DeliveryPointMap
              colorTheme={ComponentColorTheme.IC}
              points={mapPoints}
              onSelectDeliveryPoint={onSelectDeliveryPointId}
              onCloseStoreInfo={onCloseStoreInfo}
              onButtonChooseStore={(): void => setIsDeliveryListVisibleOnMobile(true)}
              enable
              currentPosition={pointPosition}
              shouldFlyToPosition={shouldFlyToPoint}
              onCoordinatesChange={(lat: number, lng: number): void => {
                setPointPosition([lat, lng]);
                setShouldFlyToPoint(false);
              }}
            />
          </MapContainer>
        )}
      </InnerContainer>
    </Container>
  );
};
