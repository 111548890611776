import { AccordionSimpleItem, CalcSize, Color, Grid, Markdown, PageTitle, StyledComponent, ValidationBar } from '@chic-loyalty/ui';
import { Form } from 'formik';
import styled from 'styled-components';

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - ${CalcSize.FooterHeight} - ${CalcSize.HealthWarningHeight});
`;

export const PageTitleWrapper: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICSmallDesktop} + 48px);
  width: 100%;
  padding: 24px;
`;

export const RegistrationBox: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICTablet} + 48px);
  width: 100%;
  padding: 0 24px;
  margin: 24px 0 62px;
`;

export const VerificationBox: StyledComponent<'div'> = styled.div`
  max-width: calc(${Grid.ICTablet} + 48px);
  width: 100%;
  padding: 0 24px;
  margin-bottom: 62px;
`;

export const StyledPageTitle: StyledComponent<typeof PageTitle> = styled(PageTitle)`
  margin-bottom: 40px;
`;

export const InputsWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const CheckBoxesWrapper: StyledComponent<'div'> = styled.div`
  margin: 40px 0;
`;

export const StyledAccordionSimpleItem: StyledComponent<typeof AccordionSimpleItem> = styled(AccordionSimpleItem)`
  margin-bottom: 40px;
`;

export const AccordionInputs: StyledComponent<'div'> = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const RequiredLabel: StyledComponent<'p'> = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${Color.ICWhite100};
`;

export const AgreementsBox: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  & + & {
    margin-top: 30px;
  }
`;

export const AgreementMessage: StyledComponent<typeof Markdown> = styled(Markdown)`
  font-size: 10px;
  line-height: 13px;
  color: ${Color.ICWhite100};
  margin-top: 30px;
`;

export const ButtonWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const VerificationDescription: StyledComponent<'p'> = styled.p`
  color: ${Color.ICWhite100};
  font-size: 12px;
  line-height: 16px;
  margin: 20px 0 40px;
`;

export const FinishButtonWrapper: StyledComponent<'div'> = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(188.65deg, ${Color.ICBlack100} -36.36%, ${Color.ICBlack500} 126.99%);
`;

export const StyledForm: StyledComponent<typeof Form> = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledValidationBar: StyledComponent<typeof ValidationBar> = styled(ValidationBar)`
  margin-top: 40px;
`;

export const SmsInput: StyledComponent<'input'> = styled.input`
  height: 38px;
  background: ${Color.Transparent};
  border: 1px solid ${Color.ICWhite100};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 0 12px;
  font-size: 16px;
  line-height: 24px;
  color: ${Color.ICWhite100};
`;
