import { useContext } from 'react';

import { OrderContext } from '@chic/contexts';
import { OrderAction } from '@chic/enums';
import { OrderContextType, OrderState, UseOrder } from '@chic/models';

export const useOrder: () => UseOrder = (): UseOrder => {
  const [orderData, dispatch]: OrderContextType = useContext(OrderContext);

  const saveOrderStateValues: (userData: OrderState) => void = (userData: OrderState): void => {
    dispatch({ type: OrderAction.SaveOrderStateValues, payload: userData });
  };

  const clearSessionStorage: () => void = (): void => {
    dispatch({ type: OrderAction.ClearSessionStorage });
  };

  return { orderData, saveOrderStateValues, clearSessionStorage };
};
