import { BaseViewForOrders } from '@chic/components';
import React, { useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { MainContainer, SmallPageTitle } from './subscriptionPayment.styled';
import { 
  ComponentColorTheme, 
  OptionsSwitch, 
  OptionsSwitchSize, 
  OptionSwitchOption, 
  UseRedirect, 
  useRedirect, 
  UseState, 
} from '@chic-loyalty/ui';
import { RoutingPath, SubscriptionPaymentMethod } from '@chic/enums';
import { UseOrder } from '@chic/models';
import { useConfig, useOrder } from '@chic/hooks';

export const SubscriptionPaymentView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { redirect }: UseRedirect = useRedirect();
  const { orderData, saveOrderStateValues }: UseOrder = useOrder();
  const [paymentMethod, setPaymentMethod]: UseState<string> = useState<string>(SubscriptionPaymentMethod.Payu);
  const { systemConfig } = useConfig();

  const paymentMethods: OptionSwitchOption[] = useMemo(
    (): OptionSwitchOption[] => {
      const canPayByCreditCard: boolean = !!orderData.canPayByCreditCard;

      return [
        {
          name: SubscriptionPaymentMethod.Payu,
          label: t('chic.hostess.subscriptionPaymentView.paymentMethod.payu'),
        },
        ...(canPayByCreditCard ? [{
          name: SubscriptionPaymentMethod.Card,
          label: t('chic.hostess.subscriptionPaymentView.paymentMethod.card'),
        }] : []),
      ];
    },
    [orderData],
  );

  const onSavePaymentMethod: () => void = (): void => {
    saveOrderStateValues({ paymentMethod: paymentMethod as SubscriptionPaymentMethod });
    if (systemConfig.subscriptionParameterCyclicOrderEnabled) {
      redirect(RoutingPath.SubscriptionParametersChoose);
    } else {
      redirect(RoutingPath.SubscriptionSummary);
    }
  };

  return (
    <BaseViewForOrders 
      pageTitle={t('chic.hostess.subscriptionPaymentView.pageTitle')} 
      pageTitleAction={(): void => redirect(RoutingPath.SubscriptionDelivery)}
      acceptButtonSettings={{
        label: t('chic.crmApp.global.save'),
        onClick: onSavePaymentMethod,
      }} 
      cancelButtonSettings={{
        label: t('chic.hostess.global.goBack'),
        onClick: (): void => redirect(RoutingPath.SubscriptionDelivery),
      }} 
    >
      <MainContainer>
        <SmallPageTitle label={t('chic.hostess.subscriptionPaymentView.paymentMethod')} />
        {!!paymentMethod && (
          <OptionsSwitch 
            options={paymentMethods} 
            onOptionChange={setPaymentMethod}    
            colorTheme={ComponentColorTheme.IC}      
            activeOptionName={paymentMethod}
            size={OptionsSwitchSize.Small}
          />
        )}
      </MainContainer>
    </BaseViewForOrders>
  );
};
