import { BaseViewForOrders } from '@chic/components';
import React, { useEffect, useMemo, useState } from 'react';
import { TransProps, useTranslation } from 'react-i18next';
import { 
  DetailsBoxContainer, 
  DetailsBoxes, 
  DetailsBoxesColumn, 
  ImageBox, 
  MainContainer,
  StyledDetailsEditBox,
  StyledPageTitle, 
  StyledTransactionProductBox, 
} from './subscriptionSummary.styled';
import {
  CyclicOrderPeriod,
  DeliveryType,
  FileFromViews,
  PosPaymentOptions,
  QueryKey,
  RoutingPath,
  SubscriptionParameterType,
  SubscriptionPlanPropertyType,
} from '@chic/enums';
import { 
  ComponentColorTheme,
  DetailsInfoGroup, 
  DetailsInfoSettings, 
  UseParsers, 
  useParsers, 
  useRedirect, 
  UseRedirect, 
  UseState, 
} from '@chic-loyalty/ui';
import { createSubscription, getSubscriptionPlans, simulateSubscription } from '@chic/api';
import { 
  SubscriptionSimulation, 
  SubscriptionSimulationData, 
  ObjectIdWithAmount, 
  SubscriptionPlanSimple,
  UseOrder,
  SubscriptionChosenProduct,
  SubscriptionSimplePlanProduct,
  SubscriptionCreateRequestData,
  SubscriptionDetails,
  UseStatics,
  ConfigsContextType,
  UseSubscriptionOrder,
} from '@chic/models';
import { useQuery } from 'react-query';
import { useConfig, useOrder, useStatics } from '@chic/hooks';
import { findPlanProductByKey, getPlanPropertyValueByType } from '@chic/utils';
import { SubscriptionParameterValue } from '@chic/types';
import { useSubscriptionOrder } from '../hooks';

export const SubscriptionSummaryView: React.FC = (): JSX.Element => {
  const { t }: TransProps<never> = useTranslation();
  const { parsePrice }: UseParsers = useParsers();
  const { redirect }: UseRedirect = useRedirect();
  const { orderData, saveOrderStateValues }: UseOrder = useOrder();
  const { getPickupPointAddress }: UseSubscriptionOrder = useSubscriptionOrder();
  const [simulationData, setSimulationData]: UseState<SubscriptionSimulation | null> = useState<SubscriptionSimulation | null>(null);
  const [selectedPlan, setSelectedPlan]: UseState<SubscriptionPlanSimple | null> = useState<SubscriptionPlanSimple | null>(null);
  const [selectedProducts, setSelectedProducts]: UseState<SubscriptionChosenProduct[]> = useState<SubscriptionChosenProduct[]>([]);
  const currency: string = 'zł';
  const [isRequestInProgress, setIsRequestInProgress]: UseState<boolean> = useState<boolean>(false);
  const { getParameterLabel, getParameterOptionLabel, isStaticsReady }: UseStatics = useStatics();
  const { systemConfig }: ConfigsContextType = useConfig();

  useQuery(
    [QueryKey.SubscriptionPlans],
    (): Promise<SubscriptionPlanSimple[]> => getSubscriptionPlans(),
    {
      onSuccess: (plansData: SubscriptionPlanSimple[]): void => {
        for (const plan of plansData) {
          if (plan.id === orderData.planId) {
            setSelectedPlan(plan);
            break;
          }
        }
      },
      onError: (): void => undefined,
    },
  );

  useEffect(
    (): void => {
      const planId: number | undefined = orderData.planId;
      const customerId: number | undefined = orderData.customerId;
      const deliveryType: DeliveryType | undefined = orderData.deliveryDestination?.deliveryType;
      const products: Record<number, number> | undefined = orderData.products;
      
      if (products && planId && customerId && deliveryType) {
        const simulateData: SubscriptionSimulationData = {
          planId,
          customerId,
          deliveryType: deliveryType,
          products: Object.entries(products).map((product: [string, number]): ObjectIdWithAmount => ({
            id: parseInt(product[0], 10),
            amount: product[1],
          })),
        };
  
        simulateSubscription(simulateData)
          .then(setSimulationData)
          .catch((): void => undefined);

        let chosenProducts: SubscriptionChosenProduct[] = [];

        for (const key in products) {
          if (products[key] > 0 && !!selectedPlan) {
            const foundProduct: SubscriptionSimplePlanProduct | undefined = findPlanProductByKey(parseInt(key, 10), selectedPlan);
        
            if (foundProduct) {
              chosenProducts = [
                ...chosenProducts, {
                  id: foundProduct.id,
                  amount: products[key],
                  productDetails: foundProduct,
                },
              ];
            }
          }
        }
  
        setSelectedProducts(chosenProducts);
      }
    },
    [orderData, selectedPlan],
  );

  const detailsInfoSettings: DetailsInfoSettings[] = useMemo(
    (): DetailsInfoSettings[] => {
      return [
        { 
          label: t('chic.hostess.subscriptionSummaryView.detailsInfoSettings.value.label'), 
          value: `${parsePrice(simulationData?.productsPrice.regular ?? 0)} ${currency}`,
        },
        { 
          label: t('chic.hostess.subscriptionSummaryView.detailsInfoSettings.discount.label'), 
          value: `${getPlanPropertyValueByType<number>(
            selectedPlan?.properties ?? [], SubscriptionPlanPropertyType.Discount,
          ) ?? 0}%`,
        },
        { 
          label: t('chic.hostess.subscriptionSummaryView.detailsInfoSettings.delivery.label'), 
          value: simulationData?.deliveryPrice.discounted !== 0 
            ? `${parsePrice(simulationData?.deliveryPrice.discounted ?? 0)} ${currency}`
            : t('chic.crmApp.global.free'),
        },
        { 
          label: t('chic.hostess.subscriptionSummaryView.detailsInfoSettings.finalValue.label'), 
          value: `${parsePrice((
            simulationData?.productsPrice.discounted ?? 0) + (simulationData?.deliveryPrice.discounted ?? 0
          ))} ${currency}`,
          valueHighlighted: true,
        },
      ];
    },
    [selectedPlan, simulationData],
  );

  const createSubscriptionAction: () => void = (): void => {
    if (orderData.customerId 
      && orderData.planId
      && orderData.paymentMethod 
      && orderData.deliveryDestination
      && orderData.deliveryDestination.deliveryType
      && orderData.deliveryDestination.address
      && orderData.deliveryDestination.name
      && orderData.deliveryDestination.postalCode
      && orderData.deliveryDestination.city
      && orderData.email 
      && orderData.phone 
      && orderData.products
      && orderData.parameters) {

      const parameters: SubscriptionParameterValue[] = Object.entries(orderData.parameters)
        .map((item: [string, string]): SubscriptionParameterValue => {

          let value: SubscriptionParameterValue['value'] = item[1] as SubscriptionParameterValue['value'];
          switch (item[0] as SubscriptionParameterType) {
            case SubscriptionParameterType.CyclicOrder:
              value = value ?? CyclicOrderPeriod.None;
              break;
          }

          return {
            value,
            type: item[0] as SubscriptionParameterValue['type'],
          };
        });

      const createSubscriptionRequestData: SubscriptionCreateRequestData = {
        customerId: orderData.customerId,
        planId: orderData.planId,
        paymentMethod: orderData.paymentMethod,
        deliveryDestination: {
          deliveryType: orderData.deliveryDestination.deliveryType,
          name: orderData.deliveryDestination.name,
          address: orderData.deliveryDestination.address,
          postalCode: orderData.deliveryDestination.postalCode,
          city: orderData.deliveryDestination.city,
          pickupPointId: orderData.deliveryDestination.pickupPoint?.id,
        },
        email: orderData.email,
        phone: orderData.phone,
        products: Object.entries(orderData.products).map((product: [string, number]): ObjectIdWithAmount => ({
          id: parseInt(product[0]),
          amount: product[1],
        })),
        parameters,
      };

      setIsRequestInProgress(true);
      createSubscription(createSubscriptionRequestData)
        .then((subscriptionData: SubscriptionDetails): void => {
          saveOrderStateValues({ subscriptionId: subscriptionData.id });
          redirect(RoutingPath.SubscriptionContract);
        })
        .catch((): void => undefined)
        .finally((): void => setIsRequestInProgress(false));
    }
  };

  const parameters: string = useMemo(
    (): string => {
      if (!orderData.parameters) {
        return '';
      }

      return Object.entries(orderData.parameters)
        .map((item: [string, string]): string => {
          const parameterLabel: string = getParameterLabel(item[0] as SubscriptionParameterType);
          const parameterValue: string = getParameterOptionLabel(
            item[0] as SubscriptionParameterType,
            item[1] as CyclicOrderPeriod | PosPaymentOptions,
          );

          return `**${parameterLabel}:** ${parameterValue}`;
        })
        .join('\n');
    },
    [orderData, isStaticsReady],
  );

  const goBack: () => void = (): void => {
    if (systemConfig.subscriptionParameterCyclicOrderEnabled) {
      redirect(RoutingPath.SubscriptionParametersChoose);
    } else if (orderData.canPayByCreditCard) {
      redirect(RoutingPath.SubscriptionPayment);
    } else {
      redirect( RoutingPath.SubscriptionDelivery);
    } 
  };

  const deliveryDestination: string = useMemo(
    (): string => {
      if (!orderData.deliveryDestination) {
        return '';
      }

      if (orderData.deliveryDestination.pickupPoint) {
        return getPickupPointAddress(orderData.deliveryDestination.pickupPoint);
      } else {
        return `${orderData.deliveryDestination.address}, 
        ${orderData.deliveryDestination.postalCode}, 
        ${orderData.deliveryDestination.city}`;
      }
    },
    [orderData],
  );

  return (
    <BaseViewForOrders 
      pageTitle={t('chic.hostess.subscriptionSummaryView.title')} 
      pageTitleAction={goBack}
      acceptButtonSettings={{
        label: t('chic.hostess.global.goNext'),
        onClick: createSubscriptionAction,
        isLoading: isRequestInProgress,
      }} 
      cancelButtonSettings={{
        label: t('chic.hostess.global.goBack'),
        onClick: goBack,
      }}
    >
      <MainContainer>
        <ImageBox $image={FileFromViews.SubscripitonSummaryCover}>
          {/* TODO: fix when be will be ready */}
          {/* <BadgesRow>
            {[Glo, Velo].map((badge: string): JSX.Element => (
              <Badge key={badge}><BadgeImg src={badge} /></Badge>
            ))}
          </BadgesRow> */}
        </ImageBox>
        <DetailsBoxes>
          <DetailsBoxContainer>
            <DetailsInfoGroup items={detailsInfoSettings} />
          </DetailsBoxContainer>
          <DetailsBoxesColumn>
            <StyledDetailsEditBox 
              title={t('chic.hostess.subscriptionSummaryView.detailsEditBox.deliveryAddress')} 
              textContent={deliveryDestination}   
              badge={
                simulationData?.deliveryPrice.discounted === 0 
                  ? t('chic.hostess.subscriptionSummaryView.detailsEditBox.freeDelivery')
                  : undefined
              }
            />
            <StyledDetailsEditBox 
              title={t('chic.hostess.subscriptionSummaryView.detailsEditBox.parameters')} 
              textContent={parameters}
            />
          </DetailsBoxesColumn>
        </DetailsBoxes>
        <StyledPageTitle label={t('chic.hostess.subscriptionSummaryView.pageTitle')} />
        {selectedProducts.map((selectedProduct: SubscriptionChosenProduct): JSX.Element => (
          <StyledTransactionProductBox 
            name={selectedProduct.productDetails.name}
            amount={selectedProduct.amount}
            category={selectedProduct.productDetails.category}
            image={selectedProduct.productDetails.photos.length ? selectedProduct.productDetails.photos[0] : undefined}
            colorTheme={ComponentColorTheme.IC}       
            price={selectedProduct.productDetails.price}
            key={selectedProduct.id}
          />
        ))}
      </MainContainer>
    </BaseViewForOrders>
  );
};
