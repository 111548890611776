export enum QueryKey {
  Agreements = 'agreements',
  SubscriptionDetails = 'subscriptionDetails',
  SubscriptionPlan = 'subscriptionPlan',
  SubscriptionSimulation = 'subscriptionSimulation',
  SubscriptionStatic = 'subscriptionStatic',
  UsersVerificationList = 'usersVerificationList',
  UserRoles = 'userRoles',
  PickupPointDetails = 'pickupPointDetails',
  PickupPoints = 'pickupPoints',
  SubscriptionPlans = 'subscriptionPlans',
  Config = 'config',
}
