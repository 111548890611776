import React from 'react';

import { useTitle } from '@chic/hooks';
import { Container } from './registrationWithoutSms.styled';
import { PageTitle, PageTitleSize } from '@chic-loyalty/ui';

export const RegistrationWithoutSmsView: React.FC = (): JSX.Element => {
  useTitle('Rejestracja bez SMS');

  return (
    <Container>
      <PageTitle
        label='Zarejestruj Klienta'
        size={PageTitleSize.Big}
      >
        Zarejestruj klienta, aby mógł cieszyć się wszystkimi przywilejami eSC.
      </PageTitle>
    </Container>
  );
};
