import { Color, FontWeight, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const Table: StyledComponent<'table'> = styled.table`
  width: 100%;
  color: ${Color.ICWhite100};
`;

export const TableHeader: StyledComponent<'th'> = styled.th`
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: ${FontWeight.Bold};
  text-transform: uppercase;
  padding-bottom: 24px;

  & + & {
    padding-left: 24px;
  }

  &:first-of-type {
    min-width: 200px;
  }
`;

export const TableRow: StyledComponent<'tr'> = styled.tr`
  border-bottom: 1px solid ${Color.ICGray500};
  text-align: left;
`;

export const TableCell: StyledComponent<'td'> = styled.td`
  font-size: 14px;
  height: 74px;
  padding: 5px 0;
  vertical-align: middle;

  & + & {
    padding-left: 24px;
  }

  &:first-of-type {
    color: ${Color.ICYellow100};
  }
`;
