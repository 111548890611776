import { 
  PriceRange, 
  FilterElementSettings,
  DetailsInfoSettings, 
  BundleDiscountData, 
  IconName, 
  UseParsers, 
  useParsers, 
  UseRedirect, 
  useRedirect, 
  UseState,
  UseNotifications,
  useNotifications, 
} from '@chic-loyalty/ui';
import { TransProps, useTranslation } from 'react-i18next';
import { getSubscriptionPlans, simulateSubscription } from '@chic/api';
import { 
  QueryKey, 
  SubscriptionPlanPropertyType, 
  SubscriptionProductsFilterKey, 
  ProductAvailability, 
  RoutingPath, 
  FileFromViews,
  PopupId, 
} from '@chic/enums';
import { useOrder } from '@chic/hooks';
import { 
  SubscriptionPlanSimple,
  SubscriptionPlanGroup, 
  SubscriptionSimplePlanProduct, 
  SubscriptionChosenProduct, 
  SubscriptionSimulationData, 
  ObjectIdWithAmount, 
  SubscriptionSimulation, 
  UseOrder, 
} from '@chic/models';
import { findPlanProductByKey, getPlanPropertyValueByType } from '@chic/utils';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { UseSubscriptionProductsChoose } from './subscriptionProductsChoose.types';

export const useSubscriptionProductsChoose: () => UseSubscriptionProductsChoose = (): UseSubscriptionProductsChoose => {
  const { t }: TransProps<never> = useTranslation();
  const { parsePrice }: UseParsers = useParsers();
  const { redirect }: UseRedirect = useRedirect();
  const { showPopup, hidePopup }: UseNotifications = useNotifications();
  const { orderData, saveOrderStateValues }: UseOrder = useOrder();
  const [activeFilter, setActiveFilter]: UseState<string> = useState<string>(SubscriptionProductsFilterKey.All);
  const [inputQuery, setInputQuery]: UseState<string> = useState<string>('');
  const [plans, setPlans]: UseState<SubscriptionPlanSimple[]> = useState<SubscriptionPlanSimple[]>([]);
  const [priceRanges, setPriceRanges]: UseState<PriceRange[]> = useState<PriceRange[]>([]);
  const [filters, setFilters]: UseState<FilterElementSettings[]> = useState<FilterElementSettings[]>([]);
  const [selectedProducts, setSelectedProducts]: UseState<SubscriptionChosenProduct[]> = useState<SubscriptionChosenProduct[]>([]);
  const [productsAmount, setProductsAmount]: UseState<Record<number, number>> = useState<Record<number, number>>({});
  const [simulationData, setSimulationData]: UseState<SubscriptionSimulation | null> = useState<SubscriptionSimulation | null>(null);
  const [highestPlanPrice, setHighestPlanPrice]: UseState<number> = useState<number>(0);
  const [currentSubscriptionPlan, setCurrentSubscriptionPlan]: UseState<SubscriptionPlanSimple | null> 
    = useState<SubscriptionPlanSimple | null>(null);
  const [isDataFromOrderDataContextLoaded, setIsDataFromOrderDataContextLoaded]: UseState<boolean> = useState<boolean>(false);
  const [planCurrentPrice, setPlanCurrentPrice]: UseState<number> = useState<number>(0);
  const currency: string = 'zł';

  useQuery(
    [QueryKey.SubscriptionPlans],
    (): Promise<SubscriptionPlanSimple[]> => getSubscriptionPlans(),
    {
      onSuccess: (plansData: SubscriptionPlanSimple[]): void => {
        setPlans(plansData);
        setPriceRanges(plansData.map((plan: SubscriptionPlanSimple, index: number): PriceRange => {
          const priceFrom: number = getPlanPropertyValueByType<number>(
            plan.properties, SubscriptionPlanPropertyType.PriceFrom,
          ) ?? 0;
          const priceTo: number = getPlanPropertyValueByType<number>(
            plan.properties, SubscriptionPlanPropertyType.PriceTo,
          ) ?? 0;

          if (index === plansData.length - 1) {
            setHighestPlanPrice(priceTo);
          }

          return {
            label: `${priceFrom}-${priceTo} ${currency}`,
            minValue: priceFrom,
            maxValue: priceTo,
          };
        }));
      },
      onError: (): void => undefined,
    },
  );

  const filteredProducts: SubscriptionPlanGroup<SubscriptionSimplePlanProduct>[] = useMemo(
    (): SubscriptionPlanGroup<SubscriptionSimplePlanProduct>[] => {
      if (!plans.length) {
        return [];
      }

      let productsGroups: SubscriptionPlanGroup<SubscriptionSimplePlanProduct>[] = [];
      productsGroups = plans[0].productsGroups
        .filter((group: SubscriptionPlanGroup<SubscriptionSimplePlanProduct>): boolean => (
          activeFilter === SubscriptionProductsFilterKey.All || String(group.id) === activeFilter
        ))
        .map((group: SubscriptionPlanGroup<SubscriptionSimplePlanProduct>): SubscriptionPlanGroup<SubscriptionSimplePlanProduct> => ({
          ...group,
          products: group.products
            .filter((product: SubscriptionSimplePlanProduct): boolean => product.name.toLowerCase().includes(inputQuery)),
        }))
        .filter((group: SubscriptionPlanGroup<SubscriptionSimplePlanProduct>): boolean => !!group.products.length);

      setFilters([
        {
          label: t('chic.hostess.useSubscriptionProductsChoose.filters.all'),
          name: SubscriptionProductsFilterKey.All,
        },
        ...productsGroups.map((group: SubscriptionPlanGroup<SubscriptionSimplePlanProduct>): FilterElementSettings => ({
          label: group.name,
          name: String(group.id),
        })),
      ]);

      return productsGroups;
    },
    [inputQuery, plans, activeFilter],
  );

  const detailsInfoSettings: DetailsInfoSettings[] = useMemo(
    (): DetailsInfoSettings[] => {
      return [
        { 
          label: t('chic.hostess.useSubscriptionProductsChoose.detailsInfoSettings.value.label'), 
          value: `${parsePrice(simulationData?.productsPrice.regular ?? 0)} ${currency}`,
        },
        { 
          label: t('chic.hostess.useSubscriptionProductsChoose.detailsInfoSettings.discount.label'), 
          value: `${getPlanPropertyValueByType<number>(
            currentSubscriptionPlan?.properties ?? plans[0]?.properties ?? [], SubscriptionPlanPropertyType.Discount,
          ) ?? 0}%`,
        },
        { 
          label: t('chic.hostess.useSubscriptionProductsChoose.detailsInfoSettings.delivery.label'), 
          value: simulationData?.deliveryPrice.discounted !== 0 
            ? `${parsePrice(simulationData?.deliveryPrice.discounted ?? 0)} ${currency}`
            : t('chic.crmApp.global.free'),
        },
        { 
          label: t('chic.hostess.useSubscriptionProductsChoose.detailsInfoSettings.finalValue.label'), 
          value: `${parsePrice((
            simulationData?.productsPrice.discounted ?? 0) + (simulationData?.deliveryPrice.discounted ?? 0
          ))} ${currency}`,
          valueHighlighted: true,
        },
      ];
    },
    [plans, currentSubscriptionPlan, simulationData],
  );

  const discounts: BundleDiscountData[] = useMemo(
    (): BundleDiscountData[] => {
      if (!currentSubscriptionPlan) {
        return [];
      }

      const discount: number = getPlanPropertyValueByType(
        currentSubscriptionPlan.properties, SubscriptionPlanPropertyType.Discount,
      ) as number;
      
      return [
        {
          label: t('chic.hostess.useSubscriptionProductsChoose.discounts.freeDelivery'),
          icon: IconName.CheckInCircle,
          isActive: simulationData?.deliveryPrice.discounted === 0 && !!Object.keys(productsAmount).length,
        },
        {
          label: t('chic.hostess.useSubscriptionProductsChoose.discounts.discount', { discount }),
          icon: IconName.OffersBig,
          isActive: discount > 0 && !!Object.keys(productsAmount).length,
        },
      ];
    },
    [simulationData, currentSubscriptionPlan, productsAmount],
  );

  const isProductAvailable: (product: SubscriptionSimplePlanProduct) => boolean = (product: SubscriptionSimplePlanProduct): boolean => {
    return product.availability === ProductAvailability.Available;
  };

  const updateProductAmount: (productId: number, amount: number) => void  = (productId: number, amount: number): void => {
    const productsAmountCopy: Record<number, number> = { ...productsAmount };  
    productsAmountCopy[productId] = amount;
    const filteredProductsAmount: Record<number, number> = {};
    let chosenProducts: SubscriptionChosenProduct[] = [];
    let selectedProductsPrice: number = 0;
    const customerId: number | undefined = orderData.customerId;

    for (const key in productsAmountCopy) {
      if (productsAmountCopy[key] > 0 && plans.length) {
        filteredProductsAmount[key] = productsAmountCopy[key];
      
        const foundProduct: SubscriptionSimplePlanProduct | undefined = findPlanProductByKey(parseInt(key, 10), plans[0]);

        if (foundProduct) {
          selectedProductsPrice += (foundProduct.price * productsAmountCopy[foundProduct.id]);
          chosenProducts = [
            ...chosenProducts, {
              id: foundProduct.id,
              amount: productsAmountCopy[key],
              productDetails: foundProduct,
            },
          ];
        }
      }
    }

    const subscriptionPlan: SubscriptionPlanSimple | undefined = plans.find((plan: SubscriptionPlanSimple): boolean => {
      const subscriptionPlanFrom: number = getPlanPropertyValueByType<number>(
        plan.properties, SubscriptionPlanPropertyType.PriceFrom,
      ) ?? 0;
      const subscriptionPlanTo: number = getPlanPropertyValueByType<number>(
        plan.properties, SubscriptionPlanPropertyType.PriceTo,
      ) ?? 0;

      return (selectedProductsPrice > subscriptionPlanFrom && selectedProductsPrice < subscriptionPlanTo);
    });

    if (!!subscriptionPlan && !!customerId) {
      setCurrentSubscriptionPlan(subscriptionPlan);

      const simulateData: SubscriptionSimulationData = {
        customerId,
        planId: subscriptionPlan.id,
        products: chosenProducts.map((product: SubscriptionChosenProduct): ObjectIdWithAmount => ({
          id: product.id,
          amount: product.amount,
        })),
      };

      simulateSubscription(simulateData)
        .then(setSimulationData)
        .catch((): void => undefined);
    }

    setSelectedProducts(chosenProducts);
    setProductsAmount(filteredProductsAmount);
    setPlanCurrentPrice(selectedProductsPrice);
  };

  useEffect(
    (): void => {
      if (!!orderData.planId && !!orderData.products && !!orderData.customerId && !!plans.length && !isDataFromOrderDataContextLoaded) {
        setCurrentSubscriptionPlan(plans.find((plan: SubscriptionPlanSimple): boolean => plan.id === orderData.planId) ?? null);
        let chosenProducts: SubscriptionChosenProduct[] = [];

        const simulateData: SubscriptionSimulationData = {
          planId: orderData.planId,
          customerId: orderData.customerId,
          products: Object.entries(orderData.products).map((product: [string, number]): ObjectIdWithAmount => ({
            id: parseInt(product[0], 10),
            amount: product[1],
          })),
        };
  
        simulateSubscription(simulateData)
          .then(setSimulationData)
          .catch((): void => undefined);

        for (const key in orderData.products) {
          if (orderData.products[key] > 0 && plans.length) {
            const foundProduct: SubscriptionSimplePlanProduct | undefined = findPlanProductByKey(parseInt(key, 10), plans[0]);
      
            if (foundProduct) {
              chosenProducts = [
                ...chosenProducts, {
                  id: foundProduct.id,
                  amount: orderData.products[key],
                  productDetails: foundProduct,
                },
              ];
            }
          }
        }

        setSelectedProducts(chosenProducts);
        setProductsAmount(orderData.products);
        setIsDataFromOrderDataContextLoaded(true);
      }
    },
    [orderData, plans],
  );

  const onProductsSave: () => void = (): void => {
    if (!selectedProducts.length) {
      return;
    }
    
    if (currentSubscriptionPlan) {
      const priceFrom: number = getPlanPropertyValueByType<number>(
        currentSubscriptionPlan.properties, SubscriptionPlanPropertyType.PriceFrom,
      ) ?? 0;
      const priceTo: number = getPlanPropertyValueByType<number>(
        currentSubscriptionPlan.properties, SubscriptionPlanPropertyType.PriceTo,
      ) ?? 0;
      const currentPlanIndex: number = plans.findIndex((plan: SubscriptionPlanSimple): boolean => plan.id === currentSubscriptionPlan.id);
      const nextPlan: SubscriptionPlanSimple | undefined = plans[currentPlanIndex + 1];

      const saveDataAndRedirectAction: () => void = (): void => {
        saveOrderStateValues({ 
          planId: currentSubscriptionPlan.id,
          products: productsAmount,
        });
        redirect(RoutingPath.SubscriptionDelivery);
      };

      if (!!simulationData && ((simulationData.productsPrice.regular - priceFrom) >= ((priceTo - priceFrom) * 0.8) && nextPlan)) {
        const nextPlanDiscount: number = getPlanPropertyValueByType<number>(
          nextPlan.properties, SubscriptionPlanPropertyType.Discount,
        ) ?? 0;

        showPopup({
          id: PopupId.NextPlanPopup,
          title: t('chic.hostess.useSubscriptionProductsChoose.nextPlanPopup.title'),
          image: FileFromViews.SubscriptionProductsChooseDiscountIcon,
          description: t('chic.hostess.useSubscriptionProductsChoose.nextPlanPopup.description', {
            price: `${parsePrice(priceTo - simulationData.productsPrice.regular)} ${currency}`,
            discount: `${nextPlanDiscount}%`,
          }),
          acceptButtonSettings: {
            label: t('chic.hostess.useSubscriptionProductsChoose.nextPlanPopup.accept'),
            action: (): void => hidePopup({ id: PopupId.NextPlanPopup }),
          },
          cancelButtonSettings: {
            label: t('chic.hostess.useSubscriptionProductsChoose.nextPlanPopup.cancel'),
            action: saveDataAndRedirectAction,
          },
          closeIconAction: (): void => hidePopup({ id: PopupId.NextPlanPopup }),
        });
      } else {
        saveDataAndRedirectAction();
      }
    }
  };
  
  return {
    onProductsSave,
    selectedProducts,
    priceRanges,
    discounts,
    productsAmount,
    simulationData,
    setInputQuery,
    filters,
    setActiveFilter,
    activeFilter,
    filteredProducts,
    isProductAvailable,
    updateProductAmount,
    highestPlanPrice,
    detailsInfoSettings,
    planCurrentPrice,
  };
};
