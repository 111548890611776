import { Breakpoint, Button, Input, StyledComponent } from '@chic-loyalty/ui';
import styled from 'styled-components';

export const MiddleContent: StyledComponent<'div'> = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  padding: 0 24px 62px;

  @media ${Breakpoint.Mobile} {
    flex-direction: column;
    gap: 40px;
    padding: 0;
  }
`;

export const Image: StyledComponent<'img'> = styled.img`
  object-fit: cover;
  width: 50%;
  height: 100%;

  @media ${Breakpoint.Mobile} {
    height: 138px;
    width: 100%;
  }
`;

export const FormWrapper: StyledComponent<'div'> = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media ${Breakpoint.Mobile} {
    width: 100%;
    padding: 0 24px;
  }
`;

export const StyledInput: StyledComponent<typeof Input> = styled(Input)`
  margin-top: 40px;

  & + & {
    margin-top: 20px;
  }
`;

export const StyledButton: StyledComponent<typeof Button> = styled(Button)`
  margin: 40px 0;

  @media ${Breakpoint.Mobile} {
    margin: 30px 0 40px;
  }
`;
